import axios from "axios";

const API_BASE_URL = "https://api.sysnot-notaria137.com/api/";

const axiosInstance = axios.create({
    baseURL: API_BASE_URL,
    // timeout: 5000,  // Optional: Timeout for requests (in milliseconds)
    headers: { // Sets default headers for all requests made by this instance. 
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('access_token')}`
    }
  });

/*
    'axiosInstance.interceptors.request.use()': Intercepts requests before they are sent.

    It modifies the request configuration (config) to include the access token (Authorization header)
    from localStorage for authenticated requests
*/
axiosInstance.interceptors.response.use(
  response => {
      return response;
  },
  async error => {
      const originalRequest = error.config;
      const refreshToken = localStorage.getItem('refresh_token');

      if (error.response && error.response.status === 401 && !originalRequest._retry && refreshToken) {
          originalRequest._retry = true;

          try {
              const response = await axios.post(`${API_BASE_URL}token/refresh/`, {
                  refresh: refreshToken
              });
              localStorage.setItem('access_token', response.data.access);
              axiosInstance.defaults.headers['Authorization'] = `Bearer ${response.data.access}`;
              originalRequest.headers['Authorization'] = `Bearer ${response.data.access}`;
              return axiosInstance(originalRequest);
          } catch (err) {
              console.log("Refresh token failed:", err);
              localStorage.removeItem('access_token');
              localStorage.removeItem('refresh_token');
              window.location.href = '/';  // Redirect to the main page
              return Promise.reject(err);
          }
      }

      // For all other errors or if refresh token fails, log out and redirect
      if (error.response && error.response.status === 401) {
          localStorage.removeItem('access_token');
          localStorage.removeItem('refresh_token');
          window.location.href = '/';  // Redirect to the main page
      }

      return Promise.reject(error);
  }
);

  export default axiosInstance;