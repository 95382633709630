import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import '../style/react-tabs.css';

import classes from './Configuration.module.css';
import Notaria from '../components/Notaria';
import Usuarios from '../components/Usuarios';
import Operaciones from '../components/Operaciones';


export default function Configuration(){

    return (
        <div className={classes.container}>
            <h1>Configuración</h1>
            <hr/>
            <p>
                Edita las configuraciones de la notaría; agrega, edita y elimina usuarios; y maneja las operaciones.
            </p>
            <Tabs>
                <TabList>
                    <Tab><h3>Notaría</h3></Tab>
                    <Tab><h3>Usuarios</h3></Tab>
                    <Tab><h3>Operaciones</h3></Tab>
                </TabList>

                <TabPanel>
                    <Notaria />
                </TabPanel>
                <TabPanel>
                    <Usuarios />
                </TabPanel>
                <TabPanel>
                    <Operaciones />
                </TabPanel>
            </Tabs>
        </div>
    );
};
