import classes from './ClientesDocTable.module.css';

import Select from 'react-select';

import Button from './Button';
import { InputFile } from './InputFile';
import apiService from '../utils/apiService';
import Modal from './Modal';
import { useState } from 'react';
import { customSelectTheme, customClassNames } from '../utils/selectCustomizations';


const optionsTipoDocumento = [
    {
        label: 'Copia', value: 'Copia',
    },
    {
        label: 'Original', value: 'Original'
    }
]

export default function ClientesDocTable({data, setData, setErrorMessage, }){
    const [confirmDelete, setConfirmDelete] = useState(undefined);

    const deleteDocument = async (id) => {
        try {
            const response = await apiService.delete(`/documentos/${id}/`, {}, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            // console.log(response) //  DEBUG
            if (response.status === 204) {
                console.log('Successfully deleted document')
            }
        } catch (error) {
            const errorCode = error.code || 'error';
            const errorMessage = error.message + '. Notifique del error, vuelva a cargar la página o espere un momento, los cambios hechos no se aplicarán';
            setErrorMessage({problem: 'Problemas al eliminar documento, código: '+errorCode, message: errorMessage});
            console.error('Error deleting document:', error);
        }
    };

    const handleDelete = (id) => {
        // setData(l => l.filter(item => item.id !== id));
        deleteDocument(id);

        console.log(id);

        setData((prev) => (
            {
                ...prev,
                documentos : [
                    ...prev.documentos.filter(i => i.id !== id)
                ]
            }
        ));

        setConfirmDelete(undefined);
    };

    const handleSelect = (id, name, value) => {
        const objectToChange = data.documentos.find(item => item.id === id);

        setData((prev) => (
            {
                ...prev,
                documentos : prev.documentos.map((item) => {
                    if(item.id === objectToChange.id){
                        return {
                            ...objectToChange,
                            [name]: value.value,
                        };
                    } else{
                        return item;
                    }
                })
            }
        ));
    };

    const handleChange = (id, name, e) => {
        const objectToChange = data.documentos.find(item => item.id === id);
        const value = e.target.value;

        setData((prev) => (
            {
                ...prev,
                documentos : prev.documentos.map((item) => {
                    if(item.id === id){
                        return {
                            ...objectToChange,
                            [name] : value,
                        };
                    } else{
                        return item;
                    }
                })
            }
        ));
    };

    const handleFile = (file, id) => {
        const objectToChange = data.documentos.find(item => item.id === id);

        const  fileType = file['type'];
        const validImageTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/x-iwork-pages-sffpages'];
        if (!validImageTypes.includes(fileType)) {
            console.log(file['type']);
            //handle invalif file type modal here
            alert('Invalid file type: ' + fileType);
            return;
        }

        setData((prev) => (
            {
                ...prev,
                documentos : prev.documentos.map((item) => {
                    if(item.id === id){
                        return {
                            ...objectToChange,
                            archivo : file,
                        };
                    } else{
                        return item;
                    }
                })
            }
        ));
    };

    const postEmptyDocument = async () => {
        try {
            const response = await apiService.post('/documentos/', {"id_cliente" : data['id']}, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            // console.log(response) //  DEBUG
            if (response.status === 201) {
                return response.data['id_documento'];
            }
        } catch (error) {
            const errorCode = error.code || 'error';
            const errorMessage = error.message + '. Notifique del error, vuelva a cargar la página o espere un momento, los cambios hechos no se aplicarán';
            setErrorMessage({problem: 'Problemas al añadir el documento, código: ' + errorCode, message: errorMessage});
            
            console.error('Error posting empty doc:', error);
        }
    };

    const handleAdd = async () => {
        const newDocID = await postEmptyDocument();
        const numberDoc = newDocID;

        setData((prev) => ({
            ...prev,
            documentos : [
                ...prev.documentos,
                {
                    id: numberDoc,
                    descripcion: '',
                    fechaEntrega: '',
                    entrega: '',
                    archivo: undefined,
                    notas: data.documentos.length
                }
            ]
        }));
    };
    
    const handleCancel = () => {
        setConfirmDelete(undefined);
    };

    const handleConfirmDelete = (item) => {
        setConfirmDelete(item);
    };

    return (
        <>
            {
                confirmDelete && <Modal handleClose={handleCancel}>
                    <div className={classes.deleteModal}>
                        <h1>
                            Eliminando...
                        </h1>

                        <p>
                            ¿Estas seguro de eliminar el documento {confirmDelete.descripcion}?
                        </p>

                        <div className={classes.deleteModalButtons}>
                            <Button action={() => handleDelete(confirmDelete.id)}>
                                Eliminar
                            </Button>
                            <Button action={handleCancel} className={'inverted'}>
                                Cancelar
                            </Button>
                        </div>
                    </div>
                </Modal>
            }
            <div className={classes.headerContainer}>
                <Button className={'inverted'} type='button' action={handleAdd} >Agregar documento</Button>
            </div>
            <div className={classes.tableContainer}>
            <table className={classes.table} id='table'>
                <thead>
                    <tr>
                        <th scope="col">Descripción</th>
                        <th scope="col">Fecha entrega</th>
                        <th scope="col">Copia/Original</th>
                        <th scope="col">Archivo</th>
                        <th scope="col">Acción</th>
                    </tr>
                </thead>
                <tbody>
                {
                    data.documentos.length > 0 ? data.documentos.map((el) => (
                        <tr key={el.id}>
                            <td scope="row">
                                <input name='descripcion' defaultValue={el.descripcion} onChange={(e) => handleChange(el.id, 'descripcion', e)}/>
                            </td>
                            <td>
                                <input name='fechaEntrega' type='date' defaultValue={el.fechaEntrega} onChange={(e) => handleChange(el.id, 'fechaEntrega', e)}/>
                            </td>

                            <td>
                                <Select 
                                name={'s'+el.id}  
                                onChange={(choice) => handleSelect(el.id, 'entrega', choice)}
                                options={optionsTipoDocumento}
                                value={optionsTipoDocumento.find((option) => option.value === el.entrega)}
                                classNames={customClassNames}
                                theme={customSelectTheme}
                                />
                            </td>
                            <td>
                                <InputFile 
                                    handleFile={handleFile} 
                                    id={el.id}
                                />
                            </td>
                            <td><Button type='button' action={() => handleConfirmDelete(el)}>Eliminar</Button></td>
                        </tr>
                    )): <tr><td colSpan="5" className={classes.noDocuments}>Agrega documentos</td></tr>
                }
                </tbody>
            </table>
            </div>
        </>
    );

}