import Button from './Button';
import Input from './Input';
import Select from 'react-select';

import classes from './ClientesWindow.module.css';
import { useEffect, useState } from 'react';
import { customSelectTheme, customClassNames } from '../utils/selectCustomizations';
import ClientesDocTable from './ClientesDocTable';

import apiService from '../utils/apiService';
import Loader from './Loader';

const optionsSexo = [
    {label: 'Masculino', value: 'Masculino'},
    {label: 'Femenino', value: 'Femenino'},
]

const optionsTipo = [
    {label: 'Persona Física', value: 'Física'},
    {label: 'Persona Moral', value: 'Moral'},
]

const optionsEstadoCivil = [
    {label: 'Soltero', value: 'Soltero'},
    {label: 'Soltera', value: 'Soltera'},
    {label: 'Casado(a)', value: 'Casado(a)'},
    {label: 'Casado bajo separación de bienes', value: 'Casado bajo separación de bienes'},
    {label: 'Casado bajo sociedad conyugal', value: 'Casado bajo sociedad conyugal'},
    {label: 'Divorciado', value: 'Divorciado'},
    {label: 'Soltero(a) por viudez', value: 'Soltero(a) por viudez'},
]

export default function ClientesWindow({data, setData, setAction, action, setSelect, setErrorMessage, setSaveStatus}){
    const [input, setInput] = useState(
        
        data ? data : 
        
        {
            id: 'Nuevo cliente',

            tipo: '',
            aPaterno: '',
            aMaterno: '',
            nombre: '',

            rfc: '',
            curp: '',
            fecha: '',

            telefonoParticular: '844',
            telefonoMovil: '844',

            email: '',
            sexo: '',
            estadoCivil: '',

            edad: 0,
            ocupacion: '',

            
            nacionalidad: '',
            lugar_nacimiento: '',
            folio_ine: '',
            profesion: '',

            num_exterior: '',
            num_interior: '',
            calle: '',
            colonia: '',
            codigo_postal: '',

            documentos: [
                
            ]
        }
    );

    const [loadingDocs, setLoadingDocs] = useState(false);

    useEffect(() => {
        if(action === 'e'){
            setLoadingDocs(() => true);
            getDocuments();
        }
    }, []);

    const transformDocument = (data) => ({
        id: data.id_documento,
        descripcion: data.nombre_documento,
        fechaEntrega: data.entrega,
        entrega: data.original_o_copia,
        archivo: data.archivo,

        idCliente : data.id_cliente
    });

    const getDocuments = async () => {
        try {
            const response = await apiService.get(`/documentos/`, {});

            const arrDocuments = response.data.map((single) => transformDocument(single));

            setLoadingDocs(() => false);
            setInput((prev) => ({
                ...prev,
                documentos : arrDocuments.filter((document) => document.idCliente === prev.id)
            }));
        } catch (error) {
            const errorCode = error.code || 'error';
            const errorMessage = error.message + '. Notifique del error, vuelva a cargar la página o espere un momento, los cambios hechos no se aplicarán';
            setErrorMessage({problem: 'Problemas al obtener los documentos, código: '+errorCode, message: errorMessage});
            console.error('There was an error getting the info!', error);
        }
    };

    const postClient = async (data) => {
        const formattedData = {        
            "apellido_paterno": data['aPaterno'],
            "apellido_materno": data['aMaterno'],
            "nombre": data['nombre'],
            "persona": data['tipo'],

            "rfc": data['rfc'],
            "curp_cud": data['curp'],
            "fecha_nacimiento_constitucion": data['fecha'],

            "tel_particular": data['telefonoParticular'],
            "tel_movil": data['telefonoMovil'],

            "email": data['email'],
            "sexo": data['sexo'],
            "estado_civil": data['estadoCivil'],

            "edad": data['edad'],
            "ocupacion": data['ocupacion'],

            "nacionalidad": data['nacionalidad'],
            "lugar_nacimiento": data['lugar_nacimiento'],
            "folio_ine": data['folio_ine'],
            "profesion": data['profesion'],

            "num_exterior": data['num_exterior'],
            "num_interior": data['num_interior'],
            "calle": data['calle'],
            "colonia": data['colonia'],
            "codigo_postal": data['codigo_postal'],
        };

        try {
            const response = await apiService.post('/clientes/', formattedData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            // console.log(response) //  DEBUG
            if (response.status === 201) {
                setSaveStatus('Guardado');
                return response.data['id_cliente'];
            }
        } catch (error) {
            const errorCode = error.response.status || 'error';
            if(errorCode === 400){
                const errorMessage = 'Problema relacionado al llenado de datos, verifique que se llenaron todos los campos obligatorios, los cambios hechos no se aplicarán';
                setErrorMessage({problem: 'Problemas al añadir el cliente, código: '+errorCode, message: errorMessage});
            } else {
                const errorMessage = error.message + '. Notifique del error, vuelva a cargar la página o espere un momento, los cambios hechos no se aplicarán';
                setErrorMessage({problem: 'Problemas al añadir el cliente, código: '+errorCode, message: errorMessage});
            }
            
            console.error('Error posting empty user:', error);
        }
    };

    const putClient = async (data) => {
        const formattedData = {        
            "apellido_paterno": data['aPaterno'],
            "apellido_materno": data['aMaterno'],
            "nombre": data['nombre'],
            "persona": data['tipo'],

            "rfc": data['rfc'],
            "curp_cud": data['curp'],
            "fecha_nacimiento_constitucion": data['fecha'],

            "tel_particular": data['telefonoParticular'],
            "tel_movil": data['telefonoMovil'],

            "email": data['email'],
            "sexo": data['sexo'],
            "estado_civil": data['estadoCivil'],

            "edad": data['edad'],
            "ocupacion": data['ocupacion'],

            "nacionalidad": data['nacionalidad'],
            "lugar_nacimiento": data['lugar_nacimiento'],
            "folio_ine": data['folio_ine'],
            "profesion": data['profesion'],

            "num_exterior": data['num_exterior'],
            "num_interior": data['num_interior'],
            "calle": data['calle'],
            "colonia": data['colonia'],
            "codigo_postal": data['codigo_postal'],
        };

        try {
            const response = await apiService.put(`/clientes/${data['id']}/`, formattedData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            // console.log(response) //  DEBUG
            if (response.status === 200) {
                console.log('Put client successful');
                setSaveStatus('Guardado cliente');
            }
        } catch (error) {
            const errorCode = error.code || 'error';
            const errorMessage = error.message + '. Notifique del error, vuelva a cargar la página o espere un momento, los cambios hechos no se aplicarán';
            setErrorMessage({problem: 'Problemas al actualizar el cliente, código: '+errorCode, message: errorMessage});
            
            console.error('Error posting empty user:', error);
        }
    };

    const putDocument = async (data, id) => {
        let formattedData;
        if(data['archivo'] instanceof File){
            formattedData = {
                "id_documento": data['id'],
                "nombre_documento": data['descripcion'],
                "entrega": data['fechaEntrega'],
                "original_o_copia": data['entrega'],
                "archivo": data['archivo'],

                "id_cliente": id
            };
        } else{
            formattedData = {
                "id_documento": data['id'],
                "nombre_documento": data['descripcion'],
                "entrega": data['fechaEntrega'],
                "original_o_copia": data['entrega'],

                "id_cliente": id
            };
        }

        console.log(data);

        try {
            const response = await apiService.put(`/documentos/${data['id']}/`, formattedData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            // console.log(response) //  DEBUG
            if (response.status === 200) {
                console.log('Put Document successful');
                setSaveStatus(() => 'Guardado terminado');
            }
        } catch (error) {
            const errorCode = error.code || 'error';
            const errorMessage = error.message + '. Notifique del error, vuelva a cargar la página o espere un momento, los cambios hechos no se aplicarán';
            setErrorMessage({problem: 'Problemas al actualizar los documentos, código: '+errorCode, message: errorMessage});
            
            console.error('Error putting doc:', error);
        }
    };

    
    const handleSave = async () => {
        if(action === 'a'){
            setSaveStatus(() => 'Guardando...');
            const newUserId = await postClient(input);
            const numberUser = newUserId;

            input.id = numberUser;

            setData((prev) => [
                ...prev,
                input
            ]);
        }

        if(action === 'e'){
            setSaveStatus(() => 'Guardando...')
            putClient(input);
            if(input.documentos.length > 0){
                input.documentos.forEach((documento) => {
                    putDocument(documento, input.id);
                });
            }

            setData((prev) => (
                prev.map((item) => {
                    if(item.id === input.id){
                        return input;
                    } else{
                        return item;
                    }
                })
            ));
        }

        
        setSelect(() => ({
            index: -1,
            id: ''
        }));
        setAction(undefined);
        
    }
    
    const handleChange = (e) => {
        setSaveStatus(() => 'No guardado');
        const { name, value } = e.target;
        setInput((prev) => ({
          ...prev,
          [name]: value,
        }));

    };

    const handleSelect = (valueDef, nameDef) => {
        setSaveStatus(() => 'No guardado');
        const value = valueDef.value;
        const name = nameDef;

        setInput((prev) => ({
          ...prev,
          [name]: value,
        }));
    };

    const handleCancel = () => {
        setSelect(() => ({
            index: -1,
            id: ''
        }));
        
        setAction(undefined);
    }

    return (
        <>
            <div className={classes.container}>
                <div className={classes.innerContainer}>
                    <div className={classes.headerContainer}>
                        <div>
                            <h1>{action === 'a' ? 'Agregar' : 'Editar'}</h1>
                        </div>
                        <div>
                            <Button action={handleCancel} className={'inverted'}>Cancelar</Button>
                            <Button action={handleSave}>Guardar</Button>
                        </div>
                    </div>

                    <div className={classes.flexContainer}>
                        <div>
                            <h3>Número de cliente: <strong style={{color:'#114E28'}}>{input.id}</strong></h3>
                            <Input label={'Apellido paterno'} name='aPaterno' value = {input.aPaterno} onChange= {handleChange}/>
                            <Input label={'Apellido materno'} name='aMaterno' value = {input.aMaterno} onChange= {handleChange}/>
                            <Input label={'Nombre(s)'} name='nombre' value = {input.nombre} onChange= {handleChange}/>
                            <Input label={'Lugar de nacimiento'} name='lugar_nacimiento' value = {input.lugar_nacimiento} onChange= {handleChange}/>
                            <Input label={'Nacionalidad'} name='nacionalidad' value = {input.nacionalidad} onChange= {handleChange}/>
                            <Input label={'Fecha nacimiento/constitución'} type='date' name='fecha' value = {input.fecha} onChange= {handleChange}/>
                        </div>

                        <div>
                            <span>
                                <label className={classes.labelText}>Tipo</label>
                                <Select name='tipo' 
                                value={
                                    {label: input.tipo, value: input.tipo}
                                } 
                                className={classes.selectDG} 
                                onChange={(choice) => handleSelect(choice, 'tipo')} 
                                options={optionsTipo}
                                classNames={customClassNames}
                                theme={customSelectTheme}
                                />
                            </span>

                            <Input label={'RFC'} name='rfc' value = {input.rfc} onChange= {handleChange}/>
                            <Input label={'CURP/CUD'} name='curp' value = {input.curp} onChange= {handleChange}/>
                            <Input label={'Folio INE'} name='folio_ine' value = {input.folio_ine} onChange= {handleChange}/>
                            <Input label={'Teléfono móvil'} name='telefonoMovil' type='number' value = {input.telefonoMovil} onChange= {handleChange}/>
                            <Input label={'Teléfono particular'} name='telefonoParticular' type='number' value = {input.telefonoParticular} onChange= {handleChange}/>
                        </div>

                        <div className={classes.optionalContainer}>
                            <p className={classes.text}>Optional</p>

                            <Input label={'E-mail'} name='email' value = {input.email} onChange= {handleChange}/>

                            <span>
                                <label className={classes.labelText}>Sexo</label>
                                <Select name='sexo' 
                                value={
                                    {label: input.sexo, value: input.sexo}
                                } 
                                className={classes.selectDG} 
                                onChange={(choice) => handleSelect(choice, 'sexo')} 
                                options={optionsSexo}
                                classNames={customClassNames}
                                theme={customSelectTheme}
                                />
                            </span>

                            <span>
                                <label className={classes.labelText}>Estado Civil</label>
                                <Select name='estadoCivil' 
                                value={
                                    {label: input.estadoCivil, value: input.estadoCivil}
                                } 
                                className={classes.selectDG} 
                                onChange={(choice) => handleSelect(choice, 'estadoCivil')} 
                                options={optionsEstadoCivil}
                                classNames={customClassNames}
                                theme={customSelectTheme}
                                />
                            </span>
                            <Input label={'Edad'} name='edad' value = {input.edad} onChange= {handleChange}/>
                            <Input label={'Ocupación'} name='ocupacion' value = {input.ocupacion} onChange= {handleChange}/>
                            <Input label={'Profesión'} name='profesion' value = {input.profesion} onChange= {handleChange}/>
                        </div>

                        <div className={classes.optionalContainer}>
                            <p className={classes.text}>Domicilio</p>
                            <Input label={'Calle'} name='calle' value = {input.calle} onChange= {handleChange}/>
                            <Input label={'Número exterior'} name='num_exterior' value = {input.num_exterior} onChange= {handleChange}/>
                            <Input label={'Número interior'} name='num_interior' value = {input.num_interior} onChange= {handleChange}/>
                            <Input label={'Colonia/Fraccionamiento'} name='colonia' value = {input.colonia} onChange= {handleChange}/>
                            <Input label={'Código Postal'} name='codigo_postal' value = {input.codigo_postal} onChange= {handleChange}/>
                        </div>
                    </div>
                </div>
                <div style={{position: 'relative'}}>
                {
                    loadingDocs &&  action === 'e'&& <Loader />
                }
                {
                    action === 'e' && !loadingDocs &&
                    <ClientesDocTable data={input} setData={setInput} setErrorMessage={setErrorMessage}/>
                }
                </div>
            </div>
        </>
    );
}