export const customSelectTheme = (theme) => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary25: '#c9e8d4',
        primary: '#114E28',
    },
});

export const customClassNames = {
    control: (state) => (state.isFocused ? 'select-focused' : ''),
};
