import { useEffect, useState } from 'react';

import classes from './Indice.module.css';
import IndiceTable from '../components/IndiceTable';

import apiService from '../utils/apiService';

import Modal from '../components/Modal';
import ErrorMessage from '../components/ErrorMessage';
import Loader from '../components/Loader';

import NoInfo from '../components/NoInfo';

export default function Indice(){
    //For the http request
    const [indiceData, setIndiceData] = useState([]);
    const [uma, setUma] = useState(0);
    const [meses_antilavado, setMeses_antilavado] = useState(0);

    const [errorMessage, setErrorMessage] = useState(undefined);
    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        setErrorMessage(undefined);
    };

    useEffect(() => {
        setLoading(() => true);
        getIndice();
    }, []);

    const getIndice = async () => {
        try {
            const response = await apiService.get('/indice/', {});
            
            setIndiceData(() => response.data.indice);
            setUma(() => response.data.uma);
            setMeses_antilavado(() => response.data.meses_antilavado);
            setLoading(() => false);
        } catch (error) {
            const errorCode = error.code || 'error';
            const errorMessage = error.message + '. Notifique del error, vuelva a cargar la página o espere un momento.';
            setErrorMessage({problem: 'Problemas al obtener los datos, código: '+errorCode, message: errorMessage});
        }
    };

    return (
        <div className={classes.container}>
            <h1>Índice</h1>
            <hr />

            {
                errorMessage && <Modal handleClose={handleClose}>
                <ErrorMessage problem={errorMessage.problem} message={errorMessage.message}/>
            </Modal>
            }

            {
                loading && <div className={classes.loaderContainer}>
                    <Loader />
                </div>
            }
            {
                indiceData.length <= 0 && !loading && <NoInfo text={'Agrega expedientes para observar'}/>
            }

            {
               indiceData.length > 0 && !loading && <IndiceTable data={indiceData} uma={uma} meses_antilavado={meses_antilavado}/>
            }
        </div>
    );
};