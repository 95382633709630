import classes from './FormatoActa.module.css';
import Input from './Input';

import Select from 'react-select';
import { customSelectTheme, customClassNames } from '../utils/selectCustomizations';
import '../style/select.css';
import { useState } from 'react';
import Button from './Button';

import apiService from '../utils/apiService';
import Loader from './Loader';
import Modal from './Modal';
import ErrorMessage from './ErrorMessage';

const optionsTiposActas = [
    {label: 'Fe de hechos', value: 'fe'},
    {label: 'Ratificación', value: 'ratificacion'},
    {label: 'Otorgamiento de poder', value: 'poder'},
    {label: 'Compraventa', value: 'compraventa'},
    {label: 'Juicio sucerio testamento', value: 'juicio intestamento'},
    {label: 'Juicio sucerio testamento', value: 'juicio testamento'},
    {label: 'Otros', value: 'otros'},
]

const optionsPaso = [
    {label: 'Si paso', value: 'si paso'},
    {label: 'No paso', value: 'no paso'},
]


export default function FormatoActa({acta}){
    const [formato, setFormato] = useState({
        apellidoPaterno: 'Verduzco',
        apellidoMaterno: 'González',
        nombre: 'Gerardo',

        notNumero: '137',
        distNot: 'Saltillo',
        numActa: '',
        fechaActa: '',

        descripcion: '',
        solicitante: '',
        caracterDe: '',
        participantes: '',
        observaciones: '',

        paso: '',
        tipo: '',
        otros: '',

        partes: '',
        acto: '',
    });


    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const handleClose = () => {
        setErrorMessage(undefined);
    };

    const handleChange = (e) => {
        
        const { name, value } = e.target;
        setFormato((prev) => ({
            ...prev,
            [name] : value
        }));
    };

    const handleSelect = (valueDef, nameDef) => {
        const value = valueDef.value;
        const name = nameDef;


        setFormato((prev) => ({
            ...prev,
            [name] : value
        }));
    };

    const handleSend = async () => {
        setLoading(() => true);

        const formattedData = {
            apellido_paterno : formato.apellidoPaterno,
            apellido_materno : formato.apellidoMaterno,
            nombre : formato.nombre,

            notaria_numero : formato.notNumero,
            distrito_notarial : formato.distNot,

            numero_acta : formato.numActa,
            fecha_acta : formato.fechaActa,

            descripcion : formato.descripcion,
            solicitante : formato.solicitante,
            caracter_de : formato.caracterDe,
            participantes : formato.participantes,
            observaciones : formato.observaciones,

            paso : formato.paso,
            tipo : formato.tipo,
            otros : formato.otros,

            partes : formato.partes,
            acto : formato.acto,
        };

        const responseData = await postFormat(formattedData);

        if(responseData === undefined){
            setLoading(() => false);
            return;
        };

        const blob = new Blob([responseData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);

        // Create a link element and trigger a download
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `acta_fueraprotocolo_${formato.solicitante}.xlsx`);  // Replace with the desired file name
        document.body.appendChild(link);
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
    };

    const postFormat = async (data) => {
        try{
            const response = await apiService.post('/format-actas/', data, {
                responseType: 'blob',
            });

            if (response.status === 200) {
                setLoading(() => false);
                return response.data;
            }
        } catch (error) {
            const errorCode = error.code || 'error';
            const errorMessage = error.message + '. Notifique del error, vuelva a cargar la página o espere un momento.';
            setErrorMessage({problem: 'Problemas al obtener el archivo, código: '+errorCode, message: errorMessage});
        }
    };

    return(
        <>
            <div className={classes.container}>
                <div className={classes.innerContainer}>
                    <Input name='apellidoPaterno' label={'Apellido paterno notario'} 
                    placeholder='Agregar'
                    value={formato.apellidoPaterno || ''}
                    onChange={handleChange}/>
                    <Input name='apellidoMaterno' label={'Apellido materno notario'} 
                    placeholder='Agregar'
                    value={formato.apellidoMaterno || ''}
                    onChange={handleChange}/>
                    <Input name='nombre' label={'Nombre(s) notario'} 
                    placeholder='Agregar'
                    value={formato.nombre || ''}
                    onChange={handleChange}/>

                    <Input name='notNumero' label={'Número notaría'} 
                    placeholder='Agregar'
                    value={formato.notNumero || ''}
                    onChange={handleChange}/>
                    <Input name='distNot' label={'Distrito notarial'} 
                    placeholder='Agregar'
                    value={formato.distNot || ''}
                    onChange={handleChange}/>

                    
                </div>
                <div className={classes.innerContainer}>
                    <Input name='numActa' label={'Número acta'} 
                    placeholder='Agregar'
                    value={formato.numActa || ''}
                    onChange={handleChange}/>
                    <Input name='fechaActa' label={'Fecha acta'} 
                    placeholder='Agregar' type='date'
                    value={formato.fechaActa || ''}
                    onChange={handleChange}/>
                    <Input name='descripcion' label={'Descripción'} 
                    placeholder='Agregar'
                    value={formato.descripcion || ''}
                    onChange={handleChange}/>
                    <span>
                        <label className={classes.labelText}>Tipo de acta
                            <Select 
                            name='tipo' 
                            className={classes.selectDG} 
                            onChange={(choice) => handleSelect(choice, 'tipo')}
                            value={optionsTiposActas.find((option) => option.value === formato.tipo) || ''}
                            options={optionsTiposActas}
                            classNames={customClassNames}
                            theme={customSelectTheme}
                            placeholder='Seleccionar'
                            />
                        </label>
                    </span>
                    {
                        formato.tipo === "otros" && 
                        <Input name='otros' label={'Otros'} 
                        placeholder='Agregar'
                        value={formato.otros || ''}
                        onChange={handleChange}/>
                    }
                </div>

                <div className={classes.innerContainer}>
                    <Input name='solicitante' label={'Solicitante'} 
                    placeholder='Agregar'
                    value={formato.solicitante || ''}
                    onChange={handleChange}/>

                    <span>
                        <label className={classes.labelText}>Paso
                            <Select 
                            name='paso' 
                            className={classes.selectDG} 
                            onChange={(choice) => handleSelect(choice, 'paso')}
                            value={optionsPaso.find((option) => option.value === formato.paso) || ''}
                            options={optionsPaso}
                            classNames={customClassNames}
                            theme={customSelectTheme}
                            placeholder='Seleccionar'
                            />
                        </label>
                    </span>

                    <Input name='caracterDe' label={'En cáracter de'} 
                    placeholder='Agregar'
                    value={formato.caracterDe || ''}
                    onChange={handleChange}/>

                    <Input name='participantes' label={'Participantes'} 
                    placeholder='Agregar'
                    value={formato.participantes || ''}
                    onChange={handleChange}/>

                    <Input name='observaciones' label={'Observaciones'} 
                    placeholder='Agregar'
                    value={formato.observaciones || ''}
                    onChange={handleChange}/>
                    
                </div>

                <div className={classes.innerContainer}>
                    <Input name='partes' label={'Partes'} 
                    placeholder='Agregar'
                    value={formato.partes || ''}
                    onChange={handleChange}/>

                    <Input name='acto' label={'Acto'} 
                    placeholder='Agregar'
                    value={formato.acto || ''}
                    onChange={handleChange}/>

                    <Button action={handleSend} style={{alignSelf: 'center', marginTop:'25px'}}>Obtener formato</Button>
                </div>
            </div>
            {
                loading && <div className={classes.loaderContainer}>
                    <Loader />
                </div>
            }

            {
                errorMessage && <Modal handleClose={handleClose}>
                    <ErrorMessage problem={errorMessage.problem} message={errorMessage.message}/>
                </Modal>
            }
        </>
    )
};