import classes from './Operaciones.module.css';

import Button from './Button';
import { useEffect, useState } from 'react';
import { customSelectTheme, customClassNames } from '../utils/selectCustomizations';
import apiService from '../utils/apiService';

import Select from 'react-select';
import ErrorMessage from './ErrorMessage';
import Modal from './Modal';
import Loader from './Loader';
import NoInfo from './NoInfo';

const optionsActividadVulnerable = [
    {
        label: 'A) Transmisión o constitución de derechos reales sobre inmuebles', value: 'A',
    },
    {
        label: 'B) Otorgamiento de poderes para actos de administración o dominio otorgados con carácter irrevocable', value: 'B'
    },
    {
        label: 'C) Constitución de personas morales y su modificación patrimonial', value: 'C'
    },
    {
        label: 'D) Constitución o modificación de fideicomisos traslativos de dominio o de garantía sobre inmuebles', value: 'D'
    },
    {
        label: 'E) Otorgamiento de contratos de mutuo o crédito, con o sin garantía', value: 'E'
    },
    {
        label: 'Sin actividad vulnerable', value: 'N'
    }
]

export default function Operaciones(){
    const [operationsData, setOperationsData] = useState([]);

    const [errorMessage, setErrorMessage] = useState(undefined);
    const [confirmDelete, setConfirmDelete] = useState(undefined);

    const [loading, setLoading] = useState(false);
    const [saveStatus, setSaveStatus] = useState('No guardado');

    const transformOperation = (data) => ({
        id: data.id_operacion,
        descripcion: data.operacion,
        actividadVulnerable: data.actividad_vulnerable,
    });

    const getOperations = async () => {
        try {
            const response = await apiService.get('/operaciones/', {});

            // setUsersData(response.data.map((single) => transformUser(single)));
            setOperationsData(response.data.map((single) => transformOperation(single)));
            setLoading(() => false);
        } catch (error) {
            const errorCode = error.code || 'error';
            const errorMessage = error.message + '. Notifique del error, vuelva a cargar la página o espere un momento';
            setErrorMessage({problem: 'Problemas al obtener las operaciones, código: '+errorCode, message: errorMessage});
            
            console.error('There was an error getting the info!', error);
        }
    };

    useEffect(() => {
        setLoading(() => true);
        getOperations();
    }, []);

    const handleSelect = (id, value) => {
        setSaveStatus('No guardado');
        const objectToChange = operationsData.find(item => item.id === id);

        setOperationsData((prev) => (
            prev.map((item) => {
                if(item.id === id){
                    return {
                        ...objectToChange,
                        actividadVulnerable : value.value,
                    };
                } else{
                    return item;
                }
            })
        ));
    };

    const handleChange = (id, name, e) => {
        setSaveStatus('No guardado');
        const value = e.target.value;
        const objectToChange = operationsData.find(item => item.id === id);

        setOperationsData((prev) => (
            prev.map((item) => {
                if(item.id === id){
                    return {
                        ...objectToChange,
                        [name]: value,
                    };
                } else{
                    return item;
                }
            })
        ));
    };

    const handleSearch = () => {
        // Declare variables
        var input, filter, table, tr, td, ti, i, txtValue;
        input = document.getElementById("searchBar");
        filter = input.value.toUpperCase();
        table = document.getElementById("table");
        tr = table.getElementsByTagName("tr");
      
        // Loop through all table rows, and hide those who don't match the search query
        for (i = 0; i < tr.length; i++) {
          td = tr[i].getElementsByTagName("td")[1];
          if(td){
            ti = td.getElementsByTagName("input")[0];
            if (ti) {
              txtValue = ti.value;
              if (txtValue.toUpperCase().indexOf(filter) > -1) {
                tr[i].style.display = "";
              } else {
                tr[i].style.display = "none";
              }
            }
          }
        }
    }

    const postEmptyOperation = async () => {
        try {
            const response = await apiService.post('/operaciones/', {}, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            // console.log(response) //  DEBUG
            if (response.status === 201) {
                setSaveStatus('Guardado');
                return response.data['id_operacion'];
            }
        } catch (error) {
            const errorCode = error.code || 'error';
            const errorMessage = error.message + '. Notifique del error, vuelva a cargar la página o espere un momento, los cambios hechos no se aplicarán';
            setErrorMessage({problem: 'Problemas al añadir la operación, código: '+errorCode, message: errorMessage});
            
            console.error('Error posting empty operation:', error);
        }
    };

    const handleAdd = async () => {
        const newRow = {
            id: 0,
            descripcion: '',
            actividadVulnerable: 'N'
        };

        const newOpId = await postEmptyOperation();
        const numberOp = newOpId;

        newRow.id = numberOp;

        setOperationsData((prev) => [
            ...prev,
            newRow
        ]);
    };

    const deleteOperation = async (id) => {
        try {
            const response = await apiService.delete(`/operaciones/${id}/`, {}, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            // console.log(response) //  DEBUG
            if (response.status === 204) {
                console.log('Delete operation successful');
                setSaveStatus('Guardado');
            }
        } catch (error) {
            const errorCode = error.code || 'error';
            const errorMessage = error.message + '. Notifique del error, vuelva a cargar la página o espere un momento, los cambios hechos no se aplicarán';
            setErrorMessage({problem: 'Problemas al eliminar la operación, código: '+errorCode, message: errorMessage});
            
            console.error('Error deleting operation:', error);
        }
    };

    const handleDelete = (id) => {
        deleteOperation(id);
        setOperationsData(l => l.filter(item => item.id !== id));
        setConfirmDelete(undefined);
    };

    const putOperation = async (data) => {
        const formattedData = {
            "id_operacion": data['id'],
            "operacion" : data['descripcion'],
            "actividad_vulnerable" : data['actividadVulnerable']
        }

        try {
            const response = await apiService.put(`/operaciones/${data['id']}/`, formattedData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            // console.log(response) //  DEBUG
            if (response.status === 200) {
                console.log('Put operation successful');
                setSaveStatus('Guardado');
            }
        } catch (error) {
            const errorCode = error.code || 'error';
            const errorMessage = error.message + '. Notifique del error, vuelva a cargar la página o espere un momento, los cambios hechos no se aplicarán';
            setErrorMessage({problem: 'Problemas al actualizar las operaciones, código: '+errorCode, message: errorMessage});
            
            console.error('Error putting operation:', error);
        }
    };

    const handleSave = () => {
        setSaveStatus('Guardando...');
        operationsData.forEach((operation) => {
            putOperation(operation);
        });
    };

    const handleClose = () => {
        setErrorMessage(undefined);
    };

    const handleCancel = () => {
        setConfirmDelete(undefined);
    };

    const handleConfirmDelete = (item) => {
        setConfirmDelete(item);
    };

    return(
        <>
            {
                errorMessage && <Modal handleClose={handleClose}>
                    <ErrorMessage problem={errorMessage.problem} message={errorMessage.message}/>
                </Modal>
            }
            {
                confirmDelete && <Modal handleClose={handleCancel}>
                    <div className={classes.deleteModal}>
                        <h1>
                            Eliminando...
                        </h1>

                        <p>
                            ¿Estas seguro de eliminar la operación {confirmDelete.descripcion || confirmDelete.id}?
                        </p>

                        <div className={classes.deleteModalButtons}>
                            <Button action={() => handleDelete(confirmDelete.id)}>
                                Eliminar
                            </Button>
                            <Button action={handleCancel} className={'inverted'}>
                                Cancelar
                            </Button>
                        </div>
                    </div>
                </Modal>
            }

            {
                loading && <Loader />
            }

            {
                !loading && 
                <>
                <div className={classes.headerContainer}>
                <input className={classes.input} type="text" id="searchBar" onKeyUp={handleSearch} placeholder="Buscar operación..."/>

                <div className={classes.buttonContainer}>
                    <p>{saveStatus}</p>

                    <Button type='button' className={'inverted'} action={handleAdd}>Agregar</Button>
                    <Button type='button' action={handleSave}>Guardar</Button>

                </div>
            </div>
            {
                operationsData.length >0 && <div className={classes.tableContainer}>
                <table className={classes.table} id='table'>
                    <thead>
                        <tr>
                            <th scope="col">Número de operación</th>
                            <th scope="col">Descripción</th>
                            <th scope="col">Actividad Vulnerable</th>
                            <th scope="col">Acción</th>
                        </tr>
                    </thead>
                <tbody>
                    {
                    operationsData.map((data, index) => (
                        <tr key={index}>
                            <td scope="row"><input name='id' readOnly value={data.id} /></td>
                            <td><input name='descripcion' value={data.descripcion || ''} onChange={(e) => handleChange(data.id, 'descripcion', e)}/></td>
                            <td>
                                <Select 
                                name={'a'+data.id}  
                                onChange={(choice) => handleSelect(data.id, choice)}
                                options={optionsActividadVulnerable}
                                value={optionsActividadVulnerable.find((value) => value.value === data.actividadVulnerable || '' )}
                                classNames={customClassNames}
                                theme={customSelectTheme}
                                />
                            </td>
                            <td><Button type='button' action={() => handleConfirmDelete(data)}>Eliminar</Button></td>
                        </tr>
                    ))
                    }
                    </tbody>
                </table>
                </div>
            }
            {
                operationsData.length <= 0 && <NoInfo text={'Agrega una operación'}/>
            }
                </>
            }
        </>
    );
}