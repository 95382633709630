import './App.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Error from './pages/Error';
import RootLayout from './pages/Root';
import Home from './pages/Home';
import Clientes from './pages/Clientes';
import Expedientes from './pages/Expedientes';
import SignUp from './pages/Authentication';
import Indice from './pages/Indice';
import Configuration from './pages/Configuration';
import Presupuesto from './pages/Presupuesto.js';

import * as loader from './utils/auth.js';
import Formatos from './pages/Formatos.js';
import Actas from './pages/Actas.js';

const router = createBrowserRouter([
  { path: '/',  
  
    element: <RootLayout />,

    errorElement: <Error />,
  
    children: [
      { index: true,  element: <SignUp />},
      { path: 'home',  element: <Home />, loader: loader.homeAuthLoader},
      { path: 'clientes',  element: <Clientes />, loader: loader.clientesAuthLoader},
      { path: 'actas',  element: <Actas />, loader: loader.actasAuthLoader},
      { path: 'expedientes',  element: <Expedientes />, loader: loader.expedientesAuthLoader},
      { path: 'indice',  element: <Indice />, loader: loader.indiceAuthLoader},
      { path: 'presupuesto',  element: <Presupuesto />, loader: loader.presupuestoAuthLoader},
      { path: 'formatos',  element: <Formatos />, loader: loader.formatsAuthLoader},
      { path: 'configuration',  element: <Configuration />, loader: loader.configurationAuthLoader},
    ]}
  ]);

export default function App() {
  return <RouterProvider router={router} />;
}