import apiService from '../utils/apiService';
import { useState } from "react";
import {InputFile} from "./InputFile";
import DocumentCard from "./DocumentCard";
import Modal from "./Modal";
import Button from "./Button";

import classes from './Protocolo.module.css';
import NoInfo from './NoInfo';

export default function Factura({expediente, setExpediente}){
    const [isOpenModal, setIsOpenModal] = useState(undefined);
    const [selectedDocument, setSelectedDocument] = useState(undefined);
    const [selectedDocumentRoute, setSelectedDocumentRoute] = useState('')

    function handleOpening(document) {
        setIsOpenModal('open');
        setSelectedDocument(document);
        setSelectedDocumentRoute(document.rutaArchivo)
    }

    const putFactura = async (idExpediente, docFactura) => {
        let formattedData = {
            'ultima_modificacion': docFactura['ultimaModificacion'],
            'ruta_archivo': docFactura['rutaArchivo'],
            'id_expediente': idExpediente,
        }
        try {
            const response = await apiService.put(`/factura/${docFactura['idDocumento']}/`, formattedData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            // console.log('PUT factura RESPONSE',response) //  DEBUG
            if (response.status === 200) {
                console.log('Put factura doc successful');
                return response.data;
            }
        } catch (error) {
            console.error('Error putting factura doc:', error);
        }
    }

    const handleFileChange = async (file) => {
        const  fileType = file['type'];
        const validImageTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/x-iwork-pages-sffpages'];
        if (!validImageTypes.includes(fileType)) {
            console.log(file['type']);
            alert('Invalid file type: ' + fileType);
            return;
        }

        const currentDate = new Date();
        const formattedDate = currentDate.getFullYear() + '-' + String(currentDate.getMonth() + 1).padStart(2, '0') + '-' + String(currentDate.getDate()).padStart(2, '0');
        selectedDocument.rutaArchivo = file
        selectedDocument.ultimaModificacion = formattedDate
        const putResponse = await putFactura(expediente.id, selectedDocument)

        setExpediente((prev) => ({
            ...prev,
            factura : prev.factura.map((docFactura) => {
                if(selectedDocument.idDocumento === docFactura.idDocumento){
                    return {
                        ...docFactura,
                        rutaArchivo : putResponse.ruta_archivo,
                        ultimaModificacion : formattedDate
                    }
                } else {
                    return docFactura;
                }
            })
        }));
        setIsOpenModal(undefined);
    };

    const DocumentModal = () => {
        return (
            <Modal handleClose={() => setIsOpenModal(undefined)}>
                { selectedDocumentRoute !== null && <iframe title={selectedDocumentRoute || 'doc'} src={selectedDocumentRoute} width="100%"/>}
                { selectedDocumentRoute === null && <NoInfo text={'Agrega un documento'}/>}
                <div>
                    <InputFile handleFile={handleFileChange} />
                    { selectedDocumentRoute !== null &&
                        <a href={selectedDocumentRoute} download="doc" target='_blank' rel="noreferrer">
                            <Button className={'inverted'} type="button">Descargar</Button>
                        </a>
                    }
                    <Button type="button" onClick={() => setIsOpenModal(undefined)}>Cerrar</Button>
                </div>
            </Modal>
        )
    }

    return (
        <>
        { isOpenModal && <DocumentModal/> }
        <div className={classes.documentsContainer}>
            { expediente.factura.map((document, index) => {
                return (
                    <span key={document.idDocumento}>
                        <DocumentCard
                        description={ 'Factura' + ' ' + (document.ultimaModificacion === null ? '' : document.ultimaModificacion)}
                        onClick={() => handleOpening(document)}/>
                    </span>
                )
            })}
        </div>
        </>
    );
}
