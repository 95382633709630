import apiService from '../utils/apiService';
import { useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { customSelectTheme, customClassNames } from '../utils/selectCustomizations';
import Select from 'react-select';
import Button from '../components/Button';
import DatosGenerales from '../components/DatosGenerales';
import Escrituras from '../components/Escrituras';
import Clientes from '../components/Clientes';
import Tramites from '../components/Tramites';
import Apendice from '../components/Apendice';
import Protocolo from '../components/Protocolo';

import ErrorMessage from '../components/ErrorMessage';
import Modal from '../components/Modal';
import Factura from '../components/Factura';
import Loader from '../components/Loader';

import classes from './Expedientes.module.css';
import '../style/select.css';
import '../style/react-tabs.css';
import NoInfo from '../components/NoInfo';
import Input from '../components/Input';


const initialExpedienteStructure = {
    id: 0,
    datosGenerales: {
        expediente: '',
        tituloExpediente: '',
        fechaCreacion: null,
        valorOperacion: '$0',
        pagoEfectivo: '$0',
        pagoTransferencia: '$0',
        pagoOtro: '$0',
        estatusExpediente: '',
        estatusPago: '',

        financiamiento: '$0',
        referencia: '',
        primerOtorgante: '',
        fechaPagoImpuestos: null,

        operacion: '',
        notario: '',
        responsable: '',
        secretaria: ''
    },
    escrituras: {
        idExpediente: '',
        escritura: '',
        tituloEscritura: '',
        libro: '',
        volumen: '',
        hora: null,

        noPaso: '',
        folioInicial: '',
        folioFinal: '',
        tipoEscritura: '',
        
        fechaElaboracion: null,
        fechaRevision: null,
        fechaFirma: null,
        fechaFirmaTodos: null,

        fechaImpresion: null,
        fechaPagoImpuestos: null,
        fechaEscritura: null,
        fechaDeclaranot: null,
    },
    clientes: [],
    tramites: [],
    apendice: [],
    protocolo: [
        {
            idDocumento: '',
            tipoDocumento: '',
            ultimaModificacion : null,
            rutaArchivo: null,
        },
        { 
            idDocumento: '',
            tipoDocumento: '',
            ultimaModificacion : null,
            rutaArchivo: null,
        },
    ],
    factura: 
    [
        {
            idDocumento: '',
            ultimaModificacion : null,
            rutaArchivo: null,
        },
    ]
}

const createOptions = (expedientes) => {
    const optionsArray = [];
    expedientes && expedientes.forEach((expediente) => {
        const optionObject = {
            value: expediente.expediente,
            label: expediente.tituloExpediente,
        }
        optionsArray.push(optionObject);
    });
    return optionsArray;
};

export default function Expedientes() {
    const [loading, setLoading] = useState(false);
    const [saveStatus, setSaveStatus] = useState('No guardado');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [tituloExpediente, setTituloExpediente] = useState('');

    const [options, setOptions] = useState([]);
    const [expediente, setExpediente] = useState({});

    const [confirmDelete, setConfirmDelete] = useState(undefined);

    //For error handling
    const [errorMessage, setErrorMessage] = useState(undefined);

    const handleCancel = () => {
        setConfirmDelete(undefined);
    };

    const handleConfirmDelete = (item) => {
        setConfirmDelete(item);
    };


    const handleClose = () => {
        setErrorMessage(undefined);
    };

    const formatValue = (inputValue) => {
        // Remove any non-numeric characters except for periods
        let numericValue = inputValue.replace(/[^0-9.]/g, '');

        // Split the value into integer and decimal parts
        let [integer, decimal] = numericValue.split('.');

        // Ensure the integer part is not empty
        if (!integer) {
          integer = '0';
        }

        // Truncate decimal part to two digits
        if (decimal) {
          decimal = decimal.slice(0, 2);
        }

        integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        // Join the integer and decimal parts
        numericValue = decimal !== undefined ? `${integer}.${decimal}` : integer;

        return numericValue;
      };

    const transformDatosGenerales = (data) => ({
        ...initialExpedienteStructure.datosGenerales,
        expediente: data.id_expediente,
        tituloExpediente: data.titulo_expediente,
        fechaCreacion: data.fecha,
        valorOperacion: `$${formatValue(data.valor_operacion)}`,
        pagoEfectivo: `$${formatValue(data.pago_efectivo)}`,
        pagoTransferencia: `$${formatValue(data.pago_transferencia)}`,
        pagoOtro: `$${formatValue(data.pago_otro)}`,
        estatusExpediente: data.estado_expediente,
        estatusPago: data.estado_pago,

        financiamiento: `$${formatValue(data.financiamiento)}`,
        referencia: data.referencia,
        primerOtorgante: data.primer_otorgante,
        fechaPagoImpuestos: data.pago_impuestos,

        operacion: data.operacion,
        notario: data.notario,
        responsable: data.responsable,
        secretaria: data.secretaria,
    });

    const transformEscrituras = (data) => ({
        ...initialExpedienteStructure.escrituras,
        idExpediente: data.id_expediente,
        escritura: data.id_escritura,
        tituloEscritura: data.titulo_escritura,
        libro: data.libro,
        volumen: data.volumen,
        hora: data.hora,

        noPaso: data.no_paso,
        folioInicial: data.folio_inicial,
        folioFinal: data.folio_final,
        tipoEscritura: data.tipo_escritura,
        
        fechaElaboracion: data.fecha_elaboracion,
        fechaRevision: data.fecha_revision,
        fechaFirma: data.fecha_firma,
        fechaFirmaTodos: data.fecha_firma_todos,

        fechaImpresion: data.fecha_impresion,
        fechaPagoImpuestos: data.fecha_pago_impuestos,
        fechaEscritura: data.fecha_escritura,
        fechaDeclaranot: data.fecha_declaranot,
    });

    const mergeData = async (idExpediente) => {

        const datosGenerales = await getExpediente(idExpediente);
        const escritura = await getEscritura(idExpediente);
        const clientesDocsArray = await getClientesAndDocs(idExpediente);
        const tramitesArray = await getTramitesExpediente(idExpediente);
        const apendiceArray = await getApendiceExpediente(idExpediente);
        const protocoloArray = await getProtocoloExpediente(idExpediente);
        const facturaArray = await getFacturaExpediente(idExpediente);
        //If it is undefined return
        if(!datosGenerales || !escritura || !clientesDocsArray || !tramitesArray || !apendiceArray || !protocoloArray || !facturaArray){
            return undefined;
        }
        
        return {
            id: idExpediente,
            datosGenerales : datosGenerales,
            escrituras: escritura,
            clientes: clientesDocsArray['clientes'],
            tramites: tramitesArray['tramites'],
            apendice: apendiceArray['apendice'],
            protocolo: protocoloArray['protocolo'],
            factura: facturaArray['factura'],
        };

    };

    const getExpedientes = async () => {
        try {
            const response = await apiService.get('/expedientes/', {});
            
            return response.data.map(transformDatosGenerales);
        } catch (error) {
            const errorCode = error.code || 'error';
            const errorMessage = error.message + '. Notifique del error, vuelva a cargar la página o espere un momento.';

            setErrorMessage({problem: 'Problemas al obtener los expedientes, código: '+errorCode, message: errorMessage});
            console.error('Error', error);
        }
    };

    const getExpediente = async (idExpediente) => {
        try {
            const response = await apiService.get(`/expedientes/${idExpediente}/`, {});
            
            return transformDatosGenerales(response.data);
        } catch (error) {
            const errorCode = error.code || 'error';
            const errorMessage = error.message + '. Notifique del error, vuelva a cargar la página o espere un momento.';

            setErrorMessage({problem: 'Problemas al obtener los expedientes, código: '+errorCode, message: errorMessage});
            console.error('Error', error);
        }
    };

    const getEscritura = async (idExpediente) => {
        try {
            const response = await apiService.get(`/expedientes/${idExpediente}/escritura/`, {});
            return transformEscrituras(response.data);
        } catch (error) {
            const errorCode = error.code || 'error';
            const errorMessage = error.message + '. Notifique del error, vuelva a cargar la página o espere un momento.';
        
            console.error('Error', error);
            setErrorMessage({problem: 'Problemas al obtener las escrituras, código: '+errorCode, message: errorMessage});
        }
    };

    const getClientesAndDocs = async (expedienteId) => {
        try {
            const response = await apiService.get(`/expedientes/${expedienteId}/clientes/`, {});
            return response.data;
        } catch (error) {
            const errorCode = error.code || 'error';
            const errorMessage = error.message + '. Notifique del error, vuelva a cargar la página o espere un momento.';

            console.error('Error', error);
            setErrorMessage({problem: 'Problemas al obtener los documentos de los clientes, código: '+errorCode, message: errorMessage});
        }
    }

    const getTramitesExpediente = async (expedienteId) => {
        try {
            const response = await apiService.get(`/expedientes/${expedienteId}/tramites/`, {});
            return response.data;
        } catch (error) {
            const errorCode = error.code || 'error';
            const errorMessage = error.message + '. Notifique del error, vuelva a cargar la página o espere un momento.';

            console.error('Error', error);
            setErrorMessage({problem: 'Problemas al obtener los trámites, código: '+errorCode, message: errorMessage});
        }
    }

    const getProtocoloExpediente = async ( expedienteId ) => {
        try {
            const response = await apiService.get(`/expedientes/${expedienteId}/protocolo/`, {});
            
            return response.data;
        } catch (error) {
            const errorCode = error.code || 'error';
            const errorMessage = error.message + '. Notifique del error, vuelva a cargar la página o espere un momento.';
            setErrorMessage({problem: 'Problemas al obtener el protocolo, código: '+errorCode, message: errorMessage});
            console.error('Error', error);
        }
    }

    const getApendiceExpediente = async ( expedienteId ) => {
        try {
            const response = await apiService.get(`/expedientes/${expedienteId}/apendice/`, {});
            
            return response.data;
        } catch (error) {
            const errorCode = error.code || 'error';
            const errorMessage = error.message + '. Notifique del error, vuelva a cargar la página o espere un momento.';
            console.error('Error', error);
            setErrorMessage({problem: 'Problemas al obtener el apéndice, código: '+errorCode, message: errorMessage});
        }
    }

    const getFacturaExpediente = async ( expedienteId ) => {
        try {
            const response = await apiService.get(`/expedientes/${expedienteId}/factura/`, {});
            // console.log('GET factura EXPEDIENTE', response.data)
            return response.data;
        } catch (error) {
            const errorCode = error.code || 'error';
            const errorMessage = error.message + '. Notifique del error, vuelva a cargar la página o espere un momento.';
            
            setErrorMessage({problem: 'Problemas al obtener la factura, código: '+errorCode, message: errorMessage});
            console.error('There was an error getting the info!', error);
        }
    }

    const postEmptyExpediente = async () => {
        try {
            const response = await apiService.post('/expedientes/', {}, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.status === 201) {
                return response.data['id_expediente']
            }
        } catch (error) {
            const errorCode = error.code || 'error';
            const errorMessage = error.message + '. Notifique del error, vuelva a cargar la página o espere un momento.';
            console.error('Error', error);
            setErrorMessage({problem: 'Problemas al agregar un expediente, código: '+errorCode, message: errorMessage});
        }
    }

    const postEmptyEscritura = async (expedienteId) => {
        let formattedData = {
            "libro": "",
            "volumen": "",
            "hora": null,
            "folio_inicial": "",
            "folio_final": "",
            "no_paso": "",
            "tipo_escritura": "",
            "fecha_elaboracion": null,
            "fecha_revision": null,
            "fecha_firma": null,
            "fecha_firma_todos": null,
            "fecha_impresion": null,
            "fecha_pago_impuestos": null,
            "fecha_escritura": null,
            "fecha_declaranot": null,
            "id_expediente": expedienteId
        }
        // console.log('DEBUG EXPEDIENTE ID ARGUMENT',expedienteId)
        try {
            const response = await apiService.post('/escrituras/', formattedData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            // console.log('POST EMPTY ESCRITURA',response) //  DEBUG
            if (response.status === 201) {
                return response.data['id_escritura']
            }
        } catch (error) {
            const errorCode = error.code || 'error';
            const errorMessage = error.message + '. Notifique del error, vuelva a cargar la página o espere un momento.';
            console.error('Error', error);
            setErrorMessage({problem: 'Problemas al agregar escritura, código: '+errorCode, message: errorMessage});
        }
    }

    const postEmptyProtocolo = async (expedienteId, documentType) => {
        let formattedData = {
            "tipo_documento": documentType,
            "ultima_modificacion": null,
            "ruta_archivo": null,
            "id_expediente": expedienteId
        }
        try {
            const response = await apiService.post('/protocolo/', formattedData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.status === 201) {
                return response.data
            }
        } catch (error) {
            const errorCode = error.code || 'error';
            const errorMessage = error.message + '. Notifique del error, vuelva a cargar la página o espere un momento.';
            console.error('Error', error);
            setErrorMessage({problem: 'Problemas al agregar protocolo, código: '+errorCode, message: errorMessage});
        }
    }

    const postEmptyFactura = async (expedienteId) => {
        let formattedData = {
            "ultima_modificacion": null,
            "ruta_archivo": null,
            "id_expediente": expedienteId
        }
        try {
            const response = await apiService.post('/factura/', formattedData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.status === 201) {
                return response.data
            }
        } catch (error) {
            console.error('Error posting empty factura:', error);
            const errorCode = error.code || 'error';
            const errorMessage = error.message + '. Notifique del error, vuelva a cargar la página o espere un momento.';
            console.error('Error', error);
            setErrorMessage({problem: 'Problemas al agregar factura, código: '+errorCode, message: errorMessage});
        }
    }

    const putExpediente = async (expediente) => {
        let formattingData = {
            "titulo_expediente": expediente['tituloExpediente'],
            "fecha": expediente['fechaCreacion'],

            "financiamiento": parseFloat(expediente['financiamiento'].slice(1).replaceAll(",", "")) || 0,
            "valor_operacion": parseFloat(expediente['valorOperacion'].slice(1).replaceAll(",", "")) || 0,
            "pago_efectivo": parseFloat(expediente['pagoEfectivo'].slice(1).replaceAll(",", "")) || 0,
            "pago_transferencia": parseFloat(expediente['pagoTransferencia'].slice(1).replaceAll(",", "")) || 0,
            "pago_otro": parseFloat(expediente['pagoOtro'].slice(1).replaceAll(",", "")) || 0,

            "referencia": expediente['referencia'],
            "primer_otorgante": expediente['primerOtorgante'],
            "operacion": expediente['operacion'],
            "notario": expediente['notario'],
            "responsable": expediente['responsable'],
            "secretaria": expediente['secretaria'],
            "estado_expediente": expediente['estatusExpediente'],
            "estado_pago": expediente['estatusPago'],
            "pago_impuestos": expediente['fechaPagoImpuestos'],
        }
        // console.log('FORMATTED DATA',formattingData) //  DEBUG
        try {
            const response = await apiService.put(`/expedientes/${expediente['expediente']}/`, formattingData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.status === 200) {
                console.log('Put Expediente datos grales successful')
            }
        } catch (error) {
            const errorCode = error.code || 'error';
            const errorMessage = error.message + '. Notifique del error, vuelva a cargar la página o espere un momento.';
            console.error('Error', error);
            setErrorMessage({problem: 'Problemas al actualizar expediente, código: '+errorCode, message: errorMessage});
        }
    }

    const putEscritura = async (escritura) => {
        let formattedData = {
            "id_expediente": escritura['idExpediente'],
            "titulo_escritura": escritura['tituloEscritura'],
            "libro": escritura['libro'],
            "volumen": escritura['volumen'],
            "hora": escritura['hora'],
            "folio_inicial": escritura['folioInicial'],
            "folio_final": escritura['folioFinal'],
            "no_paso": escritura['noPaso'],
            "tipo_escritura": escritura['tipoEscritura'],
            "fecha_elaboracion": escritura['fechaElaboracion'],
            "fecha_revision": escritura['fechaRevision'],
            "fecha_firma": escritura['fechaFirma'],
            "fecha_firma_todos": escritura['fechaFirmaTodos'],
            "fecha_impresion": escritura['fechaImpresion'],
            "fecha_pago_impuestos": escritura['fechaPagoImpuestos'],
            "fecha_escritura": escritura['fechaEscritura'],
            "fecha_declaranot": escritura['fechaDeclaranot'],
        }
        // console.log('FORMATTED DATA',formattedData) //  DEBUG
        try {
            const response = await apiService.put(`/escrituras/${escritura['escritura']}/`, formattedData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.status === 200) {
                console.log('Put escritura successful')
            }
        } catch (error) {
            const errorCode = error.code || 'error';
            const errorMessage = error.message + '. Notifique del error, vuelva a cargar la página o espere un momento.';
            console.error('Error', error);
            setErrorMessage({problem: 'Problemas al actualizar escritura, código: '+errorCode, message: errorMessage});
        }
    }

    const putTramite = async (idExpediente, tramite) => {
        let formattedData = {
            'concepto': tramite['concepto'],
            'recibi_de': tramite['recibiDe'],
            'usuario': tramite['usuario'],
            'estatus_recibo': tramite['estatusRecibo'],
            'fecha_recibo': tramite['fechaRecibo'],
            'monto': tramite['monto'],
            'forma_de_pago': tramite['formaDePago'],
            'id_expediente': idExpediente,
        }
        // console.log('FORMATTED DATA put tramite',formattedData) //  DEBUG
        try {
            const response = await apiService.put(`/tramites/${tramite['idTramite']}/`, formattedData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.status === 200) {
                console.log('Put tramite successful')
            }
        } catch (error) {
            const errorCode = error.code || 'error';
            const errorMessage = error.message + '. Notifique del error, vuelva a cargar la página o espere un momento.';
            console.error('Error', error);
            setErrorMessage({problem: 'Problemas al actualizar trámites, código: '+errorCode, message: errorMessage});
        }
    };

    const putClienteExpediente = async (id_registro, id_cliente, recibe, titulo_cliente) => {
        const formattedData = {
            "id_expediente": expediente.id,
            "id_cliente": id_cliente,
            "recibe_dinero": recibe,
            "titulo_cliente": titulo_cliente,
        }
        try {
            const response = await apiService.put(`/clientes_expedientes/${id_registro}/`, formattedData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return response.data
        } catch (error) {
            const errorCode = error.code || 'error';
            const errorMessage = error.message + '. Notifique del error, vuelva a cargar la página o espere un momento.';
            setErrorMessage({problem: 'Problemas al actualizar los clientes, código: '+errorCode, message: errorMessage});
        }
    };

    const deleteExpediente = async (expediente_id) => {
        try {
            const response = await apiService.delete(`/expedientes/${expediente_id}`);
            if (response.status === 204) {
                console.log('Expediente #' +expediente_id + ' borrado exitosamente')
            }
        } catch (error) {
            const errorCode = error.code || 'error';
            const errorMessage = error.message + '. Notifique del error, vuelva a cargar la página o espere un momento.';
            console.error('Error', error);
            setErrorMessage({problem: 'Problemas al eliminar expediente, código: '+errorCode, message: errorMessage});
        }
    }

    useEffect(() => {
        setLoading(() => true);
        handleEffectGetExpedientes();
    }, []);

    const handleEffectGetExpedientes = async () => {
        const expedientes  = await getExpedientes();

        if(expedientes.length <= 0){
            setLoading(() => false);
            return;
        }

        const options = createOptions(expedientes);
        

        setOptions(options);

        const selectedLast = expedientes[expedientes.length - 1];

        const selectedExpediente = await mergeData(selectedLast.expediente);
        
        setExpediente(() => selectedExpediente);
        setLoading(() => false);
    };

    const handleChange = async (selected) => {
        if(expediente && expediente.id === selected){
            return;
        }

        setLoading(() => true);
        
        const selectedExpediente = await mergeData(selected);

        setExpediente(selectedExpediente);
        setLoading(() => false);
    }

    const handleDelete = async () => {
        setLoading(() => true);

        await deleteExpediente(expediente.id)
        setOptions((prev) => prev.filter(element => element.value !== expediente.id));

        if(options.length <= 0){
            return;
        }
        setExpediente(() => undefined);
        setConfirmDelete(undefined);
        setLoading(() => false);
    }

    const handleAdd = async () => {
        if (tituloExpediente === '') {
            return;
        };
        const newExpediente = {
            id: 0,
            datosGenerales:
                initialExpedienteStructure['datosGenerales'],
            escrituras: 
                initialExpedienteStructure['escrituras'],
            clientes: [],
            tramites: [],
            apendice: [],
            protocolo:
                initialExpedienteStructure['protocolo'],
            factura:
                initialExpedienteStructure['factura'],
            };
            
        setIsModalOpen(() => undefined);
        setLoading(() => true);
        const newExpedienteId = await postEmptyExpediente()
        const newEscrituraId = await postEmptyEscritura(newExpedienteId)

        if(newExpedienteId === undefined && newEscrituraId === undefined) {
            return;
        };

        const protocoloDocs = ['Testimonio', 'Escritura']
        const protocoloPromises = protocoloDocs.map(async (documentType, index) => {
            const newProtocoloId = await postEmptyProtocolo(newExpedienteId, documentType);
            newExpediente.protocolo[index].idDocumento = newProtocoloId['id_documento_protocolo'];
            newExpediente.protocolo[index].tipoDocumento = newProtocoloId['tipo_documento'];
        });
        // Wait for all protocolo promises to resolve
        await Promise.all(protocoloPromises);

        const emptyFacturaPost = await postEmptyFactura(newExpedienteId);
        newExpediente.factura[0].idDocumento = emptyFacturaPost['id_documento_factura'];

        newExpediente.datosGenerales.expediente = newExpedienteId;
        newExpediente.datosGenerales.tituloExpediente = tituloExpediente;
        await putExpediente(newExpediente.datosGenerales);
        newExpediente.id = newExpedienteId;
        newExpediente.escrituras.escritura = newEscrituraId;
        newExpediente.escrituras.idExpediente = newExpedienteId;
        newExpediente.a = newExpedienteId;

        setOptions((prev) => [
            ...prev,
            {
                value: newExpediente.id,
                label: newExpediente.datosGenerales.tituloExpediente,
            }
        ]);

        setExpediente(newExpediente);

        setLoading(() => false);
        closeModal();
    }

    const handleSave = async () => {
        setSaveStatus(() => 'Guardando...');

        await putExpediente(expediente['datosGenerales'])
        await putEscritura(expediente['escrituras'])
        const tramitePromises = expediente.tramites.map(async (tramite) => {
            await putTramite(expediente.id, tramite);
        });
        await Promise.all(tramitePromises);
        const clientePromises = expediente.clientes.map( async (cliente) => {
            await putClienteExpediente(cliente.id_registro, cliente.id_cliente, cliente.recibe_dinero, cliente.titulo_cliente);
        });
        await Promise.all(clientePromises);
        
        setSaveStatus(() => 'Guardado');
    }

    const handleInputChange = (event) => {
        setTituloExpediente(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setTituloExpediente('');
    };

    return (
        <>
        {
            errorMessage && <Modal handleClose={handleClose}>
                <ErrorMessage problem={errorMessage.problem} message={errorMessage.message}/>
            </Modal>
        }
        <div className={classes.container}>
            <h1>Expedientes</h1>
            <hr/>
            <p>
                Agrega, edita y elimina expedientes, así como enlazar clientes a expedientes, manejar trámites, agregar archivos al apéndice y al procolo.
            </p>
            { !loading &&
            <div className={classes.buttonContainer}>
                <div>
                    <div className={classes.selectContainer}>
                        <Select 
                            placeholder='Selecciona expediente'
                            options={options}
                            onChange={(selected) => handleChange(selected.value)}
                            isSearchable={true}
                            value={options.find((option) => option.value === (expediente?.id || '') || '')}
                            classNames={customClassNames}
                            theme={customSelectTheme}
                        />
                    </div>
                </div>
                <div className={classes.buttonsLoader}>
                    {/* Change this later to type='submit' */}
                    <p>{saveStatus}</p>
                    <Button type='button' className={'button'} action={() => setIsModalOpen(true)}>Nuevo</Button>
                    <Button type='button' className={'button'} action={handleSave} disabled={expediente === undefined}>Guardar</Button>
                    <Button type='button' action={() => handleConfirmDelete(expediente)} disabled={expediente === undefined}>Eliminar</Button>
                </div>
            </div>
            }

            {
                confirmDelete && <Modal handleClose={handleCancel}>
                    <div className={classes.deleteModal}>
                        <h1>
                            Eliminando...
                        </h1>

                        <p>
                            ¿Estas seguro de eliminar el expediente {confirmDelete.datosGenerales.tituloExpediente}?
                        </p>
                        
                        <div className={classes.deleteModalButtons}>
                            <Button action={handleDelete}>
                                Eliminar
                            </Button>
                            <Button action={handleCancel} className={'inverted'}>
                                Cancelar
                            </Button>
                        </div>
                    </div>
                </Modal>
            }

            { isModalOpen &&
            <form onSubmit={handleSubmit}>
                <Modal handleClose={closeModal}>
                    <h2 className={classes.title}>Añadir expediente</h2>
                    <div className={classes.addingContainer}>
                        <p> Título del expediente </p>
                        {/* <input className={classes.input} id='tituloExpediente' value={tituloExpediente} onChange={handleInputChange} required /> */}
                        <Input placeholder='Ejemplo: 13/24' className={classes.input} id='tituloExpediente' onChange={handleInputChange} required />
                    </div>
                    <div>
                        <Button type="button" action={closeModal}>Cancelar</Button>
                        <Button className='inverted' type="submit" action={handleAdd}>Añadir</Button>
                    </div>
                </Modal>
            </form>
            }

            { (JSON.stringify(expediente) !== '{}' && expediente !== undefined) && !loading && <Tabs>
                    <TabList>
                        <Tab><h3>Datos generales</h3></Tab>
                        <Tab><h3>Protocolo</h3></Tab>
                        <Tab><h3>Escrituras</h3></Tab>
                        <Tab><h3>Clientes</h3></Tab>
                        <Tab><h3>Trámites</h3></Tab>
                        <Tab><h3>Apéndice</h3></Tab>
                        <Tab><h3>Factura</h3></Tab>
                    </TabList>

                    
                    <TabPanel>
                        <DatosGenerales expediente={expediente} setExpediente={setExpediente} setSaveStatus={setSaveStatus}/>
                    </TabPanel>
                    <TabPanel>
                        <Protocolo expediente={expediente} setExpediente={setExpediente}/>
                    </TabPanel>
                    <TabPanel>
                        <Escrituras expediente={expediente} setExpediente={setExpediente} setSaveStatus={setSaveStatus}/>
                    </TabPanel>
                    <TabPanel>
                        <Clientes expediente={expediente} setExpediente={setExpediente}/>
                    </TabPanel>
                    <TabPanel>
                        <Tramites expediente={expediente} setExpediente={setExpediente} setSaveStatus={setSaveStatus}/>
                    </TabPanel>
                    <TabPanel>
                        <Apendice expediente={expediente} setExpediente={setExpediente}/>
                    </TabPanel>
                    <TabPanel>
                        <Factura expediente={expediente} setExpediente={setExpediente}/>
                    </TabPanel>
                </Tabs>
            }
            { options.length === 0 && !loading && <NoInfo text={'Agrega un expediente'}/> }
            { options.length > 0 && expediente===undefined && !loading && <NoInfo text={'Selecciona un expediente'}/> }
            { loading && <Loader /> }
        </div>
        </>
    )
}
