import { useState } from "react";
import DocumentCard from "./DocumentCard";
import Button from "./Button";
import {InputFile} from "./InputFile";
import apiService from '../utils/apiService';
import classes from './Apendice.module.css';
import NoInfo from "./NoInfo";


import Modal from './Modal';
import ErrorMessage from './ErrorMessage';
import Loader from './Loader';

export default function ApendiceActa({acta, setActa}){
    const [isOpenModal, setIsOpenModal] = useState(undefined);
    const [selectedDocument, setSelectedDocument] = useState(undefined);

    const [description, setDescription] = useState('');
    const [ultimaModificacion, setUltimaModificacion] = useState('');
    const [file, setFile] = useState('');
    const [fileName, setFileName] = useState('Subir archivo');

    //Connection handler
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        setErrorMessage(undefined);
    };

    const setDefaults = () => {
        setDescription('')
        setUltimaModificacion('')
        setFile('')
        setFileName('Subir archivo')
    }

    const postApendice = async (idActa) => {
        let formattedData = {
            "nombre_doc": description,
            "ultima_modificacion": ultimaModificacion,
            "ruta_archivo": file,
            "id_acta": idActa,
        }
        try {
            const response = await apiService.post('/apendice_acta/', formattedData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            // console.log('POST apendice',response) //  DEBUG
            if (response.status === 201) {
                return response.data
            }
        } catch (error) {
            setLoading(() => false);
            setIsOpenModal(() => undefined);
            const errorCode = error.code || 'error';
            const errorMessage = error.message + '. Notifique del error, vuelva a cargar la página o espere un momento.';
            setErrorMessage({problem: 'Problemas al agregar el documento, código: '+errorCode, message: errorMessage});
        }
    }

    const deleteApendiceDoc = async (idDoc) => {
        try {
            const response = await apiService.delete(`/apendice_acta/${idDoc}/`);
            // console.log(response) //  DEBUG
            if (response.status === 204) {
                console.log('Documento de apendice de acta #' + idDoc + ' borrado exitosamente')
            }
        } catch (error) {
            const errorCode = error.code || 'error';
            const errorMessage = error.message + '. Notifique del error, vuelva a cargar la página o espere un momento.';
            setErrorMessage({problem: 'Problemas al eliminar el documento, código: '+errorCode, message: errorMessage});
        }
    }

    const handleInputChange = (event) => {
        setDescription(event.target.value);
    };

    const handleInitialAdd = () => {
        setIsOpenModal('add')
    }

    const handleFileChange = async (file) => {
        const  fileType = file['type'];
        const validImageTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/x-iwork-pages-sffpages'];
        if (!validImageTypes.includes(fileType)) {
            
            setErrorMessage({problem: 'Error al adjuntar el archivo', message: 'Tipo de archivo equivocado, solo se permite PDF, WORD y PAGES'});
            
            return;
        }
        setFile(file);
        setFileName(file['name'])
    };

    const handleAdd = async () => {
        setLoading(() => true);
        if (description === ''){
            setIsOpenModal(undefined);
            setLoading(() => false);
            setErrorMessage({problem: 'Error al agregar apéndice', message: 'Agrega una descripción...'});
            return;
        };
        if (file === ''){
            setIsOpenModal(undefined);
            setLoading(() => false);
            setErrorMessage({problem: 'Error al agregar apéndice', message: 'Archivo no adjunto...'});
            return;
        };

        const currentDate = new Date();
        const formattedDate = currentDate.getFullYear() + '-' + String(currentDate.getMonth() + 1).padStart(2, '0') + '-' + String(currentDate.getDate()).padStart(2, '0');
        setUltimaModificacion(formattedDate);
        setIsOpenModal(undefined);
        const response = await postApendice(acta.idActa);
        if(response === undefined) {
            return;
        }
        console.log('TEST RESPONSE POST APENDICE', response)
        const createdDocument = {
            idDocApendice: response['id_doc_apendice'],
            nombreDoc: response['nombre_doc'],
            ultimaModificacion: response['ultima_modificacion'],
            rutaArchivo: response['ruta_archivo'],
        }
        setActa((prev) => ({
            ...prev,
            apendice: [
                ...prev.apendice,
                createdDocument,
            ]
        }))
        setIsOpenModal(false);
        setDefaults();

        setLoading(() => false);
    }

    const handleCancel = () => {
        setIsOpenModal(null)
        setDefaults()
    }

    function handleOpening(apendiceDoc){
        setIsOpenModal('open');
        setSelectedDocument(apendiceDoc);
    }

    const handleDelete = async (idDoc) => {
        setLoading(() => true);
        setIsOpenModal(undefined);
        await deleteApendiceDoc(idDoc);
        setActa((prev) => ({
            ...prev,
            apendice : prev.apendice.filter(
                (apendiceDocument) => apendiceDocument.idDocApendice !== selectedDocument.idDocApendice
            )
        }))
        setIsOpenModal(null);
        setLoading(() => false);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
    };

    return(
        <>
        { errorMessage && <Modal handleClose={handleClose}>
            <ErrorMessage problem={errorMessage.problem} message={errorMessage.message}/>
            </Modal>
        }

        { loading &&
        <div className={classes.loaderContainer}>
            <Loader />
        </div>
        }

        <div style={{display:'flex', justifyContent:'center', paddingTop: '20px'}}>
            <Button action={handleInitialAdd} type="button">Agregar</Button>
        </div>
        {/* ADDING THE DOCUMENT SCREEN */}
        {isOpenModal === 'add' && 
        <form onSubmit={handleSubmit}>
            <Modal handleClose={() => setIsOpenModal(null)}>
                <h2 className={classes.title}>Añadir documento a apéndice</h2>
                <div className={classes.container}>
                    <label> Descripción </label>
                    <input className={classes.input} id='docDescription' onChange={handleInputChange} required/>
                </div>
                <div className={classes.container}>
                    <InputFile handleFile={handleFileChange} fileName={fileName}/>
                </div>
                <div>
                    <Button className='inverted' type="submit" action={handleAdd}>Agregar</Button>
                    <Button type="button" action={handleCancel}>Cancelar</Button>
                </div>
            </Modal>
        </form>
        }
        {/* VIEW OF THE DOC SCREEN */}
        {isOpenModal === 'open' &&
        <Modal handleClose={() => setIsOpenModal(null)}>
            <iframe src={selectedDocument.rutaArchivo} width="100%" title="doc"/>
            <div>
            {/* selectedDocument['rutaArchivo'].split('?')[0] */}
                <a href={selectedDocument.rutaArchivo} download="doc" target='_blank' rel="noreferrer">
                    <Button type="button" className={'inverted'}>Descargar {selectedDocument.nombreDoc}</Button>
                </a>
                <Button type="button" className={'inverted'} action={() => setIsOpenModal(null)}>Cerrar</Button>
                <Button type="button" action={() => handleDelete(selectedDocument['idDocApendice'])}>Eliminar {selectedDocument.id}</Button>
            </div>
        </Modal>}

        <div className={classes.documentsContainer}>
            { acta.apendice.map((apendiceDoc) => {
                return <span key={apendiceDoc.idDocApendice}>
                    <DocumentCard description={apendiceDoc.nombreDoc} onClick={() => handleOpening(apendiceDoc)}/>
                </span>
            })}
        </div>

        { acta.apendice.length <= 0 && <NoInfo text={'Agrega un documento'}/> }
        </>
    );
}
