import classes from './DocumentCard.module.css';
import logo from '../img/logo192.png';

export default function DocumentCard({description, ...props}){

    return (
        <>
            <div className={classes.wrapper} {...props}>
                <p>
                    Documento
                </p>
                <p className={classes.title}>{description}</p>
            </div>
        </>
    );
}