import { useRef } from "react";
import classes from './InputFile.module.css'


//HandleFile is the function that handles the file lmao
export const InputFile = ({ handleFile, id, fileName = 'Subir archivo' }) => {
  // Create a reference to the hidden file input element
  const hiddenFileInput = useRef(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (e) => {
    hiddenFileInput.current.click();
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    handleFile(fileUploaded, id);
  };
  return (
    <>
      <button className={classes.input} onClick={handleClick} type="button">
        {fileName}
      </button>
      <input
        type="file"
        onChange={handleChange}
        ref={hiddenFileInput}
        style={{ display: "none" }} // Make the file input element invisible
      />
    </>
  );
};
