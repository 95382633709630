import { redirect } from 'react-router-dom';

import apiService from '../utils/apiService';

export function getAuthToken(){
    const token = localStorage.getItem('access_token');
  
    if(!token){
        return;
    }
    
    return token;
  }

async function grantPermission(permission){
  localStorage.getItem('user_id');

  const permissionStatus = await getPermission(permission);

  if(permissionStatus){
    return permissionStatus;
  }

  return undefined;
  
}

async function getPermission(permission){
  try {
    const response = await apiService.get(`/user/${localStorage.getItem("user_id")}/permiso/${permission}`, {});
    // setExpedientes(transformExpediente(response.data));

    console.log(response.data);
    return response.data;
  } catch (error) {
      console.error('There was an error getting the info!', error);
      return undefined;
  }
}
  
export async function homeAuthLoader(){
    const token = getAuthToken();
    
    if(!token){
      return redirect('/');
    }

    const permission = await grantPermission('Home');
  
    if(!permission){
      return redirect('/home');
    }
  
    return null;
}

export async function clientesAuthLoader(){
    const token = getAuthToken();
    
    if(!token){
      return redirect('/');
    }
    
    const permission = await grantPermission('Clientes');
  
    if(!permission){
      return redirect('/home');
    }
  
    return null;
}

export async function expedientesAuthLoader(){
    const token = getAuthToken();
    
    if(!token){
      return redirect('/');
    }
    
    const permission = await grantPermission('Expedientes');
  
    if(!permission){
      return redirect('/home');
    }
  
    return null;
}

export async function indiceAuthLoader(){
    const token = getAuthToken();
    
    if(!token){
      return redirect('/');
    }
    
    const permission = await grantPermission('Indice');
  
    if(!permission){
      return redirect('/home');
    }

    return null;
}

export async function configurationAuthLoader(){
    const token = getAuthToken();
    
    if(!token){
      return redirect('/');
    }
    
    const permission = await grantPermission('Configuracion');
  
    if(!permission){
      return redirect('/home');
    }

    return null;
}

export async function formatsAuthLoader(){
  const token = getAuthToken();
  
  if(!token){
    return redirect('/');
  }
  
  const permission = await grantPermission('Formatos');
  
  if(!permission){
    return redirect('/home');
  }

  return null;
}

export async function presupuestoAuthLoader(){
  const token = getAuthToken();
  
  if(!token){
    return redirect('/');
  }
  
  const permission = await grantPermission('Presupuesto');
  
    if(!permission){
      return redirect('/home');
    }

  return null;
}

export async function actasAuthLoader(){
  const token = getAuthToken();
  
  if(!token){
    return redirect('/');
  }
  
  const permission = await grantPermission('Actas');
  
  if(!permission){
    return redirect('/home');
  }

  return null;
}