import classes from './Notaria.module.css';
import apiService from '../utils/apiService';

import Input from './Input';
import Button from './Button';
import { useEffect, useState } from 'react';
import ErrorMessage from './ErrorMessage';
import Modal from './Modal';
import Loader from './Loader';

import FileSaver from 'file-saver';

export default function Notaria(){
    const [input, setInput] = useState({
        nombre: "",
        numero: "",
        domicilio: "",
        telefono: "",
        
        curp: "",
        rfc: "",
        ciudad: "",
        estado: "",
        
        mesesAntilavado: 0,
        uma: 0,
    });

    const [errorMessage, setErrorMessage] = useState(undefined);
    
    const [loading, setLoading] = useState(false);
    const [saveStatus, setSaveStatus] = useState('No guardado');

    const transformNotaria = (data) => ({
        nombre: data.nombre,
        numero: data.num_notaria,
        domicilio: data.domicilio,
        telefono: data.telefono,

        curp: data.curp,
        rfc: data.rfc,
        ciudad: data.ciudad,
        estado: data.estado,

        mesesAntilavado: data.meses_antilavado,
        uma: data.valor_uma
    });

    const getConfig = async () => {
        try {
            const response = await apiService.get('/config_notaria/1/', {});
            // setExpedientes(transformExpediente(response.data));

            setInput(transformNotaria(response.data));
            setLoading(() => false);
        } catch (error) {
            const errorCode = error.code || 'Error';
            const errorMessage = error.message + '. Notifique del error, vuelva a cargar la página o espere un momento';
            setErrorMessage({problem: 'Problemas al cargar información, código: '+errorCode, message: errorMessage});
            console.error('There was an error getting the info!', error);
        }
    };

    const putConfig = async (data) => {
        let formattingData = {
            "nombre": data['nombre'],
            "num_notaria": data['numero'],
            "telefono": data['telefono'],
            "domicilio": data['domicilio'],
            "curp": data['curp'],
            "rfc": data['rfc'],
            "ciudad": data['ciudad'],
            "estado": data['estado'],
            "meses_antilavado": data['mesesAntilavado'],
            "valor_uma": data['uma']
        }
        // console.log('FORMATTED DATA',formattingData) //  DEBUG
        try {
            const response = await apiService.put(`/config_notaria/1/`, formattingData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            // console.log('PUT RESPONSE',response) //  DEBUG
            if (response.status === 200) {
                console.log('Put notaría datos grales successful');
                setSaveStatus('Guardado');
            }
        } catch (error) {
            const errorCode = error.code || 'error';
            const errorMessage = error.message + '. Notifique del error, vuelva a cargar la página o espere un momento, los cambios hechos no se aplicarán';
            setErrorMessage({problem: 'Problemas al actualizar la información, código: '+errorCode, message: errorMessage});
            console.error('Error putting notaría datos grales:', error);
        }
    }
    
    useEffect(() => {
        setLoading(() => true);
        getConfig();
    }, []);

    const handleSave = async () => {
        setSaveStatus('Guardando...');
        await putConfig(input);
        console.log(input);
    };

    const handleChange = (e) => {
        setSaveStatus(() => 'No guardado');
        const { name, value } = e.target;
        setInput((prev) => ({
          ...prev,
          [name]: value,
        }));

    };

    const handleClose = () => {
        setErrorMessage(undefined);
    };

    const handleExport = async () => {
        try {
            const response = await apiService.get('/exportar-clientes/', {
                responseType: 'blob',
            });
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            FileSaver.saveAs(blob, 'datos_notaria137.xlsx');
        } catch (error) {
            const errorCode = error.code || 'error';
            const errorMessage = error.message + '. Notifique del error, vuelva a cargar la página o espere un momento.';
            setErrorMessage({problem: 'Problemas al exportar los clientes, código: '+errorCode, message: errorMessage});
        }
    };

    return (
        <>
        {
            errorMessage && <Modal handleClose={handleClose}>
                <ErrorMessage problem={errorMessage.problem} message={errorMessage.message}/>
            </Modal>
        }

        {
                loading && <Loader />
        }
        {
            !loading && <>
                 <div className={classes.header}>
            <p>{saveStatus}</p>
            <Button action={handleSave}>Guardar</Button>
            <Button type='button' className='inverted' action={handleExport}>Exportar datos</Button>
        </div> <div className={classes.container}>
            <div className={classes.innerContainer}>
                <Input label={'Nombre de la notaría'} type='text' name='nombre' value={input.nombre} onChange={handleChange}/>

                <Input label={'Número de la notaría'} type='number' name='numero' value={input.numero} onChange={handleChange}/>

                <Input label={'Domicilio'} type='text' name='domicilio' value={input.domicilio} onChange={handleChange}/>

                <Input label={'Teléfono'} type='text' name='telefono' value={input.telefono} onChange={handleChange}/>
            </div>

            <div className={classes.innerContainer}>
                <Input label={'CURP'} type='text' name='curp' value={input.curp} onChange={handleChange}/>

                <Input label={'RFC'} type='text' name='rfc' value={input.rfc} onChange={handleChange}/>

                <Input label={'Ciudad'} type='text' name='ciudad' value={input.ciudad} onChange={handleChange}/>

                <Input label={'Estado'} type='text' name='estado' value={input.estado} onChange={handleChange}/>
            </div>

            <div className={classes.innerContainerAntiLavado}>
                <p className={classes.text}>Antilavado</p>
                <Input label={'Meses antilavado'} type='number' name='mesesAntilavado' value={input.mesesAntilavado} onChange={handleChange}/>

                <Input label={'Valor de UMA'} type='number' step="0.01" name='uma' value={input.uma} onChange={handleChange}/>
                
            </div>

            </div>
            </>
        }
        </>
    );
}