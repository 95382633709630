import classes from './Modal.module.css';
import close from '../img/icons/close.svg';

export default function Modal({children, handleClose}){

    return(
        <div className={classes.modal}>
            <div className={classes.container}>
                <img src={close} className={classes.close} onClick={handleClose} alt='Close'/>
                {children}
            </div>
        </div>
    );
}
