import Input from "./Input";
import Select from 'react-select';
import { customSelectTheme, customClassNames } from '../utils/selectCustomizations';

import classes from './Escrituras.module.css';
import '../style/select.css';

const optionsNoPaso = [
    {label: 'Si paso', value: 'Si paso'},
    {label: 'No paso', value: 'No paso'},
]

export default function Escrituras({expediente, setExpediente, setSaveStatus}){
    const handleChange = (e) => {
        setSaveStatus(() => 'No guardado');
        
        const { name, value } = e.target;
        setExpediente((prev) => ({
            ...prev,
            escrituras: {
                ...prev.escrituras,
                [name] : value
            },
        }));

    };

    const handleSelect = (valueDef, nameDef) => {
        const value = valueDef.value;
        const name = nameDef;

        setSaveStatus(() => 'No guardado');

        setExpediente((prev) => ({
            ...prev,
            escrituras: {
                ...prev.escrituras,
                [name] : value
            },
        }));
    };

    // console.log(expediente.escrituras);
    return(
        <div className={classes.formContainer}>
            <div className={classes.innerContainer}>
                <Input name={'tituloEscritura'} label={'Título escritura'}
                value={expediente.escrituras.tituloEscritura}
                onChange={handleChange}/>

                <Input name={'libro'}  label={'Libro'} type='text'
                value={expediente.escrituras.libro || ''}
                onChange={handleChange}/>

                <Input name={'volumen'} label={'Volumen'} type='text'
                value={expediente.escrituras.volumen || ''}
                onChange={handleChange}/>

                <Input name={'hora'}  label={'Hora'} type='time'
                value={expediente.escrituras.hora || ''}
                onChange={handleChange}/>
            </div>

            <div className={classes.innerContainer}>
                <span>
                    <label>No paso</label>
                    <Select 
                    className={classes.selectDG} 
                    onChange={(choice) => handleSelect(choice, 'noPaso')}
                    name="noPaso"
                    // value={{label: expediente.escrituras.noPaso.charAt(0).toUpperCase() + expediente.escrituras.noPaso.slice(1), value: expediente.escrituras.noPaso}}
                    // value={{label: expediente.escrituras.noPaso, value: expediente.escrituras.noPaso}}
                    value={optionsNoPaso.find((option) => option.value === expediente.escrituras.noPaso) || ''}
                    options={optionsNoPaso}
                    classNames={customClassNames}
                    theme={customSelectTheme}
                    />
                </span>

                <Input name={'folioInicial'}  label={'Folio inicial'} type='text'
                value={expediente.escrituras.folioInicial || ''}
                onChange={handleChange}/>

                <Input name={'folioFinal'}  label={'Folia final'} type='text'
                value={expediente.escrituras.folioFinal || ''}
                onChange={handleChange}/>
            </div>

            <div className={classes.innerContainer}>
                <Input name={'fechaElaboracion'}  label={'Fecha de elaboración'} type='date'
                value={expediente.escrituras.fechaElaboracion || ''}
                onChange={handleChange}/>

                <Input name={'fechaRevision'}  label={'Fecha de revisión'} type='date'
                value={expediente.escrituras.fechaRevision || ''}
                onChange={handleChange}/>

                <Input name={'fechaFirma'}  label={'Fecha de firma'} type='date'
                value={expediente.escrituras.fechaFirma || ''}
                onChange={handleChange}/>

                <Input name={'fechaFirmaTodos'}  label={'Fecha de firma todos'} type='date'
                value={expediente.escrituras.fechaFirmaTodos || ''}
                onChange={handleChange}/>
            </div>

            <div className={classes.innerContainer}>
                <Input name={'fechaImpresion'} label={'Fecha de impresión'} type='date'
                value={expediente.escrituras.fechaImpresion || ''}
                onChange={handleChange}/>

                <Input name={'fechaPagoImpuestos'} label={'Fecha de pago de impuestos'} type='date'
                value={expediente.escrituras.fechaPagoImpuestos || ''}
                onChange={handleChange}/>

                <Input name={'fechaEscritura'} label={'Fecha de escritura'} type='date'
                value={expediente.escrituras.fechaEscritura || ''}
                onChange={handleChange}/>

                <Input name={'fechaDeclaranot'} label={'Fecha de declaranot'} type='date'
                value={expediente.escrituras.fechaDeclaranot || ''}
                onChange={handleChange}/>
            </div>
        </div>
    );
}
