import { NavLink } from "react-router-dom";

import logo from '../img/logo-sm.jpeg';

import classes from './MainNavigation.module.css';

import {IoClose, IoMenu} from 'react-icons/io5';
import { useMediaQuery } from "react-responsive";
import { useEffect, useState } from "react";

const routes = [
    { path: '/home', label: 'Home', permission: 'Home' },
    { path: '/expedientes', label: 'Expedientes', permission: 'Expedientes' },
    { path: '/actas', label: 'Actas', permission: 'Actas' },
    { path: '/clientes', label: 'Clientes', permission: 'Clientes' },
    { path: '/indice', label: 'Índice', permission: 'Indice' },
    { path: '/presupuesto', label: 'Presupuesto', permission: 'Presupuesto' },
    { path: '/formatos', label: 'Formatos', permission: 'Formatos' },
    { path: '/configuration', label: 'Configuración', permission: 'Configuracion' }
];

function MainNavigation(){
    const [showMenu, setShowMenu] = useState(false);
    const isMobile = useMediaQuery({ maxWidth: "1000px" });

    const [info, setInfo] = useState(undefined);

    const toggleMenu = () => {
      setShowMenu(!showMenu);
    };

    useEffect(() => {
        const retrievedObject = JSON.parse(localStorage.getItem("user_info"));

        const permissions = retrievedObject.permissions.map(a => a.permission);
        setInfo(() => permissions);
    }, []);

    const closeMenuOnMobile = () => {
        if (isMobile) {
        setShowMenu(false);
      }
    };

    return (
        <header className={classes.header}>
            <nav className={classes.navbar}>
                <NavLink 
                    to="/home" 
                    end
                >
                    <img src={logo} className={classes.logo} alt="logo"/>
                </NavLink>
                <ul className={`${classes.list} ${showMenu ? classes.showMenu : ""}`}>
                    {
                        routes.map((route) => {
                        // Check if user has the required permission
                        if(info){
                            const hasPermission = info.includes(route.permission);

                            return hasPermission ? (
                                <li key={route.path}>
                                    <NavLink 
                                        to={route.path}
                                        className={({isActive}) => isActive ? classes.active : undefined}
                                        onClick={closeMenuOnMobile}
                                    >
                                        {route.label}
                                    </NavLink>
                                </li>
                            ) : null;
                        }
                        return null;  
                        // Do not render the link if the user lacks permission
                    })}
                {
                    isMobile && <div className={classes.nav__close} id="nav-close" onClick={toggleMenu}>
                    <IoClose />
                </div>
                }
                </ul>
            </nav>

            {
                isMobile && <div className={classes.nav__toggle} id="nav-toggle" onClick={toggleMenu}>
                <IoMenu />
            </div>
            }
        </header>
    );
}

export default MainNavigation;