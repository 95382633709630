import apiService from '../utils/apiService';
import { useEffect, useState } from 'react';
import { customSelectTheme, customClassNames } from '../utils/selectCustomizations';
import classes from './Clientes.module.css';
import '../style/active.css';
import '../style/select.css';

import Select from 'react-select';

import Input from "./Input";
import Button from './Button';
import DocumentCard from './DocumentCard';
import Modal from './Modal';


import ErrorMessage from './ErrorMessage';
import Loader from './Loader';


const optionsRecibeDinero = [
    {label: 'Si recibe', value: 'Si recibe'},
    {label: 'No recibe', value: 'No recibe'},
]

export default function Clientes({expediente, setExpediente}){
    const [document, setDocument] = useState(undefined);
    const [selectedClient, setSelectedCliente] = useState(expediente.clientes[0]);
    const [optionsClientes, setOptionsClientes] = useState([]);
    const [selectedDocumentRoute, setSelectedDocumentRoute] = useState('')

    console.log(expediente.clientes[0]);
    //Connection handler
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        setErrorMessage(undefined);
    };

    const getClientes = async () => {
        try {
            const response = await apiService.get('/clientes/', {});
            return response.data
        } catch (error) {
            const errorCode = error.code || 'error';
            const errorMessage = error.message + '. Notifique del error, vuelva a cargar la página o espere un momento.';
            setErrorMessage({problem: 'Problemas al obtener los clientes, código: '+errorCode, message: errorMessage});
        }
    };

    const mapClientOptions = async () => {
        const clientes = await getClientes();

        if(clientes === undefined) return;

        const mappedData = clientes.map(cliente => {
            return {
                id: cliente.id_cliente,
                label: cliente.nombre + ' ' + cliente.apellido_paterno + ' ' + cliente.apellido_materno,
                value: cliente.nombre + ' ' + cliente.apellido_paterno + ' ' + cliente.apellido_materno,
            }
        })
        mappedData.sort(function (a, b) {
            if (a.label < b.label) {
                return -1;
            }
            if (a.label > b.label) {
                return 1;
            }
            return 0;
        })

        setLoading(() => false);
        setOptionsClientes(mappedData);
    }

    const getClienteDocs = async (id_cliente) => {
        try {
            const response = await apiService.get(`/clientes/${id_cliente}/docs/`, {});
            return response.data
        } catch (error) {
            const errorCode = error.code || 'error';
            const errorMessage = error.message + '. Notifique del error, vuelva a cargar la página o espere un momento.';
            setErrorMessage({problem: 'Problemas al obtener los documentos de los clientes, código: '+errorCode, message: errorMessage});
        }
    };

    const postClienteExpediente = async (id_expediente, id_cliente) => {
        const formattedData = {
            "id_expediente": id_expediente,
            "id_cliente": id_cliente
        }
        try {
            const response = await apiService.post(`/clientes_expedientes/`, formattedData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return response.data
        } catch (error) {
            const errorCode = error.code || 'error';
            const errorMessage = error.message + '. Notifique del error, vuelva a cargar la página o espere un momento.';
            setErrorMessage({problem: 'Problemas al agregar un cliente al expediente, código: '+errorCode, message: errorMessage});
        }
    };

    const deleteClienteExpediente = async (id_cliente, id_expediente) => {
        try {
            const response = await apiService.delete(`/clientes/${id_cliente}/expedientes/${id_expediente}/`);
            if (response.status === 204) {
                console.log('ClienteExpediente deleted successfully');
            }
        } catch (error) {
            const errorCode = error.code || 'error';
            const errorMessage = error.message + '. Notifique del error, vuelva a cargar la página o espere un momento.';
            setErrorMessage({problem: 'Problemas al eliminar un clientes, código: '+errorCode, message: errorMessage});
        }
    };

    useEffect(() => {
        setLoading(() => true);
        mapClientOptions();
    }, [])

    useEffect(() => {
        setSelectedCliente(expediente.clientes[0]);
    }, [expediente])


    const handleSelect = (cliente) => {
        setSelectedCliente(cliente);
    };

    function handleOpening(index){
        setSelectedDocumentRoute(selectedClient.docs[index]['archivo'])
        setDocument(selectedClient.docs[index]);
    };

    const handleDelete = async () => {
        setLoading(() => true);
        const deleteClienteExp = await deleteClienteExpediente(selectedClient.id_cliente, expediente.id)
        setExpediente((prev) => ({
            ...prev,
            clientes : [
                ...prev.clientes.filter((cliente) => cliente !== selectedClient)
            ]
        }));

        setLoading(() => false);
        setSelectedCliente(undefined);
    };

    const handleSelectAdd = async (valueDef) => {

        setLoading(() => true);
        const value = valueDef.value;
        const findInArchive = expediente.clientes.find((cliente) => cliente.cliente === value);
        if (findInArchive) return;

        let clientesSistema = await getClientes();
        if(clientesSistema === undefined) return;

        const selected = clientesSistema.find((cliente) => (cliente.nombre + ' ' + cliente.apellido_paterno + ' ' + cliente.apellido_materno) === value);
        let clienteDocs = await getClienteDocs(selected.id_cliente);
        const postExpediente = await postClienteExpediente(expediente.id, selected.id_cliente);
        const clienteInfo = await getCliente(selected.id_cliente);

        const formattedClientInfo = {
            "cliente": clienteDocs.cliente,
            "docs": clienteDocs.docs,
            "id_cliente": clienteDocs.id_cliente,
            "id_registro": postExpediente.id,
            "recibe_dinero": postExpediente.recibe_dinero,
            "titulo_cliente": postExpediente.titulo_cliente,
            ...clienteInfo
        }

        setExpediente((prev) => ({
            ...prev,
            clientes : [
                ...prev.clientes,
                formattedClientInfo,
            ]
        }));

        setLoading(() => false);
    };

    const getCliente = async (id) => {
        try {
            const response = await apiService.get(`/clientes/${id}/`, {});
            return response.data;
        } catch (error) {
            const errorCode = error.code || 'error';
            const errorMessage = error.message + '. Notifique del error, vuelva a cargar la página o espere un momento.';
            setErrorMessage({problem: 'Problemas al obtener los clientes, código: '+errorCode, message: errorMessage});
        }
    };

    const handleClienteRecibe = async (selectedOption, cliente) => {
        setLoading(() => true);
        const objectToChange = expediente.clientes.find(item => item.id_registro === cliente.id_registro);
        // await putClienteExpediente(cliente.id_registro, cliente.id_cliente, selectedOption.value, cliente.titulo_cliente);

        setExpediente((prev) => ({
            ...prev,
            clientes : prev.clientes.map((cliente) => {
                if(cliente.id_registro === objectToChange.id_registro) {
                    return {
                        ...objectToChange,
                        ['recibe_dinero'] : selectedOption.value,
                    };
                } else {
                    return cliente;
                }
            })
        }));

        setLoading(() => false);
    }

    const handleTituloChange = async (e, cliente) => {

        const objectToChange = expediente.clientes.find(item => item.id_registro === cliente.id_registro);
        const { name, value } = e.target;
        // await putClienteExpediente(cliente.id_registro, cliente.id_cliente, cliente.recibe, value)

        setExpediente((prev) => ({
            ...prev,
            clientes : prev.clientes.map((cliente) => {
                if(cliente.id_registro === objectToChange.id_registro) {
                    return {
                        ...objectToChange,
                        ['titulo_cliente'] : value,
                    };
                } else {
                    return cliente;
                }
            })
        }));
    }

    const DocumentModal = () => {
        return (
            <Modal handleClose={() => setDocument(undefined)}>
                { selectedDocumentRoute !== null && <iframe src={selectedDocumentRoute} width="100%"/>}
                <div>
                    { selectedDocumentRoute !== null &&
                    <a href={selectedDocumentRoute} download="doc" target='_blank'>
                        <Button type="button">Descargar {document.nombre_documento}</Button>
                    </a>}
                    <Button type="button" action={() => setDocument(undefined)}>Cerrar</Button>
                </div>
            </Modal>
        )
    };

    return(
        <>
        {
            errorMessage && <Modal handleClose={handleClose}>
                <ErrorMessage problem={errorMessage.problem} message={errorMessage.message}/>
            </Modal>
        }

        {
            loading && <div className={classes.loaderContainer}>
                <Loader />
            </div>
        }
        {document && <DocumentModal/>}
        <div className={classes.clientContainer}>
            <div className={classes.clientInnerContainer}>
                <h2>Clientes</h2>
                <div className={classes.flexClientsContainer}>
                    <div className={classes.innerFlexClientsContainer}> 
                        { expediente.clientes.length > 0 && expediente.clientes.map((cliente, index) => {
                            return (
                                <div key={cliente.cliente} className={classes.clienteContainer}>
                                    <button key={index} type='button' onClick={() => handleSelect(cliente)}
                                    className={`${classes.clientButton} ${selectedClient && cliente.cliente === selectedClient.cliente ? 'activeB' : undefined}`}>
                                        {cliente.cliente}
                                    </button>
                                    <Select 
                                    placeholder='Recibe dinero'
                                    name='selectClient'
                                    value={cliente.recibe_dinero ? { label: cliente.recibe_dinero, value: cliente.recibe_dinero } : null}
                                    onChange={(choice) => handleClienteRecibe(choice, cliente)} 
                                    options={optionsRecibeDinero}
                                    classNames={customClassNames}
                                    theme={customSelectTheme}
                                    />
                                    <Input name='tituloCliente' type='text' style={{margin:'0'}}
                                    placeholder='Título del cliente'
                                    value={cliente.titulo_cliente || ''}
                                    onChange={(input) => handleTituloChange(input, cliente)}/>
                                </div>
                            );
                        })}
                    </div>

                    <div className={classes.clientAddContainer}> 
                        <Select 
                        className={classes.selectDG} 
                        name='selectClient'
                        onChange={(choice) => handleSelectAdd(choice)} 
                        options={optionsClientes}
                        classNames={customClassNames}
                        theme={customSelectTheme}
                        placeholder='Agrega un cliente'
                        />
                        <Button type='button' action={handleDelete} style={{marginTop: '10px'}}>Eliminar</Button>
                    </div>
                </div>
            </div>


            {
                selectedClient && <>

                <div className={classes.clientInfoContainer}>
                <h2>Datos Generales</h2>
                { 
                    selectedClient &&
                    <div className={classes.flexContainer}>
                        <div>
                            <p>Nombre: <strong style={{color:'#114E28'}}>{selectedClient.cliente}</strong></p>

                            <p>Fecha de nacimiento: <strong style={{color:'#114E28'}}>{selectedClient.fecha_nacimiento_constitucion}</strong></p>
                            <p>Lugar de nacimiento: <strong style={{color:'#114E28'}}>{selectedClient.lugar_nacimiento}</strong></p>
                            <p>Nacionalidad: <strong style={{color:'#114E28'}}>{selectedClient.nacionalidad}</strong></p>
                        </div>

                        <div>
                            <p>Tipo persona: <strong style={{color:'#114E28'}}>{selectedClient.persona}</strong></p>

                            <p>RFC: <strong style={{color:'#114E28'}}>{selectedClient.rfc}</strong></p>
                            <p>CURP/CUD: <strong style={{color:'#114E28'}}>{selectedClient.curp_cud}</strong></p>
                            <p>Folio INE: <strong style={{color:'#114E28'}}>{selectedClient.folio_ine}</strong></p>
                        </div>

                        <div>
                            <p>Estado civil: <strong style={{color:'#114E28'}}>{selectedClient.estado_civil || 'No ingresado'}</strong></p>

                            <p>Edad: <strong style={{color:'#114E28'}}>{selectedClient.edad || 'No ingresado'}</strong></p>
                            <p>Ocupación: <strong style={{color:'#114E28'}}>{selectedClient.ocupacion || 'No ingresado'}</strong></p>
                            <p>Profesión: <strong style={{color:'#114E28'}}>{selectedClient.profesion || 'No ingresado'}</strong></p>
                        </div>

                        <div className={classes.direccion}>
                            <p>Calle: <strong style={{color:'#114E28'}}>{selectedClient.calle || 'No ingresado'}</strong></p>

                            <p>Número exterior: <strong style={{color:'#114E28'}}>{selectedClient.num_exterior || 'No ingresado'}</strong></p>
                            <p>Número interior: <strong style={{color:'#114E28'}}>{selectedClient.num_interior || 'No ingresado'}</strong></p>
                            <p>Colonia: <strong style={{color:'#114E28'}}>{selectedClient.colonia || 'No ingresado'}</strong></p>
                            <p>Código postal: <strong style={{color:'#114E28'}}>{selectedClient.codigo_postal || 'No ingresado'}</strong></p>
                        </div>
                    </div>
                }
            </div>

            <div className={classes.clientInfoContainer}>
                <h2>Documentos</h2>
                <ol id={selectedClient}>
                    {
                        selectedClient && selectedClient.docs.map((cliente, index) => {
                            return <li key={index} >
                                <DocumentCard description={cliente.nombre_documento} onClick={() => handleOpening(index)}/>
                            </li>
                        })
                    }
                    
                </ol>
            </div>
                
                </>
            }
        </div>
        </>
    );
}
