import { useEffect, useState } from "react";
import { customSelectTheme, customClassNames } from '../utils/selectCustomizations';

import {InputFile} from '../components/InputFile';

import mammoth from 'mammoth';
import Select from 'react-select';
import apiService from '../utils/apiService';
import Button from "../components/Button";

import Loader from "../components/Loader";
import CollapseMenu from "../components/CollapseMenu";

import classes from './Formatos.module.css';
import NoInfo from "../components/NoInfo";
import Input from "../components/Input";

const keywords = ['operacion', 'valor_operacion'];

const tagsData = {
    person : '',
    quantity: 0,
};

export default function Formatos(){
    //Download document
    const [download, setDownload] = useState(undefined);

    //Loading status
    const [loading, setLoading] = useState(false);

    //To map expedientes for react-select
    const [optionsExpediente, setOptionsExpediente] = useState([]);

    //To map clients of selected expediente for react-select
    const [optionsClientes, setOptionsClientes] = useState([]);

    //Get info of expedientes
    const [expedientes, setExpedientes] = useState([]);

    //Select client from a selected expediente by extracted text options
    const [selectedClient, setSelectedClient] = useState({});
    const [selectedClientIDs, setSelectedClientIDs] = useState({});

    //Select expediente itself
    const [selectedExpediente, setSelectedExpediente] = useState(undefined);

    //After file is handled
    const [fileExtracted, setFileExtracted] = useState(undefined);

    //Create the array of texts inside the file with special tags <FieldName>
    const [extractedTexts, setExtractedTexts] = useState([]);

    const [tags, setTags] = useState(tagsData);
    const [createTags, setCreateTags] = useState(false);

    const transformDatosGenerales = (data) => ({
        expediente: data.id_expediente,
        titulo_expediente: data.titulo_expediente,
        valor_operacion: data.valor_operacion,
        operacion: data.operacion,
    });
    
    const handleSelectExpediente = async (choice) => {
        setSelectedExpediente(() => {
            return expedientes.find(ex => ex.expediente === choice.value);
        });
        
        setLoading(() => true);
        getClientesAndDocs(choice.value);
    };

    const handleSelectClient = (choice, field) => {
        const obj = optionsClientes.find((cliente) => cliente.value === choice.value);

        setSelectedClient((prev) => ({
            ...prev,
            [field] : choice.value}
        ));
        setSelectedClientIDs((prev) => ({
            ...prev,
            [field] : obj.id
        }));
    };

    const transformExpediente = (dbData) => {
        return dbData.map(data => ({
            id: data.id_expediente,
            titulo_expediente: data.titulo_expediente,
        }));
    };

    const createOptionsExpediente = (expedientes) => {
        const optionsArray = [];
        expedientes && expedientes.forEach((expediente) => {
            console.log(expediente);
            const optionObject = {
                value: expediente.id,
                label: expediente.titulo_expediente
            }
            optionsArray.push(optionObject);
        });
        return optionsArray;
    };

    const mapClientOptions = async (clientes) => {
        const mappedData = clientes.map(cliente => {
            return {
                id: cliente.id_cliente,
                label: cliente.cliente,
                value: cliente.cliente,
            }
        })
        mappedData.sort(function (a, b) {
            if (a.label < b.label) {
                return -1;
            }
            if (a.label > b.label) {
                return 1;
            }
            return 0;
        })
        return mappedData;
    }

    const getClientesAndDocs = async (expedienteId) => {
        try {
            const response = await apiService.get(`/expedientes/${expedienteId}/clientes/`, {});
            const mappedData = await mapClientOptions(response.data.clientes);
            setOptionsClientes(() => mappedData);
            setLoading(() => false);
        } catch (error) {
            console.error('There was an error getting the info!', error);
        }
    }

    const getExpedientes = async () => {
        try {
            const response = await apiService.get('/expedientes/', {});
            console.log(response.data);
            setOptionsExpediente(createOptionsExpediente(transformExpediente(response.data)));
            setLoading(() => false);
            return response.data.map(transformDatosGenerales);
        } catch (error) {
            console.error('There was an error getting the info!', error);
        }
    };

    const postDocumentFormat = async (file, data) => {
        const formattedData = {
            "file": file,
            "data": JSON.stringify(data),
        }
        console.log(formattedData);

        try {
            const response = await apiService.post('/process-document/', formattedData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                responseType: 'blob',  // Expecting binary data (file)
            });

            if (response.status === 200) {
                console.log(response.data);
                setLoading(() => false);
                return response.data;
            }
        } catch (error) {
            console.error('Error posting emptyExpediente:', error);
        }
    }

    const mergeDataExpediente = async () => {
        setLoading(() => true);
        const datosGeneralesArray = await getExpedientes();

        setExpedientes(datosGeneralesArray);

        return;
    };

    useEffect(() => {

        mergeDataExpediente();

    }, []);

    useEffect(() => {
        const clientObject = {};
        extractedTexts.forEach((text) => {
            if(!keywords.includes(text)){
                clientObject[text] = undefined;
            }
        });
        setSelectedClient(() => clientObject);
        setSelectedClientIDs(() => clientObject);
    }, [extractedTexts, selectedExpediente]);

    const extractTags = (text) => {
        const texts = [];

        const regex = /<([^<>]+?)(?:\.[^<>]+)?>|<([^<>]+)>/g;

        let match;

        while ((match = regex.exec(text)) !== null) {
            texts.push(match[1]);
        }

        return texts;
      };

    const extractTexts = async (arrayBuffer) => {
        const result = await mammoth.extractRawText({arrayBuffer});
        return result.value;
    };

    const handleFile = async (file) => {

        const reader = new FileReader();
        reader.onload = async (e) => {
            const arrayBuffer = e.target.result;
            const text = await extractTexts(arrayBuffer);

            const texts = extractTags(text);
            
            setExtractedTexts([...new Set(texts)]);
            setFileExtracted(() => file);
            
        };
        reader.readAsArrayBuffer(file);
    };

    const handleSendingFormat = async () => {
        const data = {expediente : selectedExpediente.expediente, titulo: selectedExpediente.titulo_expediente, ...selectedClientIDs};

        setLoading(() => true);
        const response = await postDocumentFormat(fileExtracted, data);
        console.log(response);

        const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' }); // Adjust MIME type as needed
        const url = URL.createObjectURL(blob);
        setDownload(() => url);
        setLoading(false);

    };

    const copyOnClipboard = (text) => {
        navigator.clipboard.writeText(text);
    };

    const handleTagsChange = (e) => {
        let { name, value } = e.target;
        setCreateTags(() => undefined);

        if(name === 'quantity' && value > 10){
            value = 10;
        }

        setTags((prev) => ({
            ...prev,
            [name] : value
        }));
    };

    const tagsCreation = () => {
        if(tags.person !== '' && tags.quantity > 0){
            const array = Array.from({ length: tags.quantity }, (_, i) => `${tags.person}${i + 1}`);

            setCreateTags(() => array);
        }
    }

    return (
        <div className={classes.container}>
            <h1>
                Formatos
            </h1>
            <hr />

            <p style={{marginBottom: '40px'}}>
                Sube un archivo válido, enlaza los clientes con las etiquetas de los mismos y descarga el formato, también se agrega automáticamente al apéndice del expediente.
            </p>

            <div className={classes.childContainer}>
            <div className={classes.labelContainer}>
                <div className={classes.labelInnerContainer}>
                    <h3>
                        ¿Neceitas ayuda?
                    </h3>
                    <p>
                        Usa este generador de etiquetas para el formato, al dar click se copian y puedes pegarlos dentor del documento.
                    </p>
                </div>

                <Input label={"Título de la persona"} name='person' type='text'
                    value={tags.person}
                    onChange={handleTagsChange}
                    placeholder='Agregar'
                />

                <Input label={"Cantidad de personas"} name='quantity' type='number'
                    value={tags.quantity}
                    onChange={handleTagsChange}
                    placeholder='Agregar'
                />

                <Button action={tagsCreation}>Crear etiquetas</Button>

                {
                    createTags && <div className={classes.tagsContainer}>

                        {
                            createTags.map((tag) => <Button key={tag} action={() => copyOnClipboard(tag)}>{tag}</Button>)
                        }

                    </div>
                }
            </div>

            <div className={classes.innerContainer}>
            <InputFile handleFile={handleFile}/>

            {
                !fileExtracted && <NoInfo text={'Sube un archivo'}/>
            }

            {
                fileExtracted && 
                
                <div>
                    <p>
                        Selecciona un expediente
                    </p>

                    <Select 
                        className={classes.selectDG}
                        name='selectExpediente'
                        onChange={(choice) => handleSelectExpediente(choice)} 
                        options={optionsExpediente}
                        classNames={customClassNames}
                        theme={customSelectTheme}
                        />
                </div>
            }
            <div className={classes.infoContainer}>
            {
                selectedExpediente && extractedTexts.map((text) => {

                    // if(keywords.includes(text)){
                    //     return (
                    //         <div key={text}>
                    //             <p className={classes.text}>
                    //                 {
                    //                      `${text} : ${selectedExpediente[text] || 'No ingresado en el sistema'}`
                    //                 }
                    //             </p>

                    //         </div>
                    //     );
                    // };

                    if(!keywords.includes(text)){
                        return (
                            <div className={classes.clientContainer} key={text}>
                                <hr />
                                <p>Selecciona a <b>{text}</b></p>
                                <Select 
                                    className={classes.selectDG}
                                    value={optionsClientes.find((option) => option.value === selectedClient[text]) || ''}
    
                                    name={text}
                                    onChange={(choice) => handleSelectClient(choice, text)} 
                                    options={optionsClientes}
                                    classNames={customClassNames}
                                    theme={customSelectTheme}
                                />
                                {/* <CollapseMenu className={classes.collapseContainer}>
    
                                    <div className={classes.collapsedButtons}>
                                        <h3>¿El documento no es lo que deseas?</h3>
                                        <p>Puede ser un problema con el texto de las etiquetas dentro del documento, copia directamente de aquí para pegarlos en el documento</p>
                                        <p><b>Con solo dar click se copian...</b></p>
                                        <div className={classes.innerCollapsedButtons}>
                                            <Button action={() => copyOnClipboard(`<valor_operacion>`)}>{`<valor_operacion>`}</Button>
                                            <Button action={() => copyOnClipboard(`<operacion>`)}>{`<operacion>`}</Button>
                                            <Button action={() => copyOnClipboard(`<${text}.nombre>`)}>{`<${text}.nombre>`}</Button>
                                            <Button action={() => copyOnClipboard(`<${text}.datos>`)}>{`<${text}.datos>`}</Button>
                                        </div>
                                    </div>
    
                                </CollapseMenu> */}
                            </div>
                        ); 
                    } 
                    return undefined;
                }
            )}

            
            </div>

            {
                Object.values(selectedClient).every(el => el !== undefined) && selectedExpediente &&
                <div className={classes.sendButton}>
                    <hr/>
                    <Button className={'inverted'} action={handleSendingFormat}>Enviar formato</Button>
                </div>
            }

            {   
                download &&
                <div className={classes.downloadContainer}>
                    <p>Descarga el archivo aquí</p>
                    <a href={download} download={'formato'}>Descargar</a>
                </div>
            }
            </div>
            </div>

            {
                loading && <div className={classes.loaderContainer}>
                    <Loader />
                </div>
            }
        </div>
    );
};