import classes from './Error.module.css';
import dinosaur from '../img/icons/Chromium_T-Rex-white.svg';

export default function Error(){
    return (
        <div className={classes.container}>
            <div className={classes.errorContainer}>
                <h1>ERROR 404</h1>
                <h2>PÁGINA NO ENCONTRADA</h2>
                <p><a href='/'>REGRESAR INICIO DE SESIÓN</a></p>
                <img className={classes.dino} src={dinosaur} alt='Icon Dino'/>
            </div>
        </div>
    );
};