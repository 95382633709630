import classes from './Usuarios.module.css';
import apiService from '../utils/apiService';

import Button from './Button';
import { useEffect, useState } from 'react';
import { customSelectTheme, customClassNames } from '../utils/selectCustomizations';

import Select from 'react-select';
import {InputFile} from './InputFile';
import ErrorMessage from './ErrorMessage';
import Modal from './Modal';
import Input from './Input';
import Loader from './Loader';

const optionsPermisos = [
    {
        label: 'Home', value: 'Home',
    },
    {
        label: 'Expedientes', value: 'Expedientes'
    },
    {
        label: 'Actas', value: 'Actas'
    },
    {
        label: 'Clientes', value: 'Clientes'
    },
    {
        label: 'Índice', value: 'Indice'
    },
    {
        label: 'Presupuesto', value: 'Presupuesto'
    },
    {
        label: 'Formatos', value: 'Formatos'
    },
    {
        label: 'Configuracion', value: 'Configuracion'
    }
]

export default function Usuarios(){
    const [usersData, setUsersData] = useState([]);

    const [loading, setLoading] = useState(false);
    const [saveStatus, setSaveStatus] = useState('No guardado');

    const [errorMessage, setErrorMessage] = useState(undefined);
    const [confirmDelete, setConfirmDelete] = useState(undefined);

    const [addModal, setAddModal] = useState(false);

    const [addUserInfo, setAddUserInfo] = useState({
        user : '',
        password : ''
    });

    const [newId, setNewId] = useState(undefined);

    const transformUser = (data) => ({
        id: data.id,
        user: data.username,
        password: data.password,
        name: data.name,
        job: data.job,
        can_access_anywhere: data.can_access_anywhere,
        permisos: data.permissions,
        profilepicture: data.cover_photo
    });

    const getUsers = async () => {
        try {
            const response = await apiService.get('/users/', {});

            setUsersData(response.data.map((single) => transformUser(single)));
            setLoading(() => false);
        } catch (error) {
            const errorCode = error.code || 'error';
            const errorMessage = error.message || 'Notifique del error, vuelva a cargar la página o espere un momento';
            setErrorMessage({problem: 'Problemas al obtener los usuarios, código: '+errorCode, message: errorMessage});
            console.error('There was an error getting the info!', error);
        }
    };

    const createMultiIndexes = () => {
        const allIndexesArray = [];
        let optionsArray = [];
        
        if(usersData){
            usersData.forEach((item) => {
                if(item.permisos !== undefined){
                    item.permisos.forEach((actividad) => {
                        const actividadElement = optionsPermisos.find(key => key.value === actividad.permission);
        
                        optionsArray.push(actividadElement);
                    });
                }
                const optionsObject = {
                    id : item.id,
                    optionsArray
                }
                allIndexesArray.push(optionsObject);
                optionsArray = [];
            });
        }

        return allIndexesArray;
    }

    const [allIndexesData, setAllIndexesData] = useState([]);

    useEffect(() => {
        setAllIndexesData(createMultiIndexes);
    }, [usersData]);

    useEffect(() => {
        setLoading(() => true);
        getUsers();
    }, []);

    useEffect(() => {
        if(newId !== undefined){
            handleSelect(newId, undefined,{action: "select-option", option: {label: "Home", value: "Home"}, name: "a"})
        }
    }, [newId])

    const postPermission = async (permission) => {
        try {
            const response = await apiService.post('/user_permissions/', permission, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            // console.log(response) //  DEBUG
            if (response.status === 201) {
                setSaveStatus('Guardado permiso');
                return response.data;
            }
        } catch (error) {
            const errorCode = error.code || 'error';
            const errorMessage = error.message || 'Notifique del error, vuelva a cargar la página o espere un momento';
            setErrorMessage({problem: 'Problemas al añadir permiso, código: '+errorCode, message: errorMessage});
            console.error('Error posting user permission:', error);
        }
    };

    const deletePermission = async (id) => {
        try {
            const response = await apiService.delete(`/user_permissions/${id}/`, {}, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            // console.log(response) //  DEBUG
            if (response.status === 204) {
                setSaveStatus('Guardado permisos');
                console.log('Successfully deleted permission');
            }
        } catch (error) {
            const errorCode = error.code || 'error';
            const errorMessage = error.message || 'Notifique del error, vuelva a cargar la página o espere un momento';
            setErrorMessage({problem: 'Problemas al eliminar permiso, código: '+errorCode, message: errorMessage});
            console.error('Error deleting permissions:', error);
        }
    };

    const handleSelect = async (id, value, action) => {
        const objectToChange = usersData.find(item => item.id === id);

        setSaveStatus('Guardando...');
        
        if(action.action === 'select-option'){
            const permission = {
                "permission": action.option.value,
                "id": id
            }


            const receivedData = await postPermission(permission);


            if(receivedData !== undefined){
                objectToChange.permisos.push(receivedData);
            }

        }

        if(action.action === 'remove-value'){

            const finded = objectToChange.permisos.find((item) => item.permission === action.removedValue.value);

            setSaveStatus('Guardando permiso...');
            deletePermission(finded.id_permiso);

            const newPermissions = objectToChange.permisos.filter((item) => item.id_permiso !== finded.id_permiso);
            objectToChange.permisos = newPermissions;
        }

        setUsersData((prev) => (
            prev.map((item) => {
                if(item.id === id){
                    return {
                        ...objectToChange
                    };
                } else{
                    return item;
                }
            })
        ));

        setNewId(() => undefined);
    };

    const handleChange = (id, name, e) => {
        setSaveStatus('No guardado');

        let value = null;
        if (name === 'can_access_anywhere') {
            value = e.target.checked;
        } else {
            value = e.target.value;
        }
        const objectToChange = usersData.find(item => item.id === id);

        setUsersData((prev) => (
            prev.map((item) => {
                if(item.id === id){
                    return {
                        ...objectToChange,
                        [name]: value,
                    };
                } else{
                    return item;
                }
            })
        ));
    };

    const handleNewUser = (name, e) => {
        const value = e.target.value;

        setAddUserInfo((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleFile = (file, id) => {
        setSaveStatus('No guardado');
        const objectToChange = usersData.find(item => item.id === id);

        const  fileType = file['type'];
        const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
        if (!validImageTypes.includes(fileType)) {
            console.log(file['type']);
            //handle invalif file type modal here
            return;
        }

        setUsersData((prev) => (
            prev.map((item) => {
                if(item.id === id){
                    return {
                        ...objectToChange,
                        profilepicture : file,
                    };
                } else{
                    return item;
                }
            })
        ));
    };


    const handleSearch = () => {
        // Declare variables
        var input, filter, table, tr, td, ti, i, txtValue;
        input = document.getElementById("searchBar");
        filter = input.value.toUpperCase();
        table = document.getElementById("table");
        tr = table.getElementsByTagName("tr");
      
        // Loop through all table rows, and hide those who don't match the search query
        for (i = 0; i < tr.length; i++) {
          td = tr[i].getElementsByTagName("td")[0];
          if(td){
            ti = td.getElementsByTagName("input")[0];
            if (ti) {
              txtValue = ti.value;
              if (txtValue.toUpperCase().indexOf(filter) > -1) {
                tr[i].style.display = "";
              } else {
                tr[i].style.display = "none";
              }
            }
          }
        }
    }

    const postUser = async (data) => {
        const formattedData = {
            "username": data['user'],
            "password": data['password'],
        };

        try {
            const response = await apiService.post('/register/', formattedData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            // console.log(response) //  DEBUG
            if (response.status === 201) {
                return response.data;
            }
        } catch (error) {
            const errorCode = error.code || 'error';
            const errorMessage = error.message || 'Notifique del error, vuelva a cargar la página o espere un momento';
            setErrorMessage({problem: 'Problemas al añadir el usuario, código: '+errorCode, message: errorMessage});
            
            console.error('Error posting empty user:', error);
        }
    };

    const handleAdd = async (data) => {
        const newRow = {
            id: 0,
            user: '',
            password: '',
            name: '',
            job: '',
            permisos: [],
            profilepicture: ''
        };

        const newUser = await postUser(data);
        
        newRow.id = newUser.id;
        newRow.password = newUser.password;
        newRow.user = newUser.username;

        setUsersData((prev) => [
            ...prev,
            newRow
        ]);

        setNewId(() => newRow.id);
        
        setAddModal(false);
    };

    const deleteUser = async (id) => {
        try {
            const response = await apiService.delete(`/users/${id}/`, {}, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            // console.log(response) //  DEBUG
            if (response.status === 204) {
                console.log('Delete user successful');
                setSaveStatus('Guardado');
            }
        } catch (error) {
            const errorCode = error.code || 'error';
            const errorMessage = error.message || 'Notifique del error, vuelva a cargar la página o espere un momento';
            setErrorMessage({problem: 'Problemas al eliminar usuario, código: '+errorCode, message: errorMessage});
            
            console.error('Error deleting usuario:', error);
        }
    };

    const handleDelete = (id) => {
        deleteUser(id);
        setUsersData(l => l.filter(item => item.id !== id));
        handleCancel();
    };

    const putUser = async (data) => {
        let formattedData;
        if(data['profilepicture'] instanceof File){
            formattedData = {
                "id": data['id'],
                "username": data['user'],
                "name": data['name'],
                "job": data['job'],
                "can_access_anywhere": data['can_access_anywhere'],
                "cover_photo": data['profilepicture']
            };
        } else{
            formattedData = {
                "id": data['id'],
                "username": data['user'],
                "name": data['name'],
                "job": data['job'],
                "can_access_anywhere": data['can_access_anywhere'],
            };
        }

        if(data['password'] !== undefined && data['password'] !== ''){
            formattedData = {
                ...formattedData,
                "password": data['password'],
            }
        }

        try {
            const response = await apiService.put(`/users/${data['id']}/`, formattedData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            // console.log(response) //  DEBUG
            if (response.status === 200) {
                console.log('Put user successful');
                setSaveStatus('Guardado usuario');
            }
        } catch (error) {
            const errorCode = error.code || 'error';
            const errorMessage = error.message || 'Notifique del error, vuelva a cargar la página o espere un momento';
            setErrorMessage({problem: 'Problemas al actualizar los usuarios, código: '+errorCode, message: errorMessage});
            
            console.error('Error putting usuario:', error);
        }
    };

    const handleSave = async () => {
        setSaveStatus('Guardando usuario...');

        usersData.forEach((user) => {
            putUser(user);
        })
    };

    const findData = (id) => {
        const findedData = allIndexesData.find(i => i.id === id);
        
        if(findedData){
            return findedData.optionsArray;
        }
        return [];
    };

    const handleClose = () => {
        setErrorMessage(undefined);
    };

    const handleCancel = () => {
        setConfirmDelete(undefined);
    };

    const handleConfirmDelete = (item) => {
        setConfirmDelete(item);
    };

    const handleAddModal = () => {
        setAddModal(true);
    };

    const handleCloseAdd = () => {
        setAddModal(false);
    };

    return(
        <>
            {
                errorMessage && <Modal handleClose={handleClose}>
                    <ErrorMessage problem={errorMessage.problem} message={errorMessage.message}/>
                </Modal>
            }

            {
                addModal && <Modal handleClose={handleCloseAdd}>
                    <div className={classes.addModal}>
                        <h2>Agrega un usuario y contraseña</h2>
                        <Input  label={'Usuario'} value={addUserInfo.user} onChange={(e) => handleNewUser('user', e)}/>

                        <Input type='password'  label={'Constraseña'} value={addUserInfo.password} onChange={(e) => handleNewUser('password', e)}/>

                        <div>
                            <Button action={() => handleAdd(addUserInfo)} className={'inverted'}>Agregar</Button>
                            <Button action={handleCloseAdd}>Cancelar</Button>
                        </div>
                    </div>
                </Modal>
            }

            {
                confirmDelete && <Modal handleClose={handleCancel}>
                    <div className={classes.deleteModal}>
                        <h1>
                            Eliminando...
                        </h1>

                        <p>
                            ¿Estas seguro de eliminar el usuario {confirmDelete.user}?
                        </p>

                        <div className={classes.deleteModalButtons}>
                            <Button action={() => handleDelete(confirmDelete.id)}>
                                Eliminar
                            </Button>
                            <Button action={handleCancel} className={'inverted'}>
                                Cancelar
                            </Button>
                        </div>
                    </div>
                </Modal>
            }
            

            {
                loading && <Loader />
            }

            {
                !loading && <>
                    <div className={classes.headerContainer}>
                <input className={classes.input} type="text" id="searchBar" onKeyUp={handleSearch} placeholder="Buscar usuarios..."/>

                <div className={classes.buttonContainer}>

                    <p>{saveStatus}</p>
                    
                    <Button type='button' className={'inverted'} action={handleAddModal}>Agregar</Button>
                    <Button type='button' action={handleSave}>Guardar</Button>

                </div>
            </div>
            <div className={classes.tableContainer}>
                <table className={classes.table} id='table'>
                    <thead>
                      <tr>
                        <th scope="col">Usuario</th>
                        <th scope="col">Contraseña</th>
                        <th scope="col">Nombre</th>
                        <th scope="col">Puesto</th>
                        <th scope="col">Permisos</th>
                        <th scope="col">Acceso</th>
                        <th scope="col">Foto de perfil</th>
                        <th scope="col">Acción</th>
                      </tr>
                    </thead>
                <tbody>
                  {
                    usersData.map((data, index) => (
                        <tr key={index}>
                            <td><input name='user' value={data.user || ''}  onChange={(e) => handleChange(data.id, 'user', e)}/></td>
    
                            <td><input placeholder='Nueva contraseña' name='password' type='password' value={data.password || ''}  onChange={(e) => handleChange(data.id, 'password', e)}/></td>
    
                            <td><input name='name' value={data.name || ''}  onChange={(e) => handleChange(data.id, 'name', e)}/></td>
    
                            <td><input name='job' value={data.job || ''}  onChange={(e) => handleChange(data.id, 'job', e)}/></td>
    
                            <td>
                                <Select 
                                isMulti
                                name={'a'+data.id}  
                                onChange={(choice, action) => handleSelect(data.id, choice, action)}
                                options={optionsPermisos}
                                value={findData(data.id)}
                                isClearable={false}
                                classNames={customClassNames}
                                theme={customSelectTheme}
                                />
                            </td>

                            <td>
                                <input
                                type="checkbox"
                                name='can_access_anywhere'
                                checked={data.can_access_anywhere || false}
                                onChange={(e) => handleChange(data.id, 'can_access_anywhere', e)}
                                />
                            </td>
    
                            <td>
                                <InputFile 
                                    fileName={'Add photo'} 
                                    handleFile={handleFile} 
                                    id={data.id}
                                />
                            </td>
    
                            <td><Button action={() => handleConfirmDelete(data)} type='button'>Eliminar</Button></td>
                        </tr>
                    ))
                  }
                    </tbody>
                </table>
                </div>
                </>
            }
        </>
    );
}