import { Outlet, useLocation } from "react-router-dom";
import MainNavigation from "../components/MainNavigation.js";


function RootLayout(){
    const location = useLocation();

    const navBar = location.pathname === '/' ? false : true;

    return (
        <>
            {navBar && <MainNavigation/>}
            <main>
                <Outlet />
            </main>
        </>
    );
}

export default RootLayout;