import ClientesTable from '../components/ClientesTable';
import classes from './Clientes.module.css';

export default function Clientes(){
    return (
        <div className={classes.container}>
            <h1>Clientes</h1>
            <hr />
            <p>
                Agrega, edita y elimina clientes, también agrega archivos al perfil del cliente.
            </p>
            <ClientesTable />
            
        </div>
    );
};