import apiService from '../utils/apiService';
import { useEffect, useState } from 'react';
import { customSelectTheme, customClassNames } from '../utils/selectCustomizations';
import Button from './Button';
import classes from './Tramites.module.css';

import Select from 'react-select';
import NoInfo from './NoInfo';


import Modal from './Modal';
import ErrorMessage from './ErrorMessage';
import Loader from './Loader';

const optionsEstatusTramite = [
    { label: 'Pendiente', value: 'Pendiente' },
    { label: 'Pagado', value: 'Pagado' }
]


export default function Tramites({expediente, setExpediente, setSaveStatus}){
    const [optionsClientes, setOptionsClientes] = useState([]);
    const [userName, setUserName] = useState(undefined);


    //Connection handler
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        setErrorMessage(undefined);
    };

    useEffect(() => {
        setUserName(() => JSON.parse(localStorage.getItem('user_info')));
    }, []);

    useEffect(() => {
        const optionsArray = [];
        if(expediente.clientes.length > 0){
            expediente.clientes.forEach((tramite) => {
                const optionsObject = {
                    label : tramite.cliente,
                    value: tramite.cliente
                };
                optionsArray.push(optionsObject);
            });
        }
        setOptionsClientes(optionsArray);
    }, [expediente]);

    const postEmptyTramite = async (idExpediente) => {
        let formattedData = { "id_expediente": idExpediente }
        try {
            const response = await apiService.post('/tramites/', formattedData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            if (response.status === 201) {
                return response.data['id_tramite']
            }
        } catch (error) {
            const errorCode = error.code || 'error';
            const errorMessage = error.message + '. Notifique del error, vuelva a cargar la página o espere un momento.';
            setErrorMessage({problem: 'Problemas al agregar el trámite, código: '+errorCode, message: errorMessage});
        }
    }

    const deleteTramite = async (idTramite) => {
        try {
            const response = await apiService.delete(`/tramites/${idTramite}/`);
            if (response.status === 204) {

            }
        } catch (error) {

            const errorCode = error.code || 'error';
            const errorMessage = error.message + '. Notifique del error, vuelva a cargar la página o espere un momento.';
            setErrorMessage({problem: 'Problemas al eliminar el trámite, código: '+errorCode, message: errorMessage});
        }
    }

    const handleSelect = (id, name, value) => {
        setSaveStatus(() => 'No guardado');

        const objectToChange = expediente.tramites.find(item => item.idTramite === id);
        setExpediente((prev) => ({
            ...prev,
            tramites : prev.tramites.map((tramite) => {
                if(tramite.idTramite === objectToChange.idTramite){
                    return {
                        ...objectToChange,
                        [name] : value.value,
                    };
                } else{
                    return tramite;
                }
            })
        }));
    }

    const handleAdd = async () => {

        setLoading(() => true);
        const dateObj = new Date();
        const month   = dateObj.getUTCMonth() + 1; // months from 1-12
        const day     = dateObj.getUTCDate();
        const year    = dateObj.getUTCFullYear();
        // Using padded values, so that 2023/1/7 becomes 2023/01/07
        const pMonth        = month.toString().padStart(2,"0");
        const pDay          = day.toString().padStart(2,"0");
        const newPaddedDate = `${year}-${pMonth}-${pDay}`;

        const idEmptyTramite = await postEmptyTramite(expediente.id)

        const newRow = {
            idTramite: idEmptyTramite,
            concepto: '',
            recibiDe: '',
            usuario: userName.name || '',
            estatusRecibo: '',
            fechaRecibo: newPaddedDate,
            monto: 0.00,
            formaDePago: ''
        };

        setExpediente((prev) => ({
            ...prev,
            tramites: [
                ...prev.tramites,
                newRow
            ]
        }));

        setLoading(() => false);
    };

    const handleDelete = async (id) => {
        setLoading(() => true);
        await deleteTramite(id)
        setExpediente((prev) => ({
            ...prev,
            tramites : prev.tramites.filter(
                (tramite) => tramite.idTramite !== id
            )
        }));
        setLoading(() => false);
    };

    const handleChange = (id, name, e) => {
        setSaveStatus(() => 'No guardado');

        const value = e.target.value;
        const objectToChange = expediente.tramites.find(item => item.idTramite === id);

        setExpediente((prev) => ({
            ...prev,
            tramites : prev.tramites.map((tramite) => {
                if(tramite.idTramite === id){
                    return {
                        ...objectToChange,
                        [name] : value,
                    };
                } else{
                    return tramite;
                }
            })
        }));
    };

    return(
        <div className={classes.container}>
            {
            errorMessage && <Modal handleClose={handleClose}>
                <ErrorMessage problem={errorMessage.problem} message={errorMessage.message}/>
            </Modal>
            }

            {
                loading && <div className={classes.loaderContainer}>
                    <Loader />
                </div>
            }
            <div className={classes.buttonsContainer}>
                <Button type='button' action={handleAdd}>Agregar</Button>
            </div>
        {
            expediente.tramites.length > 0 &&<div className={classes.tableContainer}>
            <table className={classes.table}>
                <thead>
                    <tr>
                        <th scope="col">Número de recibo</th>
                        <th scope="col">Concepto</th>
                        <th scope="col">Recibí de</th>
                        <th scope="col">Usuario</th>
                        <th scope="col">Estatus recibo</th>
                        <th scope="col">Fecha recibo</th>
                        <th scope="col">Monto</th>
                        <th scope="col">Forma de pago</th>
                        <th scope="col">Acción</th>
                    </tr>
                </thead>
                <tbody>
                    { expediente.tramites.map((data, index) => (
                        <tr key={index}>
                            <td><input value={data.idTramite}  readOnly name='id'/></td>
                            <td><input name='concepto' value={data.concepto || ''}  onChange={(e) => handleChange(data.idTramite, 'concepto', e)}/></td>
                            <td>
                                <Select
                                name={'r'+data.idTramite}
                                onChange={(choice) => handleSelect(data.idTramite, 'recibiDe', choice)}
                                options={optionsClientes}
                                value={{label: data.recibiDe || '', value: data.recibiDe || ''}}
                                classNames={customClassNames}
                                theme={customSelectTheme}
                                />
                            </td>
                            <td><input name='user' value={data.usuario || ''}  onChange={(e) => handleChange(data.idTramite, 'usuario', e)} readOnly/></td>
                            <td>
                                <Select
                                name={'s'+data.idTramite}
                                onChange={(choice) => handleSelect(data.idTramite, 'estatusRecibo', choice)}
                                options={optionsEstatusTramite}
                                value={optionsEstatusTramite.find((option) => option.value === data.estatusRecibo) || ''}
                                classNames={customClassNames}
                                theme={customSelectTheme}
                                />
                            </td>
                            <td><input name='date' type='date' value={data.fechaRecibo || ''}  onChange={(e) => handleChange(data.idTramite, 'fechaRecibo', e)}/></td>
                            <td><input name='monto'  type="number" step="0.01" min="0"
                            value={parseFloat(data.monto) || 0}
                            // value={`$${parseFloat(data.monto)}` || 0}
                            onChange={(e) => handleChange(data.idTramite, 'monto', e)}/></td>
                            <td><input name='formaDePago' value={data.formaDePago || ''}  onChange={(e) => handleChange(data.idTramite, 'formaDePago', e)}/></td>
                            <td><Button action={() => handleDelete(data.idTramite)} type='button'>Eliminar</Button></td>
                        </tr>
                    ))
                    }
                </tbody>
            </table>
            </div>
        }
        {
            expediente.tramites.length <= 0 && <NoInfo text={'Agrega un trámite'}/>
        }
        </div>
    );
}
