import { act, useEffect, useState } from 'react';
import { customSelectTheme, customClassNames } from '../utils/selectCustomizations';
import Loader from '../components/Loader';
import NoInfo from '../components/NoInfo';
import classes from './Actas.module.css';

import apiService from '../utils/apiService';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Modal from '../components/Modal';
import ErrorMessage from '../components/ErrorMessage';
import Button from '../components/Button';
import Input from '../components/Input';
import Select from 'react-select';
import DocumentoActa from '../components/DocumentoActa';
import ClientesActa from '../components/ClientesActa';
import ApendiceActa from '../components/ApendiceActa';
import FormatoActa from '../components/FormatoActa';

export default function Actas(){
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [tituloActa, setTituloActa] = useState('');

    const [options, setOptions] = useState([]);
    const [acta, setActa] = useState({});

    const [confirmDelete, setConfirmDelete] = useState(undefined);

    //For error handling
    const [errorMessage, setErrorMessage] = useState(undefined);

    const handleCancel = () => {
        setConfirmDelete(undefined);
    };

    const handleConfirmDelete = (item) => {
        setConfirmDelete(item);
    };

    useEffect(() => {
        setLoading(() => true);
        handleEffectGetActas();
    }, []);

    //Creates options for the react-select
    const handleEffectGetActas = async () => {
        const actas = await getActas();

        console.warn(actas);
        
        if(actas.length <= 0){
            setLoading(() => false);
            return;
        }

        const options = createOptions(actas);
        setOptions(options);

        const selectedLast = actas[actas.length - 1];

        console.warn(selectedLast);
        const selectedActa = await getActa(selectedLast.id_acta);

        console.log(selectedActa);
        setActa(() => selectedActa);
        setLoading(() => false);
    };

    //Gets single Acta
    const getActa = async (id) => {
        try {
            const response = await apiService.get(`/actas/${id}/info/`, {});
            console.log('GET ACTA INFO', response.data, 'id', id)
            return response.data;
        } catch (error) {
            console.error(error);
            const errorCode = error.code || 'error';
            const errorMessage = error.message + '. Notifique del error, vuelva a cargar la página o espere un momento.';
            setErrorMessage({problem: 'Problemas al obtener, código: '+errorCode, message: errorMessage});
        }
    };

    //Gets all Actas id's and title
    const getActas = async () => {
        try {
            const response = await apiService.get('/actas/', {});
            return response.data;
        } catch (error) {
            const errorCode = error.code || 'error';
            const errorMessage = error.message + '. Notifique del error, vuelva a cargar la página o espere un momento.';
            setErrorMessage({problem: 'Problemas al obtener los clientes, código: '+errorCode, message: errorMessage});
        }
    };

    //Creates options of the actas
    const createOptions = (actas) => {
        const optionsArray = [];
        actas && actas.forEach((item) => {
            const optionObject = {
                value: item.id_acta,
                label: item.titulo_acta,
            }
            optionsArray.push(optionObject);
        });
        return optionsArray;
    };

    const handleClose = () => {
        setErrorMessage(undefined);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
    };

    //Sends HTTP request for deletion
    const deleteActa = async (id) => {
        try {
            const response = await apiService.delete(`/actas/${id}/`, {});
            if (response.status === 204) {
                console.log('ClienteExpediente deleted successfully');
            }
        } catch (error) {
            const errorCode = error.code || 'error';
            const errorMessage = error.message + '. Notifique del error, vuelva a cargar la página o espere un momento.';
            setErrorMessage({problem: 'Problemas al obtener los clientes, código: '+errorCode, message: errorMessage});
        }
    };

    //Handles delete on click
    const handleDelete = async  () => {
        setLoading(() => true);
        await deleteActa(acta.idActa);
        setOptions((prev) => prev.filter((a) => a.value !== acta.idActa));

        if(options.length <= 0) {
            return;
        }
        setActa(() => undefined);
        setConfirmDelete(undefined);
        setLoading(() => false);
    };

    //Handles change of acta when clicking in select
    const handleChange = async (value) => {
        if(acta && acta.idActa === value){
            return;
        }

        setLoading(() => true);
        
        const selectedItem = await getActa(value);
        console.log(selectedItem);
        setActa(() => selectedItem);
        setLoading(() => false);
    };

    //Posts and empty acta object
    const postEmptyActa = async (titulo) => {
        try {
            const response = await apiService.post('/actas/', { "titulo_acta" : titulo }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.status === 201) {
                return response.data
            }
        } catch (error) {
            const errorCode = error.code || 'error';
            const errorMessage = error.message + '. Notifique del error, vuelva a cargar la página o espere un momento.';
            console.error('Error', error);
            setErrorMessage({problem: 'Problemas al agregar un expediente, código: '+errorCode, message: errorMessage});
        }
    }

    //Posts an empty doc for the acta
    const postEmptyActaDoc = async (idActa) => {
        let formattedData = {
            "nombre_doc": 'Acta',
            "ultima_modificacion": null,
            "ruta_archivo": null,
            "id_acta": idActa,
        }
        try {
            const response = await apiService.post('/docs_acta/', formattedData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.status === 201) {
                return response.data
            }
        } catch (error) {
            console.error('Error posting empty factura:', error);
            const errorCode = error.code || 'error';
            const errorMessage = error.message + '. Notifique del error, vuelva a cargar la página o espere un momento.';
            console.error('Error', error);
            setErrorMessage({problem: 'Problemas al agregar factura, código: '+errorCode, message: errorMessage});
        }
    }

    //Handles the adding of a new acta
    const handleAdd = async () => {
        setLoading(() => true);
        setIsModalOpen(() => false);
        const dataPost = await postEmptyActa(tituloActa);
        console.warn(dataPost);
        const emptyActaPost = await postEmptyActaDoc(dataPost.id_acta);
        console.log('DEBUG EMPTY ACTA DOC', emptyActaPost)

        setOptions((prev) => [
            ...prev, {
                value: dataPost.id_acta,
                label: dataPost.titulo_acta,
            }
        ]);
        setTituloActa(() => '');

        const selectedItem = await getActa(dataPost.id_acta);
        setActa(() => selectedItem);
        setLoading(() => false);
    };

    const handleInputChange = (event) => {
        setTituloActa(event.target.value);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setTituloActa('');
    };

    return (
        <>
            {
                errorMessage && <Modal handleClose={handleClose}>
                    <ErrorMessage problem={errorMessage.problem} message={errorMessage.message}/>
                </Modal>
            }

            {
                confirmDelete && <Modal handleClose={handleCancel}>
                    <div className={classes.deleteModal}>
                        <h1>
                            Eliminando...
                        </h1>

                        <p>
                            ¿Estas seguro de eliminar la acta {confirmDelete.tituloActa}?
                        </p>

                        <div className={classes.deleteModalButtons}>
                            <Button action={handleDelete}>
                                Eliminar
                            </Button>
                            <Button action={handleCancel} className={'inverted'}>
                                Cancelar
                            </Button>
                        </div>
                    </div>
                </Modal>
            }

            <div className={classes.container}>
                <h1>Actas fuera de protocolo</h1>
                <hr />
                <p>
                    Agrega, elimina y añade archivos a actas fuera de protocolo.
                </p>
                { !loading &&
                <div className={classes.buttonContainer}>
                    <div>
                        <div className={classes.selectContainer}>
                            <Select 
                                placeholder='Selecciona expediente'
                                options={options}
                                onChange={(selected) => handleChange(selected.value)}
                                isSearchable={true}
                                value={options.find((option) => option.value === (acta?.idActa || '')) || ''}
                                classNames={customClassNames}
                                theme={customSelectTheme}
                            />
                        </div>
                    </div>
                    <div className={classes.buttonsLoader}>
                        {/* Change this later to type='submit' */}
                        <Button type='button' className={'button'} action={() => setIsModalOpen(true)}>Nuevo</Button>
                        <Button type='button' action={() => handleConfirmDelete(acta)} disabled={acta === undefined}>Eliminar</Button>
                    </div>
                </div>
                }

                { isModalOpen &&
                <form onSubmit={handleSubmit}>
                    <Modal handleClose={closeModal}>
                        <h2 className={classes.title}>Añadir</h2>
                        <div className={classes.addingContainer}>
                            <p> Título </p>
                            {/* <input className={classes.input} id='tituloExpediente' value={tituloExpediente} onChange={handleInputChange} required /> */}
                            <Input placeholder='Ejemplo: Acta01' className={classes.input} id='tituloExpediente' onChange={handleInputChange} required />
                        </div>
                        <div>
                            <Button type="button" action={closeModal}>Cancelar</Button>
                            <Button className='inverted' type="submit" action={handleAdd}>Añadir</Button>
                        </div>
                    </Modal>
                </form>
                }

                { (JSON.stringify(acta) !== '{}' && acta !== undefined) && !loading && <Tabs>
                        <TabList>
                            <Tab><h3>Acta</h3></Tab>
                            <Tab><h3>Clientes</h3></Tab>
                            <Tab><h3>Apéndice</h3></Tab>
                            <Tab><h3>Formato dirección notarias</h3></Tab>
                        </TabList>

                        <TabPanel>
                            <DocumentoActa acta={acta} setActa={setActa}/>
                        </TabPanel>

                        <TabPanel>
                            <ClientesActa acta={acta} setActa={setActa}/>
                        </TabPanel>

                        <TabPanel>
                            <ApendiceActa acta={acta} setActa={setActa}/>
                        </TabPanel>

                        <TabPanel>
                            <FormatoActa acta={acta}/>
                        </TabPanel>
                        
                    </Tabs>
                }
                { options.length > 0 && acta === undefined && !loading && <NoInfo text={'Selecciona una Acta'}/> }
                { options.length <= 0 && !loading && <NoInfo text={'Agrega una Acta'}/> }
                { loading && <Loader /> }
            </div>
        </>
    );
};