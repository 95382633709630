import { useNavigate } from "react-router-dom";

import logo from '../img/logo.jpeg';

import classes from './Authform.module.css';
import Button from "./Button";
import { useState } from "react";
import axios from 'axios';
import apiService from '../utils/apiService';
import Loader from "./Loader";

export default function Authform(){
    const allowedIPs = [
        '187.156.45.249', // IP 27/08/2024
        '187.156.225.96', // IP 4/09/2024
    ] 

    const [input, setInput] = useState({
        username: "",
        password: "",
    });
    const [loading, setLoading] = useState(false);

    const [errorMessage, setErrorMessage] = useState(undefined);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInput((prev) => ({
          ...prev,
          [name]: value,
        }));
    };

    const getUser = async () => {
        try {
            const response = await apiService.get(`/users/${localStorage.getItem("user_id")}/`, {});
            // setExpedientes(transformExpediente(response.data));

            console.log(response.data);
            return response.data;
        } catch (error) {
            console.error('There was an error getting the info!', error);
        }
    };
    

    const navigate = useNavigate();

    const  signIn = async () => {
        if (input.username !== "" && input.password !== "") {
            setLoading(() => true);
            const tokens = await logIn();

            if(tokens) {
                localStorage.setItem("access_token", tokens.access);
                localStorage.setItem("refresh_token", tokens.refresh);
                localStorage.setItem("user_id", tokens.id);

                const userInfo = await getUser();
                
                localStorage.setItem("user_info", JSON.stringify(userInfo));
                navigate("/home");
            }
            setLoading(() => false);
        }
        
        else{
            alert('Please enter an username and password');
        }
    };

    const logIn = async () => {
        try {
            const response = await axios.post('https://api.sysnot-notaria137.com/api/login/', {"username" : input.username, "password" : input.password}, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            // console.log(response) //  DEBUG
            if (response.status === 200) {
                if (!response.data.can_access_anywhere) {
                    try {
                        const ipResponse = await fetch('https://api.ipify.org?format=json');
                        const ipData = await ipResponse.json();
                        const clientIP = ipData.ip;
                        // console.log('CLIENT IP', clientIP);
                        if (!allowedIPs.includes(clientIP)) {
                            console.log('USER IP NOT ALLOWED');
                            setErrorMessage({ problem: 'Problemas al iniciar sesión', message: 'Acceso denegado' });
                            return null;
                        } else {
                            console.log('USER IP ALLOWED');
                            return response.data;
                        }
                    } catch (error) {
                        console.error('Error fetching IP:', error);
                        setErrorMessage({ problem: 'Problemas al iniciar sesión', message: 'Error al verificar la IP' });
                        return null;
                    }
                } else {
                    console.log('USER CAN ACCESS FROM ANYWHERE')
                    return response.data;
                }
            }
        } catch (error) {
            const errorMessage = error.response.data.error;
            setErrorMessage({problem: 'Problemas al iniciar sesión', message: errorMessage});
            
            console.error('Error posting logging in:', error);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
    };

    return(
        <div className={classes.container}>
                <section className={classes.info}>
                        <img src={logo} alt="Notaria 137 Logo"/>
                        <form onSubmit={handleSubmit}>
                            <label>Usuario</label>
                            <input id="username" name="username" value={input.username} onChange={handleChange} placeholder="Usuario" type="text" autoComplete="on"/>
                            <label>Contraseña</label>
                            <input id="password" name="password" value={input.password} onChange={handleChange} placeholder="Contraseña" type="password"/>
                            {
                                errorMessage && <p className={classes.errorMessage}> {errorMessage.problem + ': ' + errorMessage.message} </p>
                            }
                        <Button action={signIn} type='submit'>Iniciar sesión</Button>
                        </form>
                </section>
                {
                loading && <div className={classes.loaderContainer}>
                    <Loader />
                </div>
            }
        </div>
    );
}
