import { useEffect, useState } from 'react';
import classes from './IndiceTable.module.css';
import Modal from './Modal';
import Button from './Button';
import Select from 'react-select'

import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

import { parse, isAfter, isBefore, addMonths, isValid } from 'date-fns';
import Input from './Input';

export default function IndiceTable({data, uma, meses_antilavado}){
    const [openModal, setOpenModal] = useState(undefined);

    //Pagination STARTS
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [optionsPaginas, setOptionsPaginas] = useState([]);

    const [expedientes, setExpedientes] = useState([]);

    const [antilavado, setAntilavado] = useState('$1,000,000');

    useEffect(() => {
        const sortDataDate = () => {
            const sortedData = data.sort((a,b) => {
                return new Date(b.fecha) - new Date(a.fecha);
            });

            setExpedientes(() => sortedData);
        };

        sortDataDate();
    }, [data]);

    useEffect(() => {
        const generatePaginationOptions = () => {
            const length = data.length;
            const options = [];
            for (let i = 5; i <= length; i += 5) {
                const pag = {label: i, value: i}
                options.push(pag);
            }
            if (length % 5 !== 0) {
                const pag = {label: length, value: length}
                options.push(pag); // Include the exact length if it is not a multiple of 5
            }
            setOptionsPaginas(options);
        };

        generatePaginationOptions();
    }, [data]);

    const handleSelect = (valueDef) => {
        const value = valueDef.value;

        setItemsPerPage(() => value);
    };

    const totalPages = Math.ceil(expedientes.length / itemsPerPage);

    const handleClick = (page) => {
        setCurrentPage(page);
    };

    const paginatedData = expedientes.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    //Pagination ENDS

    const handleOpening = (incisoVulnerable) => {
        setOpenModal(incisosData.find(expedientes => expedientes.incisoVulnerable === incisoVulnerable));
    };

    const handleClose = () => {
        setOpenModal(undefined);
    };

    const formatValue = (inputValue) => {
        // Remove any non-numeric characters except for periods
        let numericValue = inputValue.replace(/[^0-9.]/g, '');

        // Split the value into integer and decimal parts
        let [integer, decimal] = numericValue.split('.');

        // Ensure the integer part is not empty
        if (!integer) {
          integer = '0';
        }

        // Truncate decimal part to two digits
        if (decimal) {
          decimal = decimal.slice(0, 2);
        }

        integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        // Join the integer and decimal parts
        numericValue = decimal !== undefined ? `${integer}.${decimal}` : integer;

        return numericValue;
      };

    const avisoCalculations = (monto, inciso) => {
        //Se calcula con el valor -> DIARIO DE UMA <- A día de hoy es $108.57MN
        /*
         - Inciso A -> UMA = 16,000
         - Inciso B -> UMA = Siempre
         - Inciso C -> UMA = 8,025
         - Inciso D -> UMA = 8,025
         - Inciso E -> UMA = Siempre
        */

        if(inciso === 'B' || inciso === 'E'){
            return true;
        }
        if(inciso === 'A' && monto > 16000 * uma){
            return true;
        }
        if((inciso === 'C' || inciso === 'D') && monto > 8025 * uma){
            return true;
        }

        return false;
    };

    const checkIfClientReceivesMore = (clientName, endDate, months, expediente) => {
        if (!endDate) return false; // If endDate is null, return false
    
        const endDateObj = parse(endDate, 'yyyy-MM-dd', new Date());
        if (!isValid(endDateObj)) return false; // Check if endDateObj is a valid date
    
        const startDateObj = addMonths(endDateObj, -months);
        let totalMonto = parseFloat(expediente.monto);
    
        for(let i = 0; i < expedientes.length; i++) {

            const item = expedientes[i];
            if (!item.fecha) continue; // Skip if item.fecha is null
    
          const itemDate = parse(item.fecha, 'yyyy-MM-dd', new Date());
          if (isValid(itemDate) && isAfter(itemDate, startDateObj) && isBefore(itemDate, endDateObj) && expediente.expediente !== itemDate.expediente) {
            for(let j = 0; j < item.clientes.length; j++) {
                const client = item.clientes[j];
                if (client.nombre === clientName && client.recibe_dinero) {
                  totalMonto += parseFloat(item.monto);
                }
            }
          }
        }
        console.warn( parseFloat(antilavado.substring(1).replaceAll(",", "")));
        return totalMonto >= parseFloat(antilavado.substring(1).replaceAll(",", ""));
      };

      const incisosData = [
        {
            incisoVulnerable : 'A',
            texto : 'A) La transmisión o constitución de derechos reales sobre inmuebles, salvo las garantías que se constituyan en favor de instituciones del sistema financiero u organismos públicos de vivienda. Excedió la cantidad de ',
            quantity : `$${formatValue((16000 * uma)+'')}`
        },
        {
            incisoVulnerable : 'B',
            texto : 'B) El otorgamiento de poderes para actos de administración o dominio otorgados con carácter irrevocable. ',
            quantity : 'SIEMPRE'
        },
        {
            incisoVulnerable : 'C',
            texto : 'C) Constitución de personas morales, su modificación patrimonial derivada de aumento o disminución de capital social, fusión o escisión, así como la compra-venta de acciones y partes sociales. Excedió la cantidad de ',
            quantity: `$${formatValue((8025 * uma)+'')}`
        },
        {
            incisoVulnerable : 'D',
            texto : 'D) La constitución o modificación de fideicomisos traslativos de dominio o de garantía sobre inmuebles, salvo los que constituyan para garantizar algún crédito a favor de instituciones del sistema financiero u organismos públicos de vivienda. Excedió la cantidad de ',
            quantity: `$${formatValue((8025 * uma)+'')}`
        },
        {
            incisoVulnerable : 'E',
            texto : 'E) El otorgamiento de contratos de mutuo o crédito, con o sin garantía, en lo que el acreedor no forme parte del sistema financiero o no sea un organismo público de vivienda. ',
            quantity : 'SIEMPRE'
        },
    ];

    const exportToExcel = () => {
        const exportData = expedientes.map((expediente) => {
          const clientes = expediente.clientes
            .map((cliente) => `${cliente.titulo_cliente || 'Sin título'} ${cliente.nombre}`)
            .join('\n');
          const incisoData = incisosData.find(
            (inciso) => inciso.incisoVulnerable === expediente.inciso_vulnerable
          );
          const avisoSAT = avisoCalculations(expediente.monto, expediente.inciso_vulnerable);
    
          return {
            Expediente: expediente.expediente,
            Folio: expediente.folio || 'No agregado',
            Fecha: expediente.fecha || 'No agregado',
            'Acto Jurídico': expediente.operacion || 'No agregado',
            'Inciso Vulnerable': avisoSAT ? `${incisoData.texto} ${incisoData.quantity}` : '',
            Clientes: clientes,
            Monto: `$${formatValue(expediente.monto)}`,
          };
        });
    
        const worksheet = XLSX.utils.json_to_sheet(exportData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Expedientes');
        const excelBuffer = XLSX.write(workbook, {
          bookType: 'xlsx',
          type: 'array',
        });
        const data = new Blob([excelBuffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        saveAs(data, `Indice${new Date().toLocaleDateString()}.xlsx`);
      };

    const handleChangeLavado = (e) => {
        const { value } = e.target;

        let formattedValue;
        if (value.charAt(0) !== '$') {
          formattedValue = `$${formatValue(value)}`;
        } else {
          formattedValue = `$${formatValue(value.slice(1))}`;
        }

        setAntilavado(() => formattedValue);
    };

    return (
        <div  className={classes.tableContainer}>
        {
            openModal && <Modal handleClose={handleClose}> 
                <div className={classes.modalInnerContainer}>
                    <h3>Inciso Vulnerable</h3>
                    <hr/>
                    <p>{openModal.texto}<span>{openModal.quantity}</span></p>
                    <Button action={handleClose}>Regresar</Button>
                </div>
            
            </Modal>
        }

        {
        expedientes && <div>
            <div className={classes.headerContainer}>
                <div>
                    <p>Monto máximo de meses antilavado</p>
                    <Input placeholder='Monto antilavado' value={antilavado} onChange={handleChangeLavado}/>
                </div>

                <Button action={exportToExcel}>Exportar índice</Button>
            </div>
            <table className={classes.table}>
            <thead>
              <tr>
                <th scope="col">Expediente</th>
                <th scope="col">Folio</th>
                <th scope="col">Fecha</th>
                <th scope="col">Acto Jurídico</th>
                <th scope="col">Inciso Vulnerable</th>
                <th scope="col">Clientes</th>
                <th scope="col">Monto</th>
              </tr>
            </thead>
            <tbody>
                { paginatedData.map((element, index) => {
                const avisoSAT = avisoCalculations(element.monto, element.inciso_vulnerable);
                 return(
                    <tr key={index}>
                        <td scope="row">{element.expediente}</td>
                        
                        <td>{element.folio || 'No agregado'}</td>

                        <td>{element.fecha || 'No agregado'}</td>

                        <td>{element.operacion || 'No agregado'}</td>

                        <td>{
                                element.inciso_vulnerable === 'N' || !avisoSAT ? 
                                ('') : 
                                (<Button action={() => handleOpening(element.inciso_vulnerable)}> {element.inciso_vulnerable}</Button>)
                            }</td>

                        <td>{
                                element.clientes.map((cliente, index) => {
                                    const receivesMore =  cliente.recibe_dinero && checkIfClientReceivesMore(cliente.nombre, element.fecha, meses_antilavado, element)
                                    
                                    if(receivesMore) {
                                        return(
                                            <p key={index} className={classes.aviso}>
                                                <span>AVISO ANTILAVADO</span>
                                                {cliente.titulo_cliente || 'Sin título'} {cliente.nombre}
                                            </p>
                                            )
                                    }

                                    return(
                                        <p key={index}>
                                            {cliente.titulo_cliente || 'Sin título'} {cliente.nombre}
                                        </p>
                                    )
                                })
                            }</td>
                        <td>{`$${formatValue(element.monto)}`}</td>
                    </tr>
                );
                
                })}
            </tbody>
        </table>
        <div className={classes.pagination}>
            <div className={classes.buttonContainer}>
            {Array.from({ length: totalPages }, (_, i) => i + 1).map(page => (
              <button
                className={classes.tableButton}
                key={page}
                onClick={() => handleClick(page)}
                disabled={page === currentPage}
              >
                {page}
              </button>
            ))}
            </div>
            <div className={classes.pagesContainer}>
                <p>Elementos por página</p>
                <Select options={optionsPaginas} onChange={handleSelect} value={optionsPaginas.find((el) => el.value === itemsPerPage)} />
            </div>
        </div>
    </div>

        }

        {
            !data && 
            <h3>No es posible completar esta acción</h3>
        }
        </div>
    );
}