import apiService from '../utils/apiService';
import { useState } from "react";
import {InputFile} from "./InputFile";
import DocumentCard from "./DocumentCard";
import Button from "./Button";


import Modal from './Modal';
import ErrorMessage from './ErrorMessage';
import Loader from './Loader';

import classes from './Protocolo.module.css';
import NoInfo from './NoInfo';

export default function DocumentoActa({acta, setActa}){
    const [isOpenModal, setIsOpenModal] = useState(undefined);
    const [selectedDocument, setSelectedDocument] = useState(undefined);
    const [selectedDocumentRoute, setSelectedDocumentRoute] = useState('');
    //Connection handler
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        setErrorMessage(undefined);
    };

    function handleOpening(document) {
        setSelectedDocumentRoute(document.rutaArchivo);
        setIsOpenModal('open');
        setSelectedDocument(document);
    }

    const putActaDoc = async (idActa, docActa) => {
        let formattedData = {
            'nombre_doc': docActa['nombreDoc'],
            'ultima_modificacion': docActa['ultimaModificacion'],
            'ruta_archivo': docActa['rutaArchivo'],
            'id_acta': idActa,
        }
        console.log('FORMATTED DATA put acta',formattedData) //  DEBUG
        try {
            const response = await apiService.put(`/docs_acta/${docActa['idDocActa']}/`, formattedData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log('PUT acta doc RESPONSE',response) //  DEBUG
            if (response.status === 200) {
                console.log('Put acta doc successful');
                return response.data;
            }
        } catch (error) {
            setLoading(() => false);
            const errorCode = error.code || 'error';
            const errorMessage = error.message + '. Notifique del error, vuelva a cargar la página o espere un momento.';
            setErrorMessage({problem: 'Problemas al agregar el documento de acta, código: '+errorCode, message: errorMessage});
        }
    }

    const handleFileChange = async (file) => {
        setLoading(() => true);
        setIsOpenModal(undefined);
        const  fileType = file['type'];
        const validImageTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/x-iwork-pages-sffpages'];
        if (!validImageTypes.includes(fileType)) {
            setErrorMessage({problem: 'Error al adjuntar el archivo', message: 'Tipo de archivo equivocado, solo se permite PDF, WORD y PAGES'});
            
            setLoading(() => false);
            return;
        }

        const currentDate = new Date();
        const formattedDate = currentDate.getFullYear() + '-' + String(currentDate.getMonth() + 1).padStart(2, '0') + '-' + String(currentDate.getDate()).padStart(2, '0');
        selectedDocument.rutaArchivo = file
        selectedDocument.ultimaModificacion = formattedDate
        // console.log('DEBUG ACTA INFORMATION PUT',acta, selectedDocument)
        console.log('TESTING ACTA',selectedDocument)
        const putResponse = await putActaDoc(acta.idActa, selectedDocument);
        if(putResponse === undefined) return;

        setActa((prev) => ({
            ...prev,
            acta_docs: prev.acta_docs.map((docActa) => {
                if(selectedDocument.idDocumento === docActa.idDocumento){
                    return {
                        ...docActa,
                        rutaArchivo : putResponse.ruta_archivo,
                        ultimaModificacion : formattedDate
                    }
                } else {
                    return docActa;
                }
            })
        }));
        setLoading(() => false);
    };

    const DocumentModal = () => {
        const fullRoute = selectedDocumentRoute
        return (
            <Modal handleClose={() => setIsOpenModal(undefined)}>
                { selectedDocumentRoute !== null && <iframe title={selectedDocumentRoute || 'doc'} src={fullRoute} width="100%"/>}
                { selectedDocumentRoute === null && <NoInfo text={'Agrega un documento'}/>}
                <div>
                    <InputFile handleFile={handleFileChange} />
                    {
                        selectedDocumentRoute !== null &&
                        <a href={fullRoute} download="doc" target='_blank' rel="noreferrer">
                            <Button className={'inverted'} type="button">Descargar</Button>
                        </a>
                    }
                    <Button type="button" onClick={() => setIsOpenModal(undefined)}>Cerrar</Button>
                </div>
            </Modal>
        )
    }

    return (
        <>
        { errorMessage &&
        <Modal handleClose={handleClose}>
        <ErrorMessage problem={errorMessage.problem} message={errorMessage.message}/>
        </Modal>
        }

        { loading &&
        <div className={classes.loaderContainer}>
            <Loader />
        </div>
        }

        { isOpenModal && <DocumentModal/> }
        <div className={classes.documentsContainer}>
            { acta.acta_docs.map((document, index) => {
                return (
                    <span key={document.idDocActa}>
                        <DocumentCard
                        description={document.nombreDoc + ' ' + (document.ultimaModificacion === null ? '' : document.ultimaModificacion)}
                        onClick={() => handleOpening(document)}/>
                    </span>
                )
            })}
        </div>
        </>
    );
}
