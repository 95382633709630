import classes from './NoInfo.module.css';
import dinosaur from '../img/icons/Chromium_T-Rex-error-offline.svg'

export default function NoInfo({text}){
    return(
        <>
            <h1 className={classes.text}>{text}</h1>
            <img className={classes.dino} src={dinosaur} alt='Icon Dino'/>
        </>
    );
};