import apiService from '../utils/apiService';
import { useEffect, useState } from 'react';
import { customSelectTheme, customClassNames } from '../utils/selectCustomizations';
import classes from './ClientesActa.module.css';
import '../style/active.css';
import '../style/select.css';
import Select from 'react-select';

import Button from './Button';
import DocumentCard from './DocumentCard';
import Modal from './Modal';

import ErrorMessage from './ErrorMessage';
import Loader from './Loader';
import NoInfo from './NoInfo';

export default function CientesActa({acta, setActa}){
    const [document, setDocument] = useState(undefined);
    //Enviar objeto de ACTA con los clientes y todos sus datos necesarios desde un inicio
    const [selectedClient, setSelectedCliente] = useState(acta.clientes[0]);
    const [optionsClientes, setOptionsClientes] = useState([]);
    const [selectedDocumentRoute, setSelectedDocumentRoute] = useState('')

    //Connection handler
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(() => true);
        mapClientOptions();
    }, []);

    const handleClose = () => {
        setErrorMessage(undefined);
    };

    const getClienteDocs = async (id_cliente) => {
        try {
            // const response = await apiService.get(`/clientes/${id_cliente}/docs/`, {});
            // return response.data
        } catch (error) {
            const errorCode = error.code || 'error';
            const errorMessage = error.message + '. Notifique del error, vuelva a cargar la página o espere un momento.';
            setErrorMessage({problem: 'Problemas al obtener los documentos de los clientes, código: '+errorCode, message: errorMessage});
        }
    };

    const getClientes = async () => {
        try {
            const response = await apiService.get('/clientes/', {});
            return response.data
        } catch (error) {
            const errorCode = error.code || 'error';
            const errorMessage = error.message + '. Notifique del error, vuelva a cargar la página o espere un momento.';
            setErrorMessage({problem: 'Problemas al obtener los clientes, código: '+errorCode, message: errorMessage});
        }
    };

    const getCliente = async (id) => {
        try {
            const response = await apiService.get(`/clientes/${id}/info/`, {});
            return response.data;
        } catch (error) {
            const errorCode = error.code || 'error';
            const errorMessage = error.message + '. Notifique del error, vuelva a cargar la página o espere un momento.';
            setErrorMessage({problem: 'Problemas al obtener los clientes, código: '+errorCode, message: errorMessage});
        }
    };

    const mapClientOptions = async () => {
        const clientes = await getClientes();

        if(clientes === undefined) return;

        const mappedData = clientes.map(cliente => {
            return {
                id: cliente.id_cliente,
                label: cliente.nombre + ' ' + cliente.apellido_paterno + ' ' + cliente.apellido_materno,
                value: cliente.id_cliente,
            }
        })
        mappedData.sort(function (a, b) {
            if (a.label < b.label) {
                return -1;
            }
            if (a.label > b.label) {
                return 1;
            }
            return 0;
        })

        setLoading(() => false);
        setOptionsClientes(mappedData);
    }

    const postClienteActa = async (id_acta, id_cliente) => {
        const formattedData = {
            "id_acta": id_acta,
            "id_cliente": id_cliente
        }
        try {
            const response = await apiService.post(`/clientes_actas/`, formattedData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return response.data
        } catch (error) {
            const errorCode = error.code || 'error';
            const errorMessage = error.message + '. Notifique del error, vuelva a cargar la página o espere un momento.';
            setErrorMessage({problem: 'Problemas al agregar un cliente al acta, código: '+errorCode, message: errorMessage});
        }
    };

    const deleteClienteActa = async (id_cliente, id_acta) => {
        try {
            console.warn(id_acta, id_cliente)
            const response = await apiService.delete(`/clientes/${id_cliente}/actas/${id_acta}/`);
            if (response.status === 204) {
                console.log('ClienteExpediente deleted successfully');
            }
        } catch (error) {
            const errorCode = error.code || 'error';
            const errorMessage = error.message + '. Notifique del error, vuelva a cargar la página o espere un momento.';
            setErrorMessage({problem: 'Problemas al eliminar un clientes, código: '+errorCode, message: errorMessage});
        }
    };

    const handleSelect = (cliente) => {
        if(selectedClient && selectedClient.id_cliente === cliente.id_cliente) {
            return;
        };

        const selected = acta.clientes.filter(actaCliente => actaCliente.id_cliente === cliente.id_cliente);

        setSelectedCliente(() => selected[0]);
    };

    function handleOpening(index){
        setSelectedDocumentRoute(selectedClient.documents[index]['archivo'])
        setDocument(selectedClient.documents[index]);
    };

    const handleDelete = async (cliente) => {
        setLoading(() => true);
        // const deleteClienteExp = await deleteClienteExpediente(selectedClient.id_cliente, acta.id);

        await deleteClienteActa(cliente.id_cliente, acta.idActa);
        
        setActa((prev) => ({
            ...prev,
            clientes : [
                ...prev.clientes.filter((c) => c !== cliente)
            ]
        }));

        setSelectedCliente(() => undefined);
        setLoading(() => false);
    };

    const handleSelectAdd = async (valueDef) => {

        setLoading(() => true);
        const value = valueDef.value;
        const findInArchive = acta.clientes.find((cliente) => cliente.id_cliente === value);
        console.log(value);
        if (findInArchive){
            setSelectedCliente(() => findInArchive);
            setLoading(() => false);
            return;
        };

        let selected = await getCliente(value);
        if(selected === undefined) {
            setLoading(() => false);
            return;
        };

        console.log(selected);
        // let clienteDocs = await getClienteDocs(selected.id_cliente)
        // const postExpediente = await postClienteExpediente(acta.id, selected.id_cliente)

        const response = await postClienteActa(acta.idActa, value);
        console.log(response);

        setActa((prev) => ({
            ...prev,
            clientes : [
                ...prev.clientes,
                selected,
            ]
        }));
        
        setLoading(() => false);

        setSelectedCliente(() => selected);
    }

    const DocumentModal = () => {
        return (
            <Modal handleClose={() => setDocument(undefined)}>
                { selectedDocumentRoute !== null && <iframe src={selectedDocumentRoute} width="100%"/>}
                <div>
                    { selectedDocumentRoute !== null &&
                    <a href={selectedDocumentRoute} download="doc" target='_blank'>
                        <Button type="button">Descargar {document.nombre_documento}</Button>
                    </a>}
                    <Button type="button" action={() => setDocument(undefined)}>Cerrar</Button>
                </div>
            </Modal>
        )
    };

    return(
        <>
            {
            errorMessage && <Modal handleClose={handleClose}>
                <ErrorMessage problem={errorMessage.problem} message={errorMessage.message}/>
            </Modal>
        }

        {
            loading && <div className={classes.loaderContainer}>
                <Loader />
            </div>
        }
        {document && <DocumentModal/>}
        <div className={classes.clientContainer}>
            <div className={classes.clientInnerContainer}>
                <h2>Clientes</h2>
                <div className={classes.flexClientsContainer}>
                    <div className={classes.clientAddContainer}> 
                        <Select 
                        className={classes.selectDG} 
                        name='selectClient'
                        onChange={(choice) => handleSelectAdd(choice)} 
                        options={optionsClientes}
                        classNames={customClassNames}
                        theme={customSelectTheme}
                        placeholder='Agrega un cliente'
                        />
                    </div>

                    <div className={classes.innerFlexClientsContainer}> 
                        { acta.clientes.length > 0 && acta.clientes.map((cliente, index) => {
                            return (
                                <div key={cliente.id_cliente} className={classes.clienteContainer}>
                                    <button key={cliente.id_cliente} type='button' onClick={() => handleSelect(cliente)}
                                    className={`${classes.clientButton} ${selectedClient && cliente.id_cliente === selectedClient.id_cliente ? 'activeB' : undefined}`}>
                                        { cliente.nombre + ' ' + cliente.apellido_paterno + ' ' + cliente.apellido_materno}
                                    </button>


                                    <Button type='button' className={'inverted'} action={() => handleDelete(cliente)}>Eliminar</Button>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>

            {
                selectedClient && <>

                <div className={classes.clientInfoContainer}>
                <h2>Datos Generales</h2>
                { 
                    selectedClient &&
                    <div className={classes.flexContainer}>
                        <div>
                            <p>Nombre: <strong style={{color:'#114E28'}}>{selectedClient.cliente}</strong></p>

                            <p>Fecha de nacimiento: <strong style={{color:'#114E28'}}>{selectedClient.fecha_nacimiento_constitucion}</strong></p>
                            <p>Lugar de nacimiento: <strong style={{color:'#114E28'}}>{selectedClient.lugar_nacimiento}</strong></p>
                            <p>Nacionalidad: <strong style={{color:'#114E28'}}>{selectedClient.nacionalidad}</strong></p>
                        </div>

                        <div>
                            <p>Tipo persona: <strong style={{color:'#114E28'}}>{selectedClient.persona}</strong></p>

                            <p>RFC: <strong style={{color:'#114E28'}}>{selectedClient.rfc}</strong></p>
                            <p>CURP/CUD: <strong style={{color:'#114E28'}}>{selectedClient.curp_cud}</strong></p>
                            <p>Folio INE: <strong style={{color:'#114E28'}}>{selectedClient.folio_ine}</strong></p>
                        </div>

                        <div>
                            <p>Estado civil: <strong style={{color:'#114E28'}}>{selectedClient.estado_civil || 'No ingresado'}</strong></p>

                            <p>Edad: <strong style={{color:'#114E28'}}>{selectedClient.edad || 'No ingresado'}</strong></p>
                            <p>Ocupación: <strong style={{color:'#114E28'}}>{selectedClient.ocupacion || 'No ingresado'}</strong></p>
                            <p>Profesión: <strong style={{color:'#114E28'}}>{selectedClient.profesion || 'No ingresado'}</strong></p>
                        </div>

                        <div className={classes.direccion}>
                            <p>Calle: <strong style={{color:'#114E28'}}>{selectedClient.calle || 'No ingresado'}</strong></p>

                            <p>Número exterior: <strong style={{color:'#114E28'}}>{selectedClient.num_exterior || 'No ingresado'}</strong></p>
                            <p>Número interior: <strong style={{color:'#114E28'}}>{selectedClient.num_interior || 'No ingresado'}</strong></p>
                            <p>Colonia: <strong style={{color:'#114E28'}}>{selectedClient.colonia || 'No ingresado'}</strong></p>
                            <p>Código postal: <strong style={{color:'#114E28'}}>{selectedClient.codigo_postal || 'No ingresado'}</strong></p>
                        </div>
                    </div>
                }
            </div>

            <div className={classes.clientInfoContainer}>
                <h2>Documentos</h2>
                <ol id={selectedClient}>
                    {
                        selectedClient && selectedClient.documents && selectedClient.documents.map((cliente, index) => {
                            return <li key={index} >
                                <DocumentCard description={cliente.nombre_documento} onClick={() => handleOpening(index)}/>
                            </li>
                        })
                    }
                    
                </ol>
            </div>
                
                </>
            }

            {
                !selectedClient && acta.clientes.length > 0 && !loading && <NoInfo text={'Selecciona un cliente'}/>
            }

            {
                !selectedClient && acta.clientes.length <= 0 && !loading && <NoInfo text={'Agrega un cliente'}/>
            }
        </div>
        </>
    );
}