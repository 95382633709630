import { useEffect, useState } from "react";

import classes from './ClientesTable.module.css';
import Button from "./Button";
import ClientesWindow from "./ClientesWindow";
import apiService from '../utils/apiService';
import Modal from "./Modal";
import ErrorMessage from "./ErrorMessage";
import Loader from "./Loader";
import NoInfo from "./NoInfo";

export default function ClientesTable(){
    const [loading, setLoading] = useState(false);
    const [saveStatus, setSaveStatus] = useState('No guardado');

    const [clients, setClientes] = useState([]);

    const [action, setAction] = useState(undefined);

    const [select, setSelect] = useState({
        index: -1,
        id: '-1'
    });

    const [errorMessage, setErrorMessage] = useState(undefined);
    const [confirmDelete, setConfirmDelete] = useState(undefined);

    useEffect(() => {
        setLoading(() => true);
        getClients();
    }, []);

    const transformClient = (data) => ({
        id: data.id_cliente,

        aPaterno: data.apellido_paterno,
        aMaterno: data.apellido_materno,
        nombre: data.nombre,
        tipo: data.persona,

        rfc: data.rfc,
        curp: data.curp_cud,
        fecha: data.fecha_nacimiento_constitucion,
        
        telefonoParticular: data.tel_particular,
        telefonoMovil: data.tel_movil,

        email: data.email,
        sexo: data.sexo,
        estadoCivil: data.estado_civil,

        edad: data.edad,
        ocupacion: data.ocupacion,

        nacionalidad: data.nacionalidad,
        lugar_nacimiento: data.lugar_nacimiento,
        folio_ine: data.folio_ine,
        profesion: data.profesion,

        num_exterior: data.num_exterior,
        num_interior: data.num_interior,
        calle: data.calle,
        colonia: data.colonia,
        codigo_postal: data.codigo_postal,

        documentos: []
    });

    const getClients = async () => {
        try {
            const response = await apiService.get('/clientes/', {});

            setClientes(response.data.map((single) => transformClient(single)));
            setLoading(() => false);
        } catch (error) {
            const errorCode = error.code || 'error';
            const errorMessage = error.message + '. Notifique del error, vuelva a cargar la página o espere un momento, los cambios hechos no se aplicarán';
            setErrorMessage({problem: 'Problemas al obtener los clientes, código: '+errorCode, message: errorMessage});
            console.error('There was an error getting the info!', error);
        }
    };

    const handleSearch = () => {
        // Declare variables
        var input, filter, table, tr, td, i, txtValue;
        input = document.getElementById("searchBar");
        filter = input.value.toUpperCase();
        table = document.getElementById("table");
        tr = table.getElementsByTagName("tr");
      
        // Loop through all table rows, and hide those who don't match the search query
        for (i = 0; i < tr.length; i++) {
          td = tr[i].getElementsByTagName("td")[0];
          if(td){
              txtValue = td.innerText || td.textContent;
              if (txtValue.toUpperCase().indexOf(filter) > -1) {
                tr[i].style.display = "";
              } else {
                tr[i].style.display = "none";
              }
          }
        }
    }

    const deleteClient = async (id) => {
        try {
            const response = await apiService.delete(`/clientes/${id}/`, {}, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            // console.log(response) //  DEBUG
            if (response.status === 204) {
                console.log('Delete client successful')
            }
        } catch (error) {
            const errorCode = error.code || 'error';
            const errorMessage = error.message + '. Notifique del error, vuelva a cargar la página o espere un momento, los cambios hechos no se aplicarán';
            setErrorMessage({problem: 'Problemas al eliminar cliente, código: '+errorCode, message: errorMessage});
            
            console.error('Error deleting cliente:', error);
        }
    };

    const handleDelete = (id) => {
        deleteClient(id);

        setClientes(l => l.filter(item => item.id !== id));
        setSelect(() => ({
            index: -1,
            id: ''
        }));
        setAction(undefined);
        setConfirmDelete(undefined);
    };

    const handleSelect = (index, id) => {
        if(select.id === id){
            setSelect(() => ({
                index:-1,
                id:'-1'
            }));
        } else{
            setSelect(() => ({
                index,
                id
            }));
        }
    };

    const handleAction = (action) => {
        setAction(action);
    }

    const handleClose = () => {
        setErrorMessage(undefined);
    };

    const handleCancel = () => {
        setConfirmDelete(undefined);
    };

    const handleConfirmDelete = (item) => {
        setConfirmDelete(item);
    };
    
    return (
        <>
            {
                errorMessage && <Modal handleClose={handleClose}>
                    <ErrorMessage problem={errorMessage.problem} message={errorMessage.message}/>
                </Modal>
            }

            {
                confirmDelete && <Modal handleClose={handleCancel}>
                    <div className={classes.deleteModal}>
                        <h1>
                            Eliminando...
                        </h1>

                        <p>
                            ¿Estas seguro de eliminar el cliente {`${confirmDelete.nombre} ${confirmDelete.aPaterno} ${confirmDelete.aMaterno}`}?
                        </p>

                        <div className={classes.deleteModalButtons}>
                            <Button action={() => handleDelete(confirmDelete.id)}>
                                Eliminar
                            </Button>
                            <Button action={handleCancel} className={'inverted'}>
                                Cancelar
                            </Button>
                        </div>
                    </div>
                </Modal>
            }

            {
                !loading && <div className={classes.headerContainer}>
                <input className={classes.input} type="text" id="searchBar" onKeyUp={handleSearch} placeholder="Buscar clientes..."/>

                <div  className={classes.buttonsLoader}>
                    <p>{saveStatus}</p>

                    <Button type='button' action={() => handleConfirmDelete(select)} disabled={select.index === -1}>Eliminar</Button>
                    <Button className={'inverted'} type='button' action={() => handleAction('e')} disabled={select.index === -1}>Editar</Button>
                    <Button className={'inverted'} type='button' action={() => handleAction('a')}>Agregar</Button>
                </div>
            </div>
            }

            {
                loading && <Loader />
            }

        {
            !loading && clients.length > 0 && <div className={classes.tableContainer}>
            <table className={classes.table} id='table'>
                <thead>
                  <tr>
                    <th scope="col">Nombre</th>
                    <th scope="col">Tipo</th>
                    <th scope="col">RFC</th>
                    <th scope="col">CURP</th>
                    <th scope="col">Seleccionar</th>
                  </tr>
                </thead>
            <tbody>
              {
                clients && clients.map((data, index) => (
                    <tr key={index} onClick={() => handleSelect(index, data.id)}>
                        <td>{`${data.nombre} ${data.aPaterno} ${data.aMaterno}`}</td>

                        <td>{data.tipo.charAt(0).toUpperCase() + data.tipo.slice(1)}</td>

                        <td>{data.rfc}</td>

                        <td>{data.curp}</td>

                        <td><input name='seleccionar' className={classes.checkbox} type="checkbox" checked={select.index === index} onChange={() => handleSelect(index, data.id)}/></td>

                    </tr>
                ))
              }
            </tbody>
        </table>
        </div>
        }
        
        { clients.length <= 0 && !loading && <NoInfo text={'Agrega un cliente'}/> }

        {
            action === 'a' &&
            <ClientesWindow setData={setClientes} setAction={setAction} action={'a'} setSelect={setSelect} setErrorMessage={setErrorMessage} setSaveStatus={setSaveStatus}/>
        }

        {
            action === 'e' &&
            <ClientesWindow data={clients[select.index]} setData={setClientes} setAction={setAction} action={'e'} setSelect={setSelect} setErrorMessage={setErrorMessage} setSaveStatus={setSaveStatus}/>
        }
        </>
    );
};