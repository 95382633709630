import apiService from '../utils/apiService';
import { useEffect, useState } from 'react';
import { customSelectTheme, customClassNames } from '../utils/selectCustomizations';
import Input from "./Input";
import Select from 'react-select';

import ErrorMessage from './ErrorMessage';
import Modal from './Modal';

import classes from './DatosGenerales.module.css';

import '../style/select.css';

const optionsEstatusPago = [
    {label: 'Pendiente', value: 'Pendiente'},
    {label: 'Pagado', value: 'Pagado'},
]

const optionsEstatusExpediente = [
    {label: 'En trámite', value: 'En trámite'},
    {label: 'Trámite catastro', value: 'Trámite catastro'},
    {label: 'Trámite registro', value: 'Trámite registro'},
    {label: 'Terminado', value: 'Terminado'},
]

export default function DatosGenerales({expediente, setExpediente, setSaveStatus}) {
    const [optionsPrimerOtorgante, setOptionsPrimerOtorgante] = useState([]);
    const [optionsOperacion, setOptionsOperacion] = useState([]);
    const [optionsExtras, setOptionsExtras] = useState([]);

    //For petition handling
    const [errorMessage, setErrorMessage] = useState(undefined);

    //Closes error modal
    const handleClose = () => {
        setErrorMessage(undefined);
    };

    const formatValue = (inputValue) => {
        // Remove any non-numeric characters except for periods
        let numericValue = inputValue.replace(/[^0-9.]/g, '');

        // Split the value into integer and decimal parts
        let [integer, decimal] = numericValue.split('.');

        // Ensure the integer part is not empty
        if (!integer) {
          integer = '0';
        }

        // Truncate decimal part to two digits
        if (decimal) {
          decimal = decimal.slice(0, 2);
        }

        integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        // Join the integer and decimal parts
        numericValue = decimal !== undefined ? `${integer}.${decimal}` : integer;

        return numericValue;
      };

    const handleChangeCurrency = (e) => {
        const { name, value } = e.target;

        let formattedValue;
        if (value.charAt(0) !== '$') {
          formattedValue = `$${formatValue(value)}`;
        } else {
          formattedValue = `$${formatValue(value.slice(1))}`;
        }
        
        setExpediente((prev) => ({
            ...prev,
            datosGenerales: {
                ...prev.datosGenerales,
                [name] : formattedValue
            },
        }));
    };

    const handleChange = (e) => {
        setSaveStatus(() => 'No guardado');
        
        const { name, value } = e.target;
        setExpediente((prev) => ({
            ...prev,
            datosGenerales: {
                ...prev.datosGenerales,
                [name] : value
            },
        }));
    };

    const handleSelect = (valueDef, nameDef) => {
        const value = valueDef.value;
        const name = nameDef;

        setSaveStatus(() => 'No guardado');

        setExpediente((prev) => ({
            ...prev,
            datosGenerales : {
                ...prev.datosGenerales,
                [name] : value
            }
        }));
    };

    const mapClientOptions = async () => {
        const mappedData = expediente.clientes.map(cliente => {
            return {
                id: cliente.id_cliente,
                label: cliente.cliente,
                value: cliente.cliente,
            }
        })
        mappedData.sort(function (a, b) {
            if (a.label < b.label) {
                return -1;
            } if (a.label > b.label) {
                return 1;
            }
            return 0;
        })
        setOptionsPrimerOtorgante(mappedData)
    }

    const mapOperacionOptions = async () => {
        const operaciones = await getOperations();

        if(operaciones === undefined || operaciones.length <= 0) return;

        const mappedData = operaciones.map(operacion => {
            return {
                id: operacion.id_operacion,
                label: operacion.operacion,
                value: operacion.operacion,
            }
        })
        mappedData.sort(function (a, b) {
            if (a.label < b.label) {
                return -1;
            } if (a.label > b.label) {
                return 1;
            }
            return 0;
        })
        setOptionsOperacion(mappedData)
    }

    const getOperations = async () => {
        try {
            const response = await apiService.get('/operaciones/', {});
            return response.data
        } catch (error) {
            const errorCode = error.code || 'error';
            const errorMessage = error.message || 'Notifique del error, vuelva a cargar la página o espere un momento';
            setErrorMessage({problem: 'Problemas al obtener las operaciones, código: '+errorCode, message: errorMessage});
        }
    };

    const mapExtrasOptions = async () => {
        const usernames = await getUsernames()

        if(usernames === undefined || usernames.length <= 0) return;

        const mappedData = usernames['users'].map(user => {
            return {
                id: user.name,
                label: user.name,
                value: user.name,
            }
        })
        mappedData.sort(function (a, b) {
            if (a.label < b.label) {
                return -1;
            } if (a.label > b.label) {
                return 1;
            }
            return 0;
        })
        setOptionsExtras(mappedData)
    }

    const getUsernames = async () => {
        try {
            const response = await apiService.get('/usernames/', {});
            return response.data
        } catch (error) {
            const errorCode = error.code || 'error';
            const errorMessage = error.message || 'Notifique del error, vuelva a cargar la página o espere un momento';
            setErrorMessage({problem: 'Problemas al obtener los nombres de usuarios, código: '+errorCode, message: errorMessage});
        }
    };

    useEffect(() => {
        mapClientOptions()
        mapOperacionOptions()
        mapExtrasOptions()
    }, [])

    return(
        <>
        {
            errorMessage && <Modal handleClose={handleClose}>
                <ErrorMessage problem={errorMessage.problem} message={errorMessage.message}/>
            </Modal>
        }
        <div className={classes.formContainer}>
            <div className={classes.innerContainer}>
                {/* <Input name='expediente' label={'Expediente'} type='text'
                value={expediente.datosGenerales.expediente}
                onChange={handleChange} readOnly/> */}

                <Input name='tituloExpediente' label={'Nombre del expediente'} type='text'
                value={expediente.datosGenerales.tituloExpediente}
                readOnly/>

                <span>
                    <label className={classes.labelText}>Estatus expediente
                        <Select 
                        name='estatusExpediente' 
                        className={classes.selectDG} 
                        onChange={(choice) => handleSelect(choice, 'estatusExpediente')}
                        value={optionsEstatusExpediente.find((option) => option.value === expediente.datosGenerales.estatusExpediente) || ''}
                        options={optionsEstatusExpediente}
                        classNames={customClassNames}
                        theme={customSelectTheme}
                        />
                    </label>
                </span>
    
                <Input name='fechaCreacion' label={'Fecha creación'} type='date'
                value={expediente.datosGenerales.fechaCreacion || ''}
                onChange={handleChange} />

                <span>
                    <label className={classes.labelText}>Operación
                        <Select name='operacion' 
                        className={classes.selectDG} 
                        onChange={(choice) => handleSelect(choice, 'operacion')}
                        // value={optionsOperacion.find((option) => option.value === expediente.datosGenerales.operacion) || ''}
                        value={{label: expediente.datosGenerales.operacion || '', value: expediente.datosGenerales.operacion || ''}}
                        options={optionsOperacion}
                        classNames={customClassNames}
                        theme={customSelectTheme}
                        />
                    </label>
                </span>
            </div>

            <div className={classes.innerContainer}>
                <Input name='financiamiento' label={'Financiamiento'} type='text'
                    value={expediente.datosGenerales.financiamiento}
                    onChange={handleChangeCurrency}
                    placeholder='Agregar' 
                />

                <span>
                    <label className={classes.labelText}>Primer otorgante
                        <Select 
                        name='primerOtorgante'
                        className={classes.selectDG} 
                        onChange={(choice) => handleSelect(choice, 'primerOtorgante')}
                        value={{label: expediente.datosGenerales.primerOtorgante || '', value: expediente.datosGenerales.primerOtorgante || ''}}
                        // value={optionsClientes.find((option) => option.value === expediente.datosGenerales.primerOtorgante) || ''}
                        options={optionsPrimerOtorgante}
                        classNames={customClassNames}
                        theme={customSelectTheme}
                        />
                    </label>
                </span>

                <Input name='fechaPagoImpuestos' label={'Fecha de pago de impuestos'} type='date'
                value={expediente.datosGenerales.fechaPagoImpuestos || ''}
                onChange={handleChange} />
            </div>

            <div className={classes.innerContainer}>
                <span>
                    <label className={classes.labelText}>Notario
                        <Select name='notario'
                        className={classes.selectDG} 
                        onChange={(choice) => handleSelect(choice, 'notario')} 
                        // value={optionsNotario.find((option) => option.value === expediente.datosGenerales.notario) || ''}
                        value={{label: expediente.datosGenerales.notario || '', value: expediente.datosGenerales.notario || ''}}
                        options={optionsExtras}
                        classNames={customClassNames}
                        theme={customSelectTheme}
                        />
                    </label>
                </span>

                <span>
                    <label className={classes.labelText}>Responsable
                        <Select name='responsable' 
                        className={classes.selectDG} 
                        onChange={(choice) => handleSelect(choice, 'responsable')}
                        // value={optionsResponsable.find((option) => option.value === expediente.datosGenerales.responsable) || ''}
                        value={{label: expediente.datosGenerales.responsable || '', value: expediente.datosGenerales.responsable || ''}}
                        options={optionsExtras}
                        classNames={customClassNames}
                        theme={customSelectTheme}
                        />
                    </label>
                </span>

                <span>
                    <label className={classes.labelText}>Secretaria
                        <Select name='secretaria' 
                        className={classes.selectDG} 
                        onChange={(choice) => handleSelect(choice, 'secretaria')}
                        // value={optionsSecretaria.find((option) => option.value === expediente.datosGenerales.secretaria) || ''}
                        value={{label: expediente.datosGenerales.secretaria || '', value: expediente.datosGenerales.secretaria || ''}}
                        options={optionsExtras}
                        classNames={customClassNames}
                        theme={customSelectTheme}
                        />
                    </label>
                </span>
            </div>
        </div>


        <h3 className={classes.subtitle}>Valor & pagos</h3>
        <hr/>
        <div className={classes.formContainer}>
            <div className={classes.innerContainer}>
                <Input name='valorOperacion' label={'Monto'} type='text'
                    value={expediente.datosGenerales.valorOperacion}
                    onChange={handleChangeCurrency}
                    placeholder='Agregar'  />

                <span>
                    <label className={classes.labelText}>Estatus de pago
                        <Select 
                        name='estatusPago'
                        className={classes.selectDG} 
                        onChange={(choice) => handleSelect(choice, 'estatusPago')}
                        value={optionsEstatusPago.find((option) => option.value === expediente.datosGenerales.estatusPago) || ''}
                        options={optionsEstatusPago}
                        classNames={customClassNames}
                        theme={customSelectTheme}
                        />
                    </label>
                </span>
            </div>

            <div className={classes.innerContainer}>
                <Input name='pagoTransferencia' label={'Pago en transferencia'} type='text'
                    value={expediente.datosGenerales.pagoTransferencia}
                    onChange={handleChangeCurrency}
                    placeholder='Agregar' />

                <Input name='referencia' label={'Referencia'} type='text'
                value={expediente.datosGenerales.referencia || ''}
                // value={expediente.datosGenerales.referencia ? expediente.datosGenerales.referencia : ''}
                onChange={handleChange} />
            </div>
            
            <div className={classes.innerContainer}>
                <Input name='pagoEfectivo' label={'Pago en efectivo'} type='text'
                    value={expediente.datosGenerales.pagoEfectivo}
                    onChange={handleChangeCurrency}
                    placeholder='Agregar' />

                <Input name='pagoOtro' label={'Otro tipo de pago'} type='text'
                    value={expediente.datosGenerales.pagoOtro}
                    onChange={handleChangeCurrency}
                    placeholder='Agregar'  />
            </div>
        </div>
        </>
    );
}
