import { useNavigate } from "react-router-dom";

import classes from './Home.module.css';

import backgroundImage from '../img/background.jpg';

import Button from "../components/Button";

import { useEffect, useState } from "react";

export default function Home(){
    const navigate = useNavigate();
    const signOut = () => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('user_info');
        localStorage.removeItem('user_id');
        navigate("/");
    };

    const [info, setInfo] = useState(undefined);

    useEffect(() => {
        const retrievedObject = localStorage.getItem("user_info");
        setInfo(() => JSON.parse(retrievedObject));
    }, []);

    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    const notaria = "Notaria 137";

    return (
        <div className={classes.container}>
            <div className={classes.infoContainer}>
                <h2>Buen día</h2>
                <h1>{info?.name ? info.name : 'Nombre de usuario'}</h1>
                <h3>{notaria}</h3>

                <p className={classes.separation}>¿Ha terminado tu día?</p>
                <Button action={signOut} >Cerrar sesión</Button>

                <p>La fecha del día de hoy: {day}/{month}/{year}</p>
            </div>
            <div  className={classes.imgContainer} style={{backgroundImage : info?.cover_photo ? `url("${info.cover_photo}")` : `url(${backgroundImage})`}}>
            </div>
        </div>
    );
};