import classes from './Loader.module.css';
import { Grid } from 'react-loader-spinner'

export default function Loader(){
    return (
        <div className={classes.loaderContainer}>
            <Grid
                visible={true}
                height="100"
                width="100"
                ariaLabel="hourglass-loading"
                wrapperStyle={{}}
                wrapperClass=""
                color={'#114E28'}
                />
                <p>Cargando...</p>
        </div>
    );
}