import apiService from '../utils/apiService';
import { useState } from "react";
import {InputFile} from "./InputFile";
import DocumentCard from "./DocumentCard";
import Button from "./Button";
import NoInfo from "./NoInfo";


import Modal from './Modal';
import ErrorMessage from './ErrorMessage';
import Loader from './Loader';

import classes from './Protocolo.module.css';

export default function Protocolo({expediente, setExpediente}){
    const [isOpenModal, setIsOpenModal] = useState(undefined);
    const [selectedDocument, setSelectedDocument] = useState(undefined);
    const [selectedDocumentRoute, setSelectedDocumentRoute] = useState('');

    //Connection handler
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        setErrorMessage(undefined);
    };

    function handleOpening(document) {
        setSelectedDocumentRoute(document.rutaArchivo)
        setIsOpenModal('open');
        setSelectedDocument(document);
    }

    const putProtocoloDoc = async (idExpediente, documentoProtocolo) => {
        let formattedData = {
            'tipo_documento': documentoProtocolo['tipoDocumento'],
            'ultima_modificacion': documentoProtocolo['ultimaModificacion'],
            'ruta_archivo': documentoProtocolo['rutaArchivo'],
            'id_expediente': idExpediente,
        }
        // console.log('FORMATTED DATA put protocolo',formattedData) //  DEBUG
        try {
            const response = await apiService.put(`/protocolo/${documentoProtocolo['idDocumento']}/`, formattedData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            // console.log('PUT protocolo RESPONSE',response) //  DEBUG
            if (response.status === 200) {
                console.log('Put protocolo doc successful');
                return response.data;
            }
        } catch (error) {
            setLoading(() => false);
            const errorCode = error.code || 'error';
            const errorMessage = error.message + '. Notifique del error, vuelva a cargar la página o espere un momento.';
            setErrorMessage({problem: 'Problemas al agregar el documento del protocolo, código: '+errorCode, message: errorMessage});
        }
    }

    const handleFileChange = async (file) => {
        setLoading(() => true);
        setIsOpenModal(undefined);

        const  fileType = file['type'];
        const validImageTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/x-iwork-pages-sffpages'];
        if (!validImageTypes.includes(fileType)) {
            setErrorMessage({problem: 'Error al adjuntar el archivo', message: 'Tipo de archivo equivocado, solo se permite PDF, WORD y PAGES'});
            
            setLoading(() => false);
            return;
        }

        const currentDate = new Date();
        const formattedDate = currentDate.getFullYear() + '-' + String(currentDate.getMonth() + 1).padStart(2, '0') + '-' + String(currentDate.getDate()).padStart(2, '0');
        selectedDocument.rutaArchivo = file
        selectedDocument.ultimaModificacion = formattedDate
        const putResponse = await putProtocoloDoc(expediente.id, selectedDocument);
        
        if(putResponse === undefined) return;

        const rutaArchivo = putResponse.ruta_archivo
        // console.log('Put Response', putResponse)
        // console.log('RUTA ARCHIVO', rutaArchivo)

        setExpediente((prev) => ({
            ...prev,
            protocolo : prev.protocolo.map((documentoProtocolo) => {
                if(selectedDocument.idDocumento === documentoProtocolo.idDocumento){
                    return {
                        ...documentoProtocolo,
                        rutaArchivo : rutaArchivo,
                        ultimaModificacion : formattedDate
                    }
                } else {
                    return documentoProtocolo;
                }
            })
        }));
        setLoading(() => false);
    };

    const DocumentModal = () => {
        return (
            <Modal handleClose={() => setIsOpenModal(undefined)}>
                { selectedDocumentRoute !== null && <iframe title={selectedDocumentRoute || 'doc'} src={selectedDocumentRoute} width="100%"/>}
                { selectedDocumentRoute === null && <NoInfo text={'Agrega un documento'}/>}
                <div>
                    <InputFile handleFile={handleFileChange} />
                    {
                        selectedDocumentRoute !== null &&
                        <a href={selectedDocumentRoute} download="doc" target='_blank' rel="noreferrer">
                            <Button className={'inverted'} type="button">Descargar</Button>
                        </a>
                    }
                    <Button type="button" onClick={() => setIsOpenModal(undefined)}>Cerrar</Button>
                </div>
            </Modal>
        )
    }

    return (
        <>
        {
            errorMessage && <Modal handleClose={handleClose}>
                <ErrorMessage problem={errorMessage.problem} message={errorMessage.message}/>
            </Modal>
            }

            {
                loading && <div className={classes.loaderContainer}>
                    <Loader />
                </div>
            }
        { isOpenModal && <DocumentModal/> }
        <div className={classes.documentsContainer}>
            { expediente.protocolo.map((document, index) => {
                // const clonedDocument = JSON.parse(JSON.stringify(document));
                // console.log('INSIDE MAPPING CLG', clonedDocument);
                // console.log('INSIDE MAPPING CLG', clonedDocument.tipoDocumento);
                // {console.log('INSIDE MAPPING CLG', document)}
                // {console.log('INSIDE MAPPING CLG', document.tipoDocumento)}
                return (
                    <span key={document.idDocumento}>
                        <DocumentCard
                        description={document.tipoDocumento + ' ' + (document.ultimaModificacion === null ? '' : document.ultimaModificacion)}
                        onClick={() => handleOpening(document)}/>
                    </span>
                )
            })}
        </div>
        </>
    );
}
